import { Brick, registerBrick, ListDef, CloudObject, QueryResult } from 'olympe';
import { getLogger } from "@olympeio/core";

export default class FilterListIsNull extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {ListDef | Array} list
     * @param {*} property
     * @param {boolean} not
     * @param {function(ListDef | Array)} setList
     */
    update(context, [list, property, not], [setList]) {
        const logger = getLogger('Filter List Is Null');

        if (Array.isArray(list) || list instanceof QueryResult) {
            const result = list.filter(item => this.checkProperty(item, property, not));
            setList(result);
        } else if (list instanceof ListDef) {
            // Not ideal because if the prop changes without the size changing, this block won't retrigger, but how to filter on a listdef on a null prop
            list.onReady(() => {
                list.observeSize().subscribe(() => {
                    const array = [];
                    list.forEachCurrentValue((item) => {
                        const meetsCondition = this.checkProperty(item, property, not);
                        if (meetsCondition){
                            array.push(item);
                        }
                    });
                    setList(array);
                });
            });
        } else {
            logger.error('List is not a ListDef, an Array or a Query Result');
        }
    }

    /**
     *
     * @param {CloudObject} item
     * @param {string} property
     * @param {boolean} not
     * @return {boolean}
     */
    checkProperty(item, property, not) {
        const value = item.get(property);
        return not ? value !== null : value === null;
    }
}

registerBrick('017d5c2294d1b88c6ffe', FilterListIsNull);
