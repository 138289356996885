import { Brick, registerBrick } from 'olympe';

/**
 * @deprecated
 */
export default class RandomFunction extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {number} interval
     * @param {function(number)} setResult
     */
    update(context, [interval], [setResult]) {
        this.intervalHandler && clearInterval(this.intervalHandler);
        setResult(Math.random());

        if(typeof interval === 'number' && interval < 100000000000){
            this.intervalHandler = setInterval(() => {
                setResult(Math.random());
            }, interval);
        } else {
            // More than 1157 days... most probably a timestamp were given so don't trigger the interval, this is a one-shot
        }
    }

    /**
     * @override
     */
    destroy(context) {
        if(this.intervalHandler) {
            clearInterval(this.intervalHandler);
        }
    }
}

registerBrick('017aaa03c1c235bcaa5c', RandomFunction);
