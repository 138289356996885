import { ListDef, QueryResult } from 'olympe';

/**
 * @param {*} source
 * @return {Object}
 */
export const getAsArray = source => {
    let list = [];
    if (Array.isArray(source)) {
        list = source;
    } else if (source instanceof QueryResult) {
        list = source.toArray();
    } else if (source instanceof ListDef) {
        source.forEachCurrentValue(item => list.push(item));
    } else {
        const error = { message: 'Provided source input is not ListDef or Array', code: 1 };
        return { array: null, error: error };
    }
    return { array: list, error: null };
};

/**
 * @param {*} data
 * @return {Object}
 */
export const getAsJson = data => {
    try {
        const dataJson = (typeof data === 'string') ? JSON.parse(data) : data;
        return { json: dataJson, error: null };
    } catch (e) {
        const error = { message: 'Error while parsing the source string: ' + e.message, code: 1 };
        return { json: null, error: error };
    }
};

/**
 * @param {!BrickContext} $
 * @param {!Object} object
 * @param {!Brick} iteratee
 * @return {!Promise<!Array>}
 */
export const iterateInObject = async ($, object, iteratee) => {
    return await Promise.all(Object.entries(object).map(
        async ([key, value]) => await iterate($, key, value, object, iteratee)
    ));
}

/**
 * @param {!BrickContext} $
 * @param {!Object} key
 * @param {!Object} value
 * @param {!Object} object
 * @param {!Brick} iterator
 * @return {Promise<!Object>}
 */
export const iterate = async ($, key, value, object, iterator) => {
    const [startInput, keyInput, valueInput, objectInput] = iterator.getInputs();
    const [endOutput, resOutput] = iterator.getOutputs();

    const iterator$ = $.runner(iterator)
        .set(keyInput, key)
        .set(valueInput, value)
        .set(objectInput, object)
        .trigger(startInput);

    await iterator$.waitFor(endOutput);
    const result = iterator$.get(resOutput);
    iterator$.destroy();

    return result;
};

/**
 * Placing Helper function in a static class in order
 * to easily mock them in tests with `spyOn()`
 */
export class Helper {

    /**
     * @param {!BrickContext} $
     * @param {!Object} object
     * @param {number} rank
     * @param {!Array<*>} list
     * @param {!Brick} predicateBrick
     * @return {!Promise<boolean>}
     */
    static async predicate ($, object, rank, list, predicateBrick) {
        const [objectInput, rankInput, listInput] = predicateBrick.getInputs();
        const [resOutput] = predicateBrick.getOutputs();

        const predicate$ = $.runner(predicateBrick)
            .set(objectInput, object)
            .set(rankInput, rank)
            .set(listInput, list);

        const result = await predicate$.waitFor(resOutput);
        predicate$.destroy();
        return result;
    };

    /**
     * @param {!BrickContext} $
     * @param {!Object} objectA
     * @param {!Object} objectB
     * @param {!Brick} comparator
     * @return {Promise<!Object>}
     */
    static async compare ($, objectA, objectB, comparator) {
        const [startInput, objectAInput, objectBInput] = comparator.getInputs();
        const [endOutput, resOutput] = comparator.getOutputs();

        const comparator$ = $.runner(comparator)
            .set(objectAInput, objectA)
            .set(objectBInput, objectB)
            .trigger(startInput);

        await comparator$.waitFor(endOutput);
        const result = comparator$.get(resOutput);
        comparator$.destroy();

        return result;
    }

    /**
     *
     * @param $
     * @param {*[] }array
     * @param {*} comparator
     * @return {Promise<*[]>}
     */
    static async uniqueWithAsync($, array, comparator) {
        const isDuplicated = [];
        const result = [];
        for (let i = 0; i < array.length; i++) {
            isDuplicated[i] = !!isDuplicated[i];
            if (isDuplicated[i]) {
                continue;
            } else {
                result.push(array[i]);
            }
            for (let j = i + 1; j < array.length; j++) {
                const comparison = await Helper.compare($, array[j], array[i], comparator);
                if (comparison) {
                    isDuplicated[j] = true;
                }
            }
        }
        return result;
    }
}
