import { makeStyles } from '@mui/styles';
import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme(adaptV4Theme({
    typography: {
        fontFamily: [
            "BrownPro",
            "Roboto",
            "Arial",
            "Verdana",
        ].join(",")
    },
    spacing: 8,
}));

export const useStyle = makeStyles((theme) => {
    return {
        root: (styling) => getRootStyle(styling),
        selected: (styling) => getSelectedStyle(styling),
        button: {
            width: 24, height: 24,
            padding: 0,
            color: (styling) => styling.fontColor?.toHexString() || 'unset',

            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        text: {
            '& span': {
                fontFamily: (styling) => {
                    const font = styling.fontFamily;
                    return `${font} !important`;
                },
            },
        },
    }
}, { defaultTheme: theme });

const getRootStyle = (styling) => ({
    height: styling.optionHeight,
    backgroundColor: styling.primaryColor?.toHexString() || 'unset',
    color: styling.fontColor?.toHexString() || 'unset',
    fontFamily: styling.fontFamily,

    '&$selected': {
        backgroundColor: styling.primaryColor?.toHexString() || 'unset',
    },

    '&$selected:hover': {
        backgroundColor: styling.primaryColor?.toHexString() || 'unset',
    },

    '&:hover': {
        backgroundColor: styling.secondaryColor?.toHexString() || 'unset',
    },
});

const getSelectedStyle = (styling) => ({
    backgroundColor: styling.secondaryColor?.toHexString() || 'unset',

    '& span': {
        fontWeight: '600',
    },

    '&:hover': {
        backgroundColor: styling.secondaryColor?.toHexString() || 'unset',
    },
});
