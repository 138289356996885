import {BrickContext, Brick, ErrorFlow, generateTag, registerBrick, Service} from "olympe";
import {getLogger} from "@olympeio/core";
import {of} from "rxjs";
import {switchMap} from "rxjs/operators";

export default class CallService extends Brick {

    /**
     * @override
     */
    setupExecution($) {
        // Wait for the brick to be "active" and then listen to the service name.
        const [activeInput, serviceNameInput] = this.getInputs();
        return $.observe(activeInput).pipe(switchMap((isActive) => {
            return isActive ? $.observe(serviceNameInput) : of(null);
        }));
    }

    /**
     * @protected
     * @param {!BrickContext} $
     * @param {string} name
     * @param {function(*)} setResult
     * @param {function(!ErrorFlow)} setError
     */
    update($, [name], [setResult, setError]) {
        const logger = getLogger('CallService');
        const dataInput = this.getInputs()[2];

        // Create a unique consumer id
        const id = generateTag();

        // Start consuming results coming from the producer.
        const initData = $.get(dataInput);
        Service.observe(name, $, {id, initData}).subscribe({
            next: (data) => {
                data instanceof ErrorFlow ? setError(data) : setResult(data);
            },
            error: (e) => setError(ErrorFlow.create(`Error while calling the service ${name}, ${e}`, 500))
        });

        // Listen to any new value coming as input of the brick and send it to the producer service.
        $.observe(dataInput).subscribe((data) => {
            Service.publish(name, {id, data}).catch((e) => {
                logger.error(`Error occurred while trying to send data ${data} to the service ${name}: ${e}`);
            });
        });
    }
}

registerBrick('0181eda6ec2827e1f7b6', CallService);
