import { UIBrick, registerBrick } from 'olympe';
import {combineProps, getObjectFromJSON} from "./_helpers";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';
import { getIcon } from '@olympeio-extensions/commons';

export class MUIIcon extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        // Center component
        elementDom.style.display = 'flex';
        elementDom.style.justifyContent = 'center';
        elementDom.style.alignItems = 'center';

        combineProps([
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'muiFontSize', prop: context.getProperty('MUI Font Size')},
            {name: 'muiHtmlColor', prop: context.getProperty('MUI HTML Color')},
            {name: 'muiIcon', prop: context.getProperty('MUI Icon')}
        ]).subscribe(props => {
            ReactDOM.render((<MUIIcon.Component {...props}/>), elementDom);
        });
    }
}

registerBrick('01791dcbae63408a88af', MUIIcon);

/**
 * @param {{
 *     styleJSON: string
 *     muiColor: string
 *     muiFontSize: string
 *     muiHtmlColor: string
 *     muiIcon: string
 *   }} props
 * @return {Element}
 */
MUIIcon.Component = (props) => {
    const style = getObjectFromJSON(props.styleJSON);
    const iconProps = {
        style: style,
        color: props.muiColor,
        fontSize: props.muiFontSize,
        htmlColor: props.muiHtmlColor
    };
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {getIcon(props.muiIcon, true, iconProps)}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};