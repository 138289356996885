import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import { getAsArray } from '../../helper';
import * as _ from 'lodash';
import { merge } from 'rxjs';
import {map} from "rxjs/operators";

export default class Slice extends Brick {

    setupExecution($) {
        const inputs = this.getInputs();
        return merge(...inputs.map((i) => $.observe(i))).pipe(map((value) => {
            const [source, start, end] = inputs.map((i) => $.get(i));
            if (source === null) {
                return null;
            }
            return [source, start, end];
        }));
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {number} start
     * @param {number} end
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [source, start, end], [setResult, setErrorFlow]) {
        const logger = getLogger('Slice');
        // Guards
        if (typeof start !== 'number') {
            logger.error('Provided start input is not a number');
            return;
        }
        if (typeof end !== 'number' && end !== null) {
            logger.error('Provided end input is not a number');
            return;
        }

        const { array, error } = getAsArray(source);

        if (!error) {
            const endIndex = end ?? array.length;
            const index = _.slice(array, start, endIndex);
            setResult(index);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017fe3ea673bca1cd06a', Slice);