import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

/**
 * Snackbar Manager
 * (only one snackbar at any time allowed)
 */
class SnackbarManager {
    constructor() {
        /**
         * @private
         * @type {Element}
         */
        this.parentDiv = null;

        /**
         * @private
         * @type {!Array<!Object>}
         */
        this.stack = [];
    }

    /**
     * Init the manager
     * @private
     */
    init() {
        this.parentDiv = document.createElement('div');
        this.parentDiv.setAttribute('id', `snackbar_${Date.now()}`);
        document.body.appendChild(this.parentDiv);
    }

    /**
     * Add a snackbar in the stack
     * @public
     * @param {string} message
     * @param {number} autoHideDuration
     * @param {string} severity
     * @param {string} variant
     * @param {string} anchor
     */
    show(message, autoHideDuration, severity, variant, anchor) {
        // Init manager if necessary
        if (!this.parentDiv) {
            this.init();
        }

        // Get anchor values
        const [verticalAnchor, horizontalAnchor] = anchor.split('-');

        // Add to stack
        this.stack.push({
            message: message?.trim(),
            autoHideDuration: autoHideDuration && autoHideDuration > 0 ? autoHideDuration : null,
            severity: severity,
            variant: variant,
            anchor: { vertical: verticalAnchor, horizontal: horizontalAnchor }
        });

        // Process stack
        this.process();
    }

    /**
     * Show all snackbars from the stack
     * @private
     */
    process() {
        // Stop recursion
        if (this.stack.length === 0) {
            return;
        }

        // Get snackbar params from stack
        const params = this.stack.shift();

        // Close any previous snackbar first
        this.close();

        // Render the new snackbar
        ReactDOM.render((
            <Snackbar
                open={true}
                autoHideDuration={params.autoHideDuration}
                anchorOrigin={params.anchor}
                onClose={(_event, reason) => reason === 'timeout' && this.close()}
            >
                <Alert
                    severity={params.severity}
                    variant={params.variant}
                    sx={{ width: 1 }}
                    onClose={this.close.bind(this)}
                >
                    {params.message}
                </Alert>
          </Snackbar>
        ), this.parentDiv);

        // Recursion
        this.process();
    }

    /**
     * @private
     */
    close() {
        ReactDOM.unmountComponentAtNode(this.parentDiv);
    }
}

let mgr = null;

/**
 * Snackbar Manager singleton
 * @type {function(): SnackbarManager}
 */
export const snackbarManager = () => mgr ??= new SnackbarManager();
