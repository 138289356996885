import {Brick, registerBrick, ErrorFlow, CloudObject} from 'olympe';
import { getLogger } from '@olympeio/core';
import { getAsArray, Helper } from '../helper';

export default class UnionWith extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} first
     * @param {*} second
     * @param {*} transformation
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    async update($, [first, second, transformation], [setResult, setErrorFlow]) {
        const logger = getLogger('Union With');

        // Guards
        if (!(transformation instanceof Brick)) {
            logger.error('Provided transformation input is not a Brick');
            return;
        }

        const firstArray = getAsArray(first);
        const secondArray = getAsArray(second);

        if (firstArray.error || secondArray.error) {
            logger.error(firstArray.error || secondArray.error);
            return;
        }

        const unitedArray = firstArray.array.concat(secondArray.array);
        const nonNullishItems = unitedArray.filter(item => item !== null && item !== undefined);
        const firstNonNullishItem = nonNullishItems.length > 0 ? nonNullishItems[0] : undefined;
        if (firstNonNullishItem !== undefined) {
            const otherNonNullishItem = nonNullishItems.slice(1).find((item) => {
                if (firstNonNullishItem instanceof CloudObject && item instanceof CloudObject) {
                    return firstNonNullishItem.getModelTag() !== item.getModelTag();
                }
                return typeof item !== typeof firstNonNullishItem;
            });
            if (otherNonNullishItem) {
                logger.error('Provided lists combined different types of inputs');
                return;
            }
        }

        try {
            // unite unique values from arrays to resemble combinations done by lodash later in unionWith
            const result = await Helper.uniqueWithAsync($, unitedArray, transformation);
            setResult(result);
        } catch (error) {
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017fe46e17a1f4aa8144', UnionWith);
