import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsArray } from '../../helper';

export default class LastIndexOf extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {*} value
     * @param {number} fromIndex
     * @param {function(number)} setIndex
     * @param {function(*)} setErrorFlow
     */
    update($, [source, value, fromIndex], [setIndex, setErrorFlow]) {
        const logger = getLogger('LastIndexOf');

        // Guards
        if (typeof fromIndex !== 'number') {
            logger.error('Provided fromIndex input is not a number');
            return;
        }

        const { array, error } = getAsArray(source);

        if (!error) {
            const index = _.lastIndexOf(array, value, fromIndex);
            setIndex(index);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017fe0bcc3bd12ab13da', LastIndexOf);