import { registerBrick, Context, ListDef, Property, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';
import AggregateFunction from './AggregateFunction.js';

export default class Mean extends AggregateFunction {


    /**
     * @protected
     * @param {!BrickContext} context
     * @param {ListDef | Array | QueryResult} list
     * @param {Property} prop
     * @param {function(number)} setResult
     */
    update(context, [list, prop], [setResult]) {
        this.aggregate(
            list,
            prop,
            list => {
                let total = 0;
                let validValues = 0;
                list.forEach(
                    value => {
                        if (typeof value !== 'number') {
                            getLogger('Mean').error(`[Mean] '${value}' is not a number!`);
                        } else {
                            total += value;
                            ++validValues;
                        }
                    }
                );
                const result = validValues === 0 ? 0 : total / validValues;
                setResult(result);
            },
        );
    }
}

registerBrick('017a5bdf6f74f56a7c55', Mean);


