import { ListDef, CloudObject, BrickContext } from 'olympe';
import { useProperty } from '@olympeio/core'
import React, {useEffect} from 'react';
import { combineLatest } from 'rxjs';
import { DataGrid, GridToolbar, frFR, enUS, itIT, deDE } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { createEvents } from '../../helpers/_helpers';

export const MUIDataGrid = ({ $, cols, rows, isServerMode, pageSize, onPageSizeCalculated }) => {
    const mode = isServerMode ? 'server' : 'client';
    const locales = { frFR, enUS, itIT, deDE };
    const [localeText, setLocaleText] = React.useState(enUS);
    // it's needed to maintain the color while changing other props, e.g. page, without a blink of color
    const [overrideColor, setOverrideColor] = React.useState($.get('Text Color Override'));
    const color = useProperty($, 'Text Color Override');
    const language = useProperty($, 'Language');
    const clear = useProperty($, 'Clear Selection');

    const events = MUIDataGrid.getEvents($);
    const getPageSize = (height, _rowHeight, isFilterEnabled, showToolbar) => {
        const rowHeight = _rowHeight === 0 ? 10 : _rowHeight;
        const navbarHeight = 56; // set default by MUI Data Grid
        const footerHeight = 52; // set default by MUI Data Grid
        const contentHeight = height - navbarHeight - footerHeight; // actual height of list content
        const toolbarHeight = showToolbar ? 36 : 0; // 36 is toolbar height (default by MUI Datagrid)
        const actualFilterHeight = isFilterEnabled ? 40 : toolbarHeight; // because filterEnabledHeight's higher than toolbar height (set default by MUI Data grid - is 40)
        const size = Math.floor((contentHeight - actualFilterHeight) / rowHeight); // padding

        if (size <= 0) {
            return 1;
        } else if (size > 100) {
            return 100;
        }
        return size;
    };

    React.useEffect(() => {
        const subscription = combineLatest([$.observe('Height'), $.observe('Row Height'), $.observe('Show Filter', false), $.observe('Show Toolbar', false)]).subscribe(
            ([_height, _rowHeight, _showFilter, _showToolbar]) => {
                const actualPageSize = getPageSize(_height, _rowHeight, _showFilter, _showToolbar);
                onPageSizeCalculated(actualPageSize);
            }
        );

        const selectedEntryObserve = $.observe('Selected Entry').subscribe((_selectedEntry) => {
            if (_selectedEntry === null) {
                $.trigger('On Selection Cleared');
            }
        });
        const selectedEntriesObserve = $.observe('Selected Entries').subscribe((_selectedEntries) => {
            if (_selectedEntries === null) {
                // for listen action brick: When user reset Selected Entries value -> It will return null
                $.trigger('On Selection Cleared');
            }
        });

        return () => {
            subscription.unsubscribe();
            selectedEntryObserve.unsubscribe();
            selectedEntriesObserve.unsubscribe();
        };
    }, []);


    // Clear selection
    useEffect(() => {
        const keys = ['Selected Entry', 'Selected Entries'];

        keys.forEach(key => {
            $.remove(key);
        });
    }, [clear]);

    // While changing other properties, e.g. page, useProperty with color contains `undefined`
    // which makes the color to be set to default for a moment
    // with useEffect and useState we can update the color in the editor right away (useProperty con)
    // + get rid of blink of color (having $.get('Color') in useState)
    React.useEffect(() => {
        if (color !== null) {
            setOverrideColor(color);
        }
    }, [color]);

    React.useEffect(() => {
        if (language !== null) {
            setLocaleText(locales[language]);
        }
    }, [language]);

    /**
     * Selection: conditionally fire event On Selected or On Selection Cleared
     * @param {!Object|Object[]} newSelection
     */
    const onSelectionModelChange = (newSelection) => {
        const checkboxSelection = $.get('Checkbox Selection');
        if (checkboxSelection) {
            if ((newSelection || []).length > 0) {
                _setSelectedEntries(newSelection);
                $.trigger('On Selected');
            } else {
                _setSelectedEntries([]);
            }
        } else {
            const newSelect = (newSelection || [''])[0];
            const selectedEntry = $.get('Selected Entry');
            const selected = (rows || [{}]).find((select) => select.id === newSelect) || {};
            if (selectedEntry && selected.tag === selectedEntry.tag) {
                $.remove('Selected Entry');
            } else if (CloudObject.exists(selected.tag)) {
                $.set('Selected Entry', CloudObject.get(selected.tag));
                $.trigger('On Selected');
            }
        }
    };

    const _setSelectedEntries = (newSelection = []) => {
        const selectedRows = [];
        newSelection.forEach((index) => {
            const [selectedRow] = (rows || [{}]).filter((row) => row.id === index);
            CloudObject.exists(selectedRow.tag) && selectedRows.push(CloudObject.get(selectedRow.tag));
        });

        $.set('Selected Entries', selectedRows.length === 0 ? null : selectedRows);
    };

    const getSortModel = ($, cols) => {
        // check if a column should be used for sorting
        let sortModel = $.get('Property to sort');
        const asc = $.get('Sort Ascending');
        if (sortModel && cols.some((c) => c.field === sortModel)) {
            return [{ field: sortModel, sort: asc ? 'asc' : 'desc' }];
        }
        return undefined;
    };

    /**
     * @return {GridRowId[]|string|number}
     */
    const getSelectionModel = () => {
        const selectedEntries = useProperty($, 'Selected Entries');
        const selectedEntry = useProperty($, 'Selected Entry');
        const isCheckboxSelection = useProperty($, 'Checkbox Selection');

        if (isCheckboxSelection) {
            return getSelection(selectedEntries);
        } else {
            const selectionModel = getSelection([selectedEntry || {}]);
            if (selectionModel.length > 0) {
                return selectionModel[0];
            }
            return [];
        }
    };

    /**
     * @param {Array|ListDef} entries
     * @return {Object[]}
     */
    const getSelection = (entries) => {
        const selectedEntries = Array.isArray(entries) ? entries : [];
        if (entries instanceof ListDef) {
            entries.forEachCurrentValue(entry => selectedEntries.push(entry));
        }
        return rows.filter((row) => {
            return selectedEntries.some(entry => entry.tag === row.tag);
        }).map(row => row.id);
    };

    const rowLambda = $.get('Customize Row');
    const muiSxJson = rowLambda ? MUIDataGrid.getMuiSxJson($, rows, rowLambda) : {};
    const sortModel = getSortModel($, cols);
    const theme = createTheme({
        palette: {
            primary: { main: overrideColor === undefined ? '#1976D2' : overrideColor.toHexString() }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <DataGrid
                // Properties
                rows={rows}
                columns={cols}
                rowHeight={useProperty($, 'Row Height')}
                page={useProperty($, 'Page')}
                pageSize={pageSize}
                // if more than one value, select between page sizes. Added here with single value to remove a warning emitted by material ui...
                rowsPerPageOptions={[pageSize]}
                components={{
                    Toolbar: () => useProperty($, 'Show Toolbar') ? <GridToolbar /> : null,
                }}
                disableColumnFilter={useProperty($, 'Disable Filter Per Column')}
                checkboxSelection={useProperty($, 'Checkbox Selection')}
                sortModel={sortModel}
                selectionModel={getSelectionModel()}
                paginationMode={mode}
                sortingMode={mode}
                filterMode={mode}
                rowCount={isServerMode ? useProperty($, 'Row Count') : rows.length}
                loading={useProperty($, 'Is Loading')}

                // Events
                onPageChange={events.onPageChange}
                onSortModelChange={events.onSortModelChange}

                // Callbacks
                onSelectionModelChange={onSelectionModelChange}
                getRowClassName={params => `row-style--${params.row.id}`}
                // UI
                sx={{
                    ...muiSxJson,
                    '& .MuiDataGrid-cell': {
                        whiteSpace: 'nowrap',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none !important'
                    }
                }}
                localeText={localeText?.components.MuiDataGrid.defaultProps.localeText}
            />
        </ThemeProvider>
    );
};

/**
 * @param {!BrickContext} $
 * @param {!Array<Object>} rows
 * @param {Brick} rowLambda
 * @return {!Object}
 */
MUIDataGrid.getMuiSxJson = ($, rows, rowLambda) => {
    const runRowLambda = (row) => {
        const [objectInput] = rowLambda.getInputs();
        const [fontColorOutput, colorOutput] = rowLambda.getOutputs();
        const lambda$ = $.runner(rowLambda).set(objectInput, row.tag);

        const fontColor = lambda$.get(fontColorOutput);
        const backgroundColor = lambda$.get(colorOutput);
        return { fontColor, backgroundColor };
    };

    const muiSxJson = {};
    rows.forEach(row => {
        const rowStyle = runRowLambda(row);
        const rowId = `& .row-style--${row.id}`;
        muiSxJson[rowId] = {
            backgroundColor: rowStyle.backgroundColor?.toHexString(),
            color: rowStyle.fontColor?.toHexString(),
        };
    });
    return muiSxJson;
};

/**
 * @param {!BrickContext} $
 * @return {!Array<!Object>}
 */
MUIDataGrid.getEvents = ($) => {
    return createEvents([
        {
            name: 'onPageChange',
            event: $.trigger.bind($, 'On Page Change'),
            before: (newPage) => {
                $.getProperty('Page').set(newPage);
            },
        },
        {
            name: 'onSortModelChange',
            event: () => {
                const onSort = $.get('On Sort');
                if (onSort) {
                    const [startInput, sortPropertyInput, sortOrderInput] = onSort.getInputs();
                    $.runner(onSort)
                        .set(sortPropertyInput, $.get('Current sorting property'))
                        .set(sortOrderInput, $.get('Current sorting order'))
                        .trigger(startInput)
                        .destroy();
                }
            },
            before: (params) => {
                if (params[0]) {
                    $.set('Current sorting order', params[0].sort);
                    $.set('Current sorting property', params[0].field);
                } else {
                    $.set('Current sorting order', '');
                    $.set('Current sorting property', '');
                }
            },
        },
    ]);
};
