import { Brick, registerBrick } from 'olympe';

export default class ColorToHex extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {Color} color
     * @param {function(string)} setHex
     * @param {function(string)} setRgb
     */
    update($, [color], [setHex, setRgb]) {
        setHex(color.toHexString());
        setRgb(color.toRGBString());
    }
}

registerBrick('01802342ba998a88c3b9', ColorToHex);
