import { Brick, registerBrick } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';

export default class ConcatAutoincrementedId extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} prefix
     * @param {function(string)} setResult
     */
    update($, [prefix], [setResult]) {
        const logger = getLogger('Unique Id');

        // Guards
        if (typeof prefix !== 'string') {
            logger.error('Provided prefix input is not a string');
            return;
        }

        setResult(_.uniqueId(prefix || ''));
    }
}

registerBrick('017ff8c3d45b23a21d4a', ConcatAutoincrementedId);