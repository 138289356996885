import { UIBrick, registerBrick } from 'olympe';
import { combineProps, createEvents, getObjectFromJSON } from "./_helpers";
import ReactDOM from 'react-dom';
import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';
import { GetLocalisedText } from "../i18n/GetLocalisedText";

export class LocalisedMUISelect extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const events = createEvents([
            {
                name: 'onChange',
                event: context.trigger.bind(context, 'On Change'),
                before: (event) => {
                    context.getProperty('Value').set(event.target.value);
                }
            }
        ]);
        combineProps([
            {name: 'isLocalised', prop: context.getProperty('Is Localised')},
            {name: 'value', prop: context.getProperty('Value')},
            {name: 'muiVariant', prop: context.getProperty('MUI Variant')},
            {name: 'muiSize', prop: context.getProperty('MUI Size')},
            {name: 'required', prop: context.getProperty('Required')},
            {name: 'id', prop: context.getProperty('MUI DOM ID')},
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'disabled', prop: context.getProperty('Disabled')},
            {name: 'muiError', prop: context.getProperty('MUI Error')},
            {name: 'muiFullWidth', prop: context.getProperty('MUI Full Width')},
            {name: 'muiLabel', prop: context.getProperty('MUI Label')},
            {name: 'muiHelperText', prop: context.getProperty('MUI Helper Text')},
            {name: 'optionSeparator', prop: context.getProperty('Option Separator')},
            {name: 'optionValues', prop: context.getProperty('Option Values')},
            {name: 'optionLabels', prop: context.getProperty('Option Labels')},
        ]).subscribe(props => {
            // Guards
            if(props.muiVariant !== 'standard' && props.muiVariant !== 'outlined' && props.muiVariant !== 'filled')
                return;

            ReactDOM.render((<LocalisedMUISelect.Component {...props} {...events}/>), elementDom);
        });
    }
}

registerBrick('01791d9eb087c37d5597', LocalisedMUISelect);

/**
 * @param {{
 *     isLocalised: boolean
 *     value: string
 *     muiVariant: string
 *     muiSize: string
 *     required: boolean
 *     id: string
 *     styleJSON: string
 *     muiColor: string
 *     disabled: boolean
 *     muiError: boolean
 *     muiFullWidth: boolean
 *     muiLabel: string
 *     muiHelperText: string
 *     optionSeparator: string
 *     optionValues: string
 *     optionLabels: string
 *     onChange: function(*)
 *   }} props
 * @return {Element}
 */
LocalisedMUISelect.Component = (props) => {
    // Localised props
    const label = props.isLocalised ? GetLocalisedText.getTranslation(props.muiLabel) : props.muiLabel;
    const helperText = props.isLocalised ? GetLocalisedText.getTranslation(props.muiHelperText) : props.muiHelperText;
    const style = getObjectFromJSON(props.styleJSON);

    // Select options
    const labels = props.optionLabels.split(props.optionSeparator);
    const values = props.optionValues.split(props.optionSeparator)
        .map((value, index) => {
            const label = props.isLocalised ? GetLocalisedText.getTranslation(labels[index]) : labels[index];
            return { value: value, label: label };
        });

    // Element
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <TextField
                    select={true}
                    value={props.value}
                    variant={props.muiVariant}
                    size={props.muiSize}
                    required={props.required}
                    id={props.id}
                    style={style}
                    color={props.muiColor}
                    disabled={props.disabled}
                    error={props.muiError}
                    fullWidth={props.muiFullWidth}
                    label={label}
                    helperText={helperText}
                    onChange={props.onChange.bind(this)}
                >
                    {values.map(value => (<MenuItem value={value.value} key={value.value}>{value.label}</MenuItem>))}
                </TextField>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};