import { registerBrick, GlobalProperties } from 'olympe';
import { useProperty, ReactBrick, cssToSxProps, ifNotTransparent, ifNotNull } from '@olympeio/core';
import { combineLatestWith } from 'rxjs/operators';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default class RendererComponent extends ReactBrick {

    /**
     * @override
     */
    setupExecution($) {
        return $.observe('Renderer', false).pipe(combineLatestWith($.observe('Hidden')));
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const [renderer, hidden] = props.values;
            const [borderColor, borderRadius, borderWidth, cssProperty, defaultColor] = [
                'Border Color', 'Border Radius', 'Border Width', 'CSS Property', 'Default Color'
            ].map((p) => useProperty($, p));

            // No renderer -> show a placeholder in DRAW
            if (!hidden && !renderer && $.get(GlobalProperties.EDITION, true)) {
                return <Box sx={{backgroundColor: 'lightgrey', width: 1, height: 1, overflow: 'hidden'}}>
                    <Typography sx={{color: 'black', padding: 1}}>
                        <b>Visual Renderer</b><br/>
                        Specify <code>Renderer</code> property to use the component.
                    </Typography>
                </Box>;
            }

            const [boxEl, setBoxEl] = React.useState(null);

            // Create and destroy runner using useEffect
            React.useEffect(() => {
                let renderer$;
                if (boxEl && !hidden && renderer) {
                    renderer$ = $.runner(renderer).setParentElement(boxEl);
                    RendererComponent.getPropertiesToRepeat().forEach(prop => {
                        renderer$.repeat(prop, $.observe(prop));
                    });
                    RendererComponent.getEventsToForward().forEach(event => {
                        renderer$.observe(event).subscribe($.trigger.bind(event));
                    });
                }
                return () => {
                    renderer$?.destroy();
                };
            }, [boxEl]);

            // Execute runner
            return !hidden && <Box
                ref={setBoxEl}
                sx={{
                    width: 1,
                    height: 1,
                    boxSizing: 'border-box',
                    position: 'absolute',
                    overflow: useProperty($, 'Allow Content Overflow') ? 'visible' : 'hidden',
                    ...ifNotNull('borderWidth', useProperty($, 'Border Width')),
                    ...ifNotTransparent('backgroundColor', defaultColor),
                    ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                    ...ifNotTransparent('borderColor', borderColor),
                    ...ifNotTransparent('borderStyle', 'solid', borderColor),
                    ...ifNotNull('boxSizing', 'border-box', borderWidth),
                    ...cssToSxProps(useProperty($, cssProperty))
                }}
            />;
        };
    }

    /**
     * @protected
     * @return {!Array<string>}
     */
    static getPropertiesToRepeat() {
        return [
            'Allow Content Overflow',
            'Height',
            'Tab Index',
            'Width',
            'Z-Index'
        ];
    }

    /**
     * @protected
     * @return {!Array<string>}
     */
    static getEventsToForward() {
        return ['On Click'];
    }
}

registerBrick('0188245dc1cff09a2cba', RendererComponent);