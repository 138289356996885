import { registerBrick, QueryResult, ListDef } from "olympe";
import { ReactBrick, useProperty, cssToSxProps, ifNotTransparent, ifNotNull, getLogger, useMUITheme, jsonToSxProps } from "@olympeio/core";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { combineLatestWith, map, switchMap } from "rxjs/operators";
import { of, combineLatest } from "rxjs";

import { styled, ThemeProvider } from "@mui/material/styles";
import { Typography, StepLabel, Step, Stepper, Box, StepConnector } from "@mui/material";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { getIcon } from '@olympeio-extensions/commons';

const StepWithStyling = styled(Step)(({ ownerState }) => ({
    [`.stepper-label--bold .Mui-active`]: {
        fontWeight: "700",
    },
    [`.MuiStepLabel-label`]: {
        color: ownerState.color || 'black',
        fontFamily: ownerState.fontFamily
    },
    [`.stepper-icon`]: {
        fontFamily: ownerState.fontFamily
    }
}));

export default class StepperComp extends ReactBrick {
    /**
     * @override
     */
    setupExecution($) {
        // List of properties to observe normally
        const properties = ["Hidden"];
        // Observe the list size to re-trigger an update if necessary
        const observeList = $.observe("List of steps", false);
        const observeListAsArray = observeList.pipe(
            switchMap((list) => {
                let sizeObservable;
                if (Array.isArray(list)) {
                    sizeObservable = of(list.length);
                } else if (list instanceof QueryResult) {
                    sizeObservable = of(list.size());
                } else if (list instanceof ListDef) {
                    sizeObservable = list.observeSize();
                } else {
                    sizeObservable = of(0);
                }
                return sizeObservable.pipe(
                    combineLatestWith($.observe("Reverse", false)),
                    map(([size]) => {
                        const reverse = false;
                        let elements = [];
                        if (size > 0) {
                            // Array case

                            if (Array.isArray(list)) {
                                elements = !reverse ? list : list.reverse();
                            }

                            // QueryResult case
                            else if (list instanceof QueryResult) {
                                elements = !reverse ? list.toArray() : list.toArray().reverse();
                            }

                            // ListDef case
                            else {
                                list.forEachCurrentValue((value, key) => {
                                    elements.push({
                                        value: value,
                                        rank: list.getCurrentRank(key),
                                    });
                                });
                                if (!reverse) {
                                    elements = elements.sort((a, b) => a.rank - b.rank);
                                } else {
                                    elements = elements.sort((a, b) => b.rank - a.rank);
                                }
                                elements = elements.map((e) => e.value);
                            }
                        }
                        return elements;
                    })
                );
            })
        );
        return combineLatest([observeListAsArray, ...properties.map((p) => $.observe(p, false))]);
    }

    /**
     * @override
     */
    static getReactComponent($) {
        const objectToLabels = $.get("Object to label");
        const objectToIcons = $.get("Object to icon");
        const onStepClickProps = $.get("On Step Click");

        const onObjectTolabels = objectToLabels ? [$.runner(objectToLabels), ...objectToLabels.getInputs(), ...objectToLabels.getOutputs()] : null;

        const onObjectToIcons = objectToIcons ? [$.runner(objectToIcons), ...objectToIcons.getInputs(), ...objectToIcons.getOutputs()] : null;
        const onStepClick = onStepClickProps ? [$.runner(onStepClickProps), ...onStepClickProps.getInputs()] : null;
        return (props) => {
            const [elements, hidden] = props.values;
            if ((elements || []).length === 0 || hidden)
                return (
                    // No list or renderer
                    <Box
                        sx={{
                            backgroundColor: "lightgrey",
                            width: 1,
                            height: 1,
                            overflow: "hidden",
                        }}
                    >
                        <Typography sx={{ color: "black", padding: 1 }}>
                            <b>Stepper</b>
                        </Typography>
                    </Box>
                );

            return <StepperComp.Component $={$} steps={elements} onObjectTolabels={onObjectTolabels} onObjectToIcons={onObjectToIcons} onStepClick={onStepClick} />;
        };
    }
}

StepperComp.Component = ({ $, steps, onObjectTolabels, onObjectToIcons, onStepClick }) => {
    const [activeStep, setActiveStep] = useState(0);
    const boxRef = useRef();
    const width = useProperty($, "Width");
    const height = useProperty($, "Height");
    const highlightCurrentStep = useProperty($, "Highlight selected step");

    const borderRadius = useProperty($, "Border Radius");
    const borderColor = useProperty($, "Border Color");
    const borderWidth = useProperty($, "Border Width");
    const defaultColor = useProperty($, "Default Color");
    const cssProperty = useProperty($, "Css Property");
    const allowContentOverflow = useProperty($, "Allow Content Overflow");
    const labelDisplayLocation = useProperty($, "Label display location");
    const backgroundIconSize = useProperty($, "Background Icon Size");
    const muiSxJson = useProperty($, 'MUI sx [json]');
    const fontFamily = useProperty($, 'Font Family');
    const textColorOveride = useProperty($, 'Text Color Override');

    useEffect(() => {
        const observeMoveToNextStep = $.observe("Move to next step");
        const observeMoveToPreviousStep = $.observe("Move to previous step");
        const observeCurrentStep = $.observe("Selected step");

        observeCurrentStep.subscribe((currStep) => {
            if (typeof currStep.position === "number" && isFinite(currStep.position) && currStep.position >= 0) {
                setActiveStep(() => currStep.position);
            } else {
                const listofStep = $.get("List of steps");
                let findIndex = -1;
                if (Array.isArray(listofStep)) {
                    // json case
                    findIndex = steps.findIndex((x) => x.label === currStep.label);
                } else {
                    // List case
                    findIndex = steps.findIndex((x) => x === currStep);
                }
                if (findIndex !== -1) {
                    setActiveStep((prev) => findIndex);
                } else {
                    getLogger("Set Current Step").error("can't detect your step you input");
                }
            }
        });
        observeMoveToNextStep.subscribe((moveToNextStep) => {
            if (moveToNextStep) {
                setActiveStep((prev) => {
                    if (prev >= steps.length - 1) {
                        $.set("Selected step", { ...steps[prev + 1], position: prev });
                    } else {
                        $.set("Selected step", { ...steps[prev + 1], position: prev + 1 });
                    }
                });
            }
        });
        observeMoveToPreviousStep.subscribe((moveToPreviousStep) => {
            if (moveToPreviousStep) {
                setActiveStep((prev) => {
                    if (prev === 0 || prev === undefined) {
                        $.set("Selected step", { ...steps[0], position: 0 });
                    } else {
                        $.set("Selected step", { ...steps[prev - 1], position: prev - 1 > 0 ? prev - 1 : 0 });
                    }
                });
            }
        });
    }, []);

    useLayoutEffect(() => {
        if (boxRef && boxRef.current) {
            boxRef.current.parentElement.style.overflow = allowContentOverflow ? "unset" : "hidden";
        }
    }, [boxRef, allowContentOverflow]);

    const setObjectToLabel = (item) => {
        if (onObjectTolabels) {
            const [property$, dataInput, output] = onObjectTolabels;
            property$.set(dataInput, item);
            return property$.get(output);
        }
        return item.label;
    };

    const setObjectToIcon = (item) => {
        if (onObjectToIcons) {
            const [property$, dataInput, output] = onObjectToIcons;
            property$.set(dataInput, item);
            return property$.get(output);
        }
        return item.icon;
    };

    const onClickStep = (step, position) => {
        if (onStepClick) {
            const [onStepClickSignature$, ctrlflowInput, currentStepObj, currentStepRank] = onStepClick;
            onStepClickSignature$.set(currentStepObj, step);
            onStepClickSignature$.set(currentStepRank, position);
            onStepClickSignature$.trigger(ctrlflowInput);
        }
    };

    const theme = useMUITheme($);

    return (
        <ThemeProvider theme={theme}>
            <Box ref={boxRef}
                    sx={{
                        width: 1,
                        height: 1,
                        boxSizing: 'border-box',
                        // Border and background
                        ...ifNotTransparent('borderColor', borderColor),
                        ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                        ...ifNotNull('borderWidth', `${borderWidth}px`, borderWidth),
                        ...ifNotTransparent('borderStyle', 'solid', borderColor),
                        ...ifNotTransparent('backgroundColor', defaultColor),
     
                        '&:hover': {
                            ...ifNotTransparent('borderColor', borderColor),
                            ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                            ...ifNotNull('borderWidth', `${borderWidth}px`, borderWidth),
                            ...ifNotTransparent('borderStyle', 'solid', borderColor),
                            ...ifNotTransparent('backgroundColor', defaultColor),
                        },
                        // Additional
                        ...cssToSxProps(cssProperty),
                        ...jsonToSxProps(muiSxJson),
                }}>
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel={!!labelDisplayLocation}
                    connector={<StepConnectorWithStyling ownerState={{ backgroundIconSize }}/>}
                    orientation={width >= height ? 'horizontal' : 'vertical'}
                >
                {steps.map((step, index) => (
                    <StepWithStyling
                        ownerState={{
                            fontFamily,
                            ...ifNotTransparent('color', textColorOveride)
                        }}
                        key={index}
                        onClick={() => {
                            onClickStep(step, index);
                        }}
                    >
                        <StepLabel
                            className={`stepper-label ${highlightCurrentStep ? "stepper-label--bold" : ""}`}
                            StepIconComponent={(props) => <ColorlibStepIcon {...props} className="stepper-icon" index={index} iconString={setObjectToIcon(step)} $={$} />}
                        >
                            {setObjectToLabel(step)}
                        </StepLabel>
                    </StepWithStyling>
                ))}
                </Stepper>
            </Box>
        </ThemeProvider>
    );
};

const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
    color: ownerState.backgroundIcon ? ownerState.inactiveStepColor : 'transparent',
    position: "relative",
    zIndex: 1,
    width: ownerState.backgroundIconSize || 40,
    height: ownerState.backgroundIconSize || 40,

    '& .stepper__background-icon-wrap': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
    },
    '& .stepper__background-icon-wrap .material-icons': {
        fontSize: ownerState.backgroundIconSize,
    },
    '& .stepper__front-icon': {
        zIndex: 2,
        color: ownerState.frontIconColor,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    ...(ownerState.active && {
        color: ownerState.backgroundIcon ? ownerState.selectedStepColor : 'transparent',
    }),
    ...(ownerState.completed && {
        color: ownerState.backgroundIcon ? ownerState.finishedStepColor : 'transparent',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, iconString, index, $ } = props;
    const backgroundIcon = useProperty($, "Background Icon") ?? '';
    
    const inactiveStepColor = useProperty($, "Inactive steps color")?.toHexString();
    const finishedStepColor = useProperty($, "Finished steps color")?.toHexString();
    const selectedStepColor = useProperty($, "Selected step color")?.toHexString();
    const backgroundIconSize = useProperty($, "Background Icon Size");
    const frontIconColor = useProperty($, "Front Icon Color")?.toHexString();

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, inactiveStepColor, finishedStepColor, selectedStepColor, backgroundIcon, backgroundIconSize, frontIconColor }} className={className}>
           {backgroundIcon && <div className='stepper__background-icon-wrap'>
                {getIcon(backgroundIcon)}
            </div>}
            <div className="stepper__front-icon">{iconString ? getIcon(iconString) : index + 1}</div>
        </ColorlibStepIconRoot>
    );

}

const StepConnectorWithStyling = styled(StepConnector)(({ownerState}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: ownerState.backgroundIconSize / 2,
    },
    [`&.${stepConnectorClasses.vertical}`]: {
        marginLeft: ownerState.backgroundIconSize / 2,
    },
}));

registerBrick("0183d50e16a201c3836f", StepperComp);
