import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsJson } from '../../helper';

export default class UpdateObject extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {string} path
     * @param {*} updater
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    async update($, [source, path, updater], [setResult, setErrorFlow]) {
        const logger = getLogger('Update object');

        // Guards
        if (!(updater instanceof Brick)) {
            logger.error('Provided updater input is not a Brick');
            return;
        }
        if (typeof path !== 'string') {
            logger.error('Provided path input is not a string');
            return;
        }

        const { json, error } = getAsJson(source);

        if (!error) {
            const result = _.update(json, path,(value) => {
                return this.updateValue($, value, updater);
            });
            setResult(result);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {!Object} value
     * @param {!Brick} updater
     * @return {!Object}
     */
    updateValue($, value, updater) {
        const [valueInput] = updater.getInputs();
        const [resOutput] = updater.getOutputs();

        const predicate$ = $.runner(updater).set(valueInput, value);
        const result = predicate$.get(resOutput);
        predicate$.destroy();
        return result;
    }
}

registerBrick('017ffa3b501adf4ac81e', UpdateObject);