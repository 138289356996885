import { ListDef, CloudObject, QueryResult, BrickContext, Predicate, PropertyPrimitive, StringModel, generateTag } from 'olympe';
import { getLogger, useProperty } from '@olympeio/core'
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { of, Observable } from 'rxjs';
import { combineLatestWith, switchMap } from 'rxjs/operators';
import { filterByValue, debounce } from '../../helpers/_helpers';
import { MUIDataGrid } from './MUIDataGrid.jsx';
import { GridCheckIcon, GridCloseIcon } from '@mui/x-data-grid';

export const DatagridComponent = ({ $, columns, renderers, refresh }) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [cols, setCols] = useState(columns);
    const [pageSize, setPageSize] = useState(0);

    const disableSort = useProperty($, 'Disable Sort Per Column', false);
    const isServerMode = useProperty($, 'Is Server Mode', false);
    const filterText = useProperty($, 'Filter Text', false);

    const searchDebouncer = useCallback(
        debounce((text, rows) => {
            if (text === '') {
                setFilteredRows(rows);
            }
            else if (!isServerMode) {
                const dateFormat = $.get('Date Format');
                setFilteredRows(filterByValue(rows, text, dateFormat));
            }
        }, 250),
        []
    );

    // TODO WIP: Finish the cleanup when resizing the size of the list
    // // Cleaning up Existing Runners is a heavy operation, with great power comes great responsibility
    // // therefore we wrap it up in a debouncer to make sure it is not called too often ( for example if the user
    // // enjoys resizing his datagrid a tremendous amount of time in a short window)
    // const cleanupAllRunnersDebouncer = useCallback(
    //     debounce(() => {
    //         const allRunners = $.get('allRunners');
    //         allRunners.forEach(runners => {
    //             // Too many runners for the number of rows displayed
    //             if (runners.length > pageSize) {
    //                 for (let i = pageSize; i < runners.length; i++) {
    //                     const runner = runners[i];
    //                     if (runner && runner.destroy)
    //                         runner.destroy(); // can be undefined (or 0) or a runner
    //                 }
    //             }
    //             // // Not enough runners for the number of rows displayed
    //             // else {
    //             //     for (let i = pageSize; i < runners.length; i++) {
    //             //         const runner = runners[i];
    //             //         if (runner && runner.destroy)
    //             //             runner.destroy(); // can be undefined (or 0) or a runner
    //             //     }
    //             // }
    //         });
    //
    //         allRunners.clear();
    //     }, 3000),
    //     []
    // );

    useEffect(() => {
        if (pageSize > 0) {
            // if ($.get('allRunners')?.size > 0) {
            //     cleanupAllRunnersDebouncer();
            // }
            const allRunners = new Map();

            // When we get a Page Size, initialize runners for each Custom Renderers
            for (let i = 0; i < renderers.length; i++) {
                const renderer = renderers[i];
                const id = renderer.tag;
                const runners = [];

                for (let j = 0; j < pageSize; j++) {
                    runners.push(0);
                }

                allRunners.set(id, runners);
            }
            $.set('allRunners', allRunners);
        }
    }, [pageSize]);

    useEffect(() => {
        const observeList = $.observe('Data (local)', false);
        const observeListSize = observeList.pipe(switchMap(list => getSizeOfList(list)));
        const subscription = observeList.pipe(combineLatestWith(observeListSize)).subscribe(([_data, size]) => {
            if (columns && columns.length > 0 && _data) {
                getRows($, _data, columns).then((r) => {
                    setRows(r);
                    searchDebouncer(filterText, r);
                });
            }
        });
        return () => { subscription.unsubscribe(); };
    }, [columns, refresh]);

    // On Search Use Effect
    useEffect(() => {
        if (rows.length > 0) {
            searchDebouncer(filterText, rows);
        }
    }, [filterText]);

    // Add function with cell rendering to expected columns
    useEffect(() => {
        const mappedColumns = columns.map(col => {
            const renderer = renderers[col.rendererIndex];
            if (renderer) {
                col.renderCell = (params) => {
                    params.rowHeight = $.get('Row Height');
                    return renderColumn($, renderer, params);
                };
            }
            else if (col.type === "boolean") {
                col.renderCell = (params) => {
                    const value = params.row[col.field];
                    return value === true ? <GridCheckIcon /> : value === false ? <GridCloseIcon /> : ""
                };
            }
            col.sortable = !(!!col.renderCell || disableSort);
            return col;
        });
        setCols(mappedColumns);
    }, [renderers]);

    /**
     * @param {!BrickContext} $
     * @param {Brick} renderer
     * @param {Object} params
     */
    const renderColumn = ($, renderer, params) => {
        const id = generateTag();
        return (
            <Box
                id={id}
                className={'custom-renderer'}
                style={{ border: 'none', outline: 'none', color: 'transparent' }}
                //Cell rendering
                ref={el => {
                    if (el !== null) {
                        // If no renderer yet, create one for the data row id. We need to know the number of runners we will create therefore
                        // we need to know the pageSize (number of Rows) since one row = one runner
                        if (el.children.length === 0 && pageSize > 0) {
                            renderColumnStyle($, el, renderer, params);
                        }
                    }
                }}
            />
        );
    }

    /**
     * @param {!BrickContext} $
     * @param {!Object} el
     * @param {Brick} renderer
     * @param {!Object} params
     */
    const renderColumnStyle = ($, el, renderer, params) => {
        const selectedEntries = $.get('Selected Entries') || [];
        const isSelected = selectedEntries.filter(selectedRow => selectedRow.tag === params.row.tag).length !== 0;
        const rendererId = renderer.tag;
        // get all runners for the current Custom Renderer
        const allRunners = $.get('allRunners').get(rendererId);
        let runner;
        let runnerParent;
        if (allRunners) {
            const rowIndex = params.id % pageSize;
            // if no runner exists for this Row index, create one
            if (!allRunners[rowIndex]) {
                // Create a Runner
                runner = $.runner(renderer);
                runnerParent = document.createElement('div');
                runnerParent.setAttribute('id', params.row.tag);
                allRunners[rowIndex] = runner;
            }
            // or retrieve info from the previous runner so that we can update it and reuse it
            else {
                runner = allRunners[rowIndex];
                runnerParent = runner.get('__container');
            }

            // Attach runner to the row cell
            el.appendChild(runnerParent);

            // update runner's row data
            const renderer$ = runner
                .set('Current Index', params.id % pageSize)
                .set('Current Item', CloudObject.get(params.row.tag))
                .set('Is Selected', isSelected)
                .set('Current List', $.get('Data (local)'))
                .set('__container', runnerParent)
                .setParentElement(runnerParent);

            renderer$.set('Width', params.colDef.width);
            el.style.width = `${params.colDef.width}px`;

            renderer$.set('Height', params.rowHeight);
            el.style.height = `${params.rowHeight}px`;
        }
    };

    /**
     * @private
     * @param {*} list
     * @return {Observable<number>}
     */
    const getSizeOfList = (list) => {
        if (Array.isArray(list)) {
            return of(list.length);
        } else if (list instanceof QueryResult) {
            return of(list.size());
        } else if (list instanceof ListDef) {
            return list.observeSize();
        }
        if (list !== null) {
            getLogger('Datagrid').error('Data (local) supposed to be ListDef, Array or QueryResult');
        }
        return of(0);
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {Object[]} data
     * @param {Object[]} columns
     * @return {*[]}
     */
    const getRows = async ($, data, columns) => {
        let index = 0;
        let entries = Array.isArray(data) ? data : [];
        if (data instanceof QueryResult) {
            entries = data.toArray();
        } else if (data instanceof ListDef) {
            data.forEachCurrentValue(entry => entries.push(entry));
        }
        return await Promise.all(entries.map(
            async (entry) => await processRow($, index++, entry, columns)
        ));
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {number} index
     * @param {!CloudObject} instance
     * @param {!Array} columns
     * @return {Object}
     */
    const processRow = async ($, index, instance, columns) => {
        const row = {
            id: index,
            filterable: true,
            tag: instance.getTag(), // this prop is not part of the DataGrid API! It is useful for the selection
        };

        // Append properties values, in the order defined
        for (const col of columns) {
            let fieldValue = '';
            if (col.relation) {
                fieldValue = await getRelatedPropertyValue($, instance, col);
            } else {
                fieldValue = getPropertyValue(instance, col);
            }
            row[col.field] = fieldValue;
        }
        return row;
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {!CloudObject} instance
     * @param {!Object} column
     * @return {string}
     */
    const getRelatedPropertyValue = async ($, instance, column) => {
        const relatedInstance = await instance.followSingle(column.relation).execute();
        return relatedInstance !== null ? getPropertyValue(relatedInstance, column) : '-';
    }

    /**
     * @private
     * @param {!CloudObject} instance
     * @param {!Object} column
     * @return {string}
     */
    const getPropertyValue = (instance, column) => {
        if (column.model && column.model.name() === 'Enum') {
            return getDisplayName(instance.get(column.propTag), column.propTag);
        }
        // Get the property value depending on its type
        if (['string', 'dateTime', 'boolean'].includes(column.type)) {
            return instance.get(column.propTag);
        } else if (column.type === 'number') {
            return String(instance.get(column.propTag));
        }
        return '';
    }

    const getDisplayName = (value, property) => {
        const enumValue = property.follow(PropertyPrimitive.typeRel).follow(PropertyPrimitive.instancesRel)
            .filter(Predicate.equals(StringModel.valueProp, value)).executeFromCache().getFirst();

        return (enumValue && enumValue.name()) || value;
    };

    return (
        <MUIDataGrid
            $={$}
            rows={filteredRows}
            cols={cols}
            isServerMode={isServerMode}
            onPageSizeCalculated={setPageSize}
        />
    );
};
