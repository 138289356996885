import { Brick, registerBrick } from 'olympe';
import { getLogger} from "@olympeio/core";

export default class Math2 extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} name
     * @param {number} param1
     * @param {number} param2
     * @param {function(number)} setResult
     */
    update(context, [name, param1, param2], [setResult]) {
        const func = Math[name];
        const logger = getLogger('Math Functions');

        if (!func) {
            logger.error(`Unknown function ${name}!`);
        } else if (func.length !== 2) {
            logger.error(`${name} is not a double param function!`);
        } else if (typeof param1 !== 'number' || typeof param2 !== 'number') {
            logger.error(`Input is not a number!`);
        } else {
            setResult(func(param1, param2));
        }
    }
}

registerBrick('017a8afddc618a563417', Math2);
