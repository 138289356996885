import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getIcon } from '@olympeio-extensions/commons';
import { useStyle } from '../helpers/style.jsx';

export const OptionItem = ({
    id,
    textProp,
    iconProp,
    navigationPath,
    childOptions,
    onClick,
    activeKey,
    styling,
    nestedLevel,
}) => {
    const classes = useStyle(styling);

    const getListOfMenus = () => {
        let list = JSON.parse(localStorage.getItem('listOfMenus'));
        if (!list) {
            list = createNewMenuList();
        }
        return list;
    };

    const menuObjectId = `${textProp}_${navigationPath}`;

    const createNewMenuList = () => {
        let list = [];
        const menuObject = {
            'id': menuObjectId,
            'opened': false,
            'selected': false,
        };
        list.push(menuObject);
        return list;
    };

    const unselectUnactiveOptions = (id) => {
        const listOfMenus = getListOfMenus();
        const changedListOfMenus = listOfMenus.map((menuObj) => {
            menuObj.selected = menuObj.id == id;
            return menuObj;
        });
        return changedListOfMenus;
    }

    const getMenuObject = (id) => {
        const listOfMenus = getListOfMenus();
        let menuObject = listOfMenus.find(menu => menu.id == id);
        if (!menuObject) {
            menuObject = {
                'id': menuObjectId,
                'opened': false,
                'selected': false,
            };
            listOfMenus.push(menuObject);
        }
        return menuObject;
    };

    const isOpened = (id) => {
        const menuObject = getMenuObject(id);
        return menuObject ? menuObject.opened === true : false;
    };

    const [isOptionOpened, setIsOptionOpened] = React.useState(isOpened(menuObjectId));

    const saveToMenuList = (menuObject) => {
        const listOfMenus = getListOfMenus();
        const menuObjectIndex = listOfMenus.findIndex((obj => obj.id === menuObjectId));
        menuObjectIndex < 0 ? listOfMenus.push(menuObject) : listOfMenus[menuObjectIndex] = menuObject;
        localStorage.setItem('listOfMenus', JSON.stringify(listOfMenus));
    }

    React.useEffect(() => {
        const menuObject = getMenuObject(menuObjectId);
        menuObject['opened'] = isOptionOpened;
        saveToMenuList(menuObject);
    }, [isOptionOpened]);


    const handleClick = (id, navigationPath) => {
        setIsOptionOpened(!isOptionOpened);
        if (navigationPath) {
            const listOfMenus = unselectUnactiveOptions(menuObjectId);
            localStorage.setItem('listOfMenus', JSON.stringify(listOfMenus));
        }
        onClick(id, navigationPath);
    };

    return (
        <>
            <ListItem button
                key={id}
                onClick={() => handleClick(id, navigationPath)}
                classes={{ root: classes.root, selected: classes.selected }}
                selected={id === activeKey}
            >
                <ListItemIcon style={{ paddingLeft: nestedLevel * 20 }}>
                    {iconProp && (
                        <IconButton className={classes.button}>
                            {getIcon(iconProp)}
                        </IconButton>
                    )}
                </ListItemIcon>
                <ListItemText primary={textProp} classes={{ root: classes.text }} />
                {childOptions && childOptions.length ? (
                    <ListItemIcon>
                        <ExpandMore
                            sx={{
                                mr: -1,
                                transform: !isOptionOpened ? 'rotate(0)' : 'rotate(-180deg)',
                                transition: '0.2s',
                            }}
                        />
                    </ListItemIcon>
                ) : null}
            </ListItem>
            <Collapse
                in={isOptionOpened}
                component="li"
                timeout="auto"
                unmountOnExit
                classes={{ root: classes.nested }}
            >
                <List disablePadding>
                    {childOptions.map(({ id, textProp, iconProp, childOptions, navigationPath }) => (
                        <OptionItem
                            id={id}
                            key={id}
                            textProp={textProp}
                            iconProp={iconProp}
                            navigationPath={navigationPath}
                            childOptions={childOptions}
                            onClick={onClick}
                            activeKey={activeKey}
                            styling={styling}
                            nestedLevel={nestedLevel + 1}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
};
