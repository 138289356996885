import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsJson, iterateInObject } from '../../helper';

export default class MapValues extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {*} iteratee
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    async update($, [source, iteratee], [setResult, setErrorFlow]) {
        const logger = getLogger('Map Values');

        // Guards
        if (!(iteratee instanceof Brick)) {
            logger.error('Provided iteratee input is not a Brick');
            return;
        }

        const { json, error } = getAsJson(source);

        if (!error) {
            const changedKeys = await iterateInObject($, json, iteratee);

            let index = 0;
            const result = _.mapValues(json, (value, key) => changedKeys[index++]);
            setResult(result);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017ff91ca9bc82c0284d', MapValues);