import { UIBrick, registerBrick } from 'olympe';
import {BarcodeFormat, MultiFormatWriter} from '@zxing/library';
import { combineLatest } from "rxjs";

export default class QRViewer extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {

        combineLatest(
            context.observe('data'),
            context.observe('Width'),
            context.observe('Height')
        ).subscribe(([data, width, height]) => {

            // Clean dom before recreating content
            if(elementDom.lastChild){
                elementDom.removeChild(elementDom.lastChild);
            }

            if(data.length > 0){
                // QR Code is the only supported format yet, maybe future versions of the lib will support barcode as well
                const writer = new MultiFormatWriter();
                const matrix = writer.encode(data, BarcodeFormat.QR_CODE, width, height, null);

                // Compute the image
                const buffer = [];
                for(let y = 0; y < height; y++) {
                    for(let x = 0; x < width; x++) {
                        const color = matrix.get(x, y) ? 0 : 255;
                        const pos = (y * width + x) * 4;    // position in buffer based on x and y
                        buffer[pos  ] = color;              // some R value [0, 255]
                        buffer[pos+1] = color;              // some G value
                        buffer[pos+2] = color;              // some B value
                        buffer[pos+3] = 255;                // set alpha channel
                    }
                }

                // create off-screen canvas element
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;

                canvas.addEventListener('click', () => {
                    context.getEvent('On Click').trigger();
                });

                const ctx = canvas.getContext('2d');
                const iData = ctx.createImageData(width, height);
                iData.data.set(buffer);
                ctx.putImageData(iData, 0, 0);
                
                elementDom.appendChild(canvas);
            }
        });
    }
}

registerBrick('017b3b02371dafb244ec', QRViewer);
