import { ActionBrick, registerBrick } from 'olympe';
import { getLogger } from '@olympeio/core';
import { cacheManager } from '../CacheManager';

export default class RemoveFromCache extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} key
     * @param {function()} forwardEvent
     */
    update(context, [key], [forwardEvent]) {
        if (typeof key !== 'string') {
            getLogger('RemoveFromCache').error(`Provided key is not of type string: ${key}`);
            return;
        }
        cacheManager().removeByKey(key);
        forwardEvent();
    }
}

registerBrick('017d76a96c7e6c88e188', RemoveFromCache);
