import { ActionBrick, registerBrick, ErrorFlow, Sync, File, DBView, PropertyPrimitive, BusinessObject, CloudObject, Transaction } from 'olympe';
import { getLogger } from "@olympeio/core";
import XLSX from 'xlsx';

export default class ReadExcelFile extends ActionBrick {

    /**
     * @override
     */
    init(context){
        this.db = DBView.get();
        this.logger = getLogger('ReadExcelFile');
    }



    /**
     * @protected
     * @param {!BrickContext} context
     * @param {File} file
     * @param {string} sheetName
     * @param {Sync} businessObject
     * @param {Map} mapping
     * @param {boolean} automaticDiscovery
     * @param {function()} forwardEvent
     * @param {function(Array<Sync>)} setObjects
     * @param {function(*)} setErrorFlow
     */
    update(context, [file, sheetName, businessObject, mapping, automaticDiscovery], [forwardEvent, setObjects, setErrorFlow]) {
        // Guards
        if(!(file instanceof File)){
            this.logger.error(`The file input is supposed to be a file`);
            return;
        }
        if(sheetName && typeof sheetName !== 'string'){
            this.logger.error(`The sheetName is supposed to be a string`);
            return;
        }
        if(!(businessObject instanceof Sync)){
            this.logger.error(`The business object is supposed to be a olympe object`);
            return;
        }
        if(mapping && !(mapping instanceof Map)){
            this.logger.error(`The mapping is supposed to be a Map object`);
            return;
        }
        if(typeof automaticDiscovery !== "boolean"){
            this.logger.error(`The automatic discovery is supposed to be a boolean`);
            return;
        }
        if(mapping === undefined && automaticDiscovery === false){
            this.logger.error(`You should either provide a mapping or activate the automatic discovery`);
            return;
        }

        if(automaticDiscovery){
            mapping = this.createMappingFromBusinessObject(businessObject);
        }


        file.getContentAsBinary(async data => {
            const workbook = XLSX.read(data, {type:"array", cellDates: true});

            const sheet_name = (sheetName && sheetName.trim() !== '' ? sheetName : workbook.SheetNames[0]);
            const worksheet = workbook.Sheets[sheet_name];

            // Wrong inputs
            if(worksheet === undefined){
                setErrorFlow(ErrorFlow.create(`The specified sheet (${sheetName}) doesn't exist. (First sheet name : "${workbook.SheetNames[0]}")`, 2));
                return;
            }

            const excelData = XLSX.utils.sheet_to_json(worksheet);
            const unusedHeaders = this.checkHeaders(excelData, Array.from(mapping.keys()), Array.from(mapping.keys()).length);
            if(unusedHeaders.length > 0) {
                setErrorFlow(ErrorFlow.create(`No data found for that header: ${unusedHeaders.toString()}`, 0));
            }
            const objects = [];
            const transaction = new Transaction(false);
            excelData.forEach((line) => {
                objects.push(this.createOlympeObject(businessObject, mapping, line, transaction));
            });
            await transaction.executeAsLarge();
            setObjects(objects.map(tag => CloudObject.get(tag)));
            forwardEvent();

        }, error => {
            setErrorFlow(ErrorFlow.create(error, 4));
        })
    }

    /**
     * Generate a mapping configuration according to the property names
     *
     * @param businessObject
     */
    createMappingFromBusinessObject(businessObject){
        const mapping = new Map();
        this.db.getRelated(businessObject, BusinessObject.propertyRel).forEach(prop => {
            mapping.set(this.db.name(prop), prop);
        });
        return mapping;
    }



    /**
     * the xlsx lib provide data as json but the empty cells don't have their property set,
     * So we read the data until all the headers are found. If no values for all lines from the given header,
     * we return that header name as it's probable that there is an issue in the header name
     *
     * @param data
     * @param headers
     * @param expectedLength
     * @return {*}
     */
    checkHeaders(data, headers, expectedLength){
        let i = 0, founds = 0;
        while(i < data.length && founds < expectedLength){
            for(let j = headers.length-1; j >= 0; j--){
                if(data[i][headers[j]] !== undefined){
                    founds++;
                    headers.splice(j, 1);
                }
            }
            i++;
        }
        return headers;
    }



    /**
     * Create the olympe object in the local DC
     *
     * @param businessObject
     * @param mapping
     * @param line
     * @param transaction
     * @return {CloudObject}
     */
    createOlympeObject(businessObject, mapping, line, transaction){

        const properties = new Map();
        for (let [key, prop] of mapping) {
            const type = this.db.getUniqueRelated(prop, PropertyPrimitive.typeRel);
            if(line[key] !== undefined && line[key] !== null){
                let value;
                switch (this.db.name(type)){
                    case 'Number': value = (typeof line[key] === 'string' ? Number(line[key].replace(/,/g, '.')) : Number(line[key])); break;
                    case 'Date/Time': value = (typeof line[key] === 'string' ? new Date(line[key]) : line[key]); break;
                    case 'String': value = line[key] ? String(line[key]): ''; break;
                    default: value = line[key];
                }
                properties.set(prop, value);
            }
        }

        return transaction.create(businessObject, properties);
    }
}

registerBrick('017c2b9ee15ae5c2af41', ReadExcelFile);
