import { ActionBrick, registerBrick, GlobalProperties } from 'olympe';
import { useMUITheme } from '@olympeio/core';
import ReactDOM from 'react-dom';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { COLORS } from './helpers/_helpers';

export default class StartLoadingPopup extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {boolean} circularProgress
     * @param {Color} backgroundColor
     * @param {*} loaderColor
     * @param {string} text
     * @param {Color} fontColor
     * @param {*} fontFamily
     * @param {number} fontSize
     * @param {function()} forwardEvent
     */
    update($, [circularProgress, backgroundColor, loaderColor, text, fontColor, fontFamily, fontSize], [forwardEvent]) {
        const isPopupPresented = document.getElementById('loading-popup') !== null;
        if (isPopupPresented) {
            throw 'Loading Popup is already displayed on screen';
        }
        if(!$.get(GlobalProperties.EDITION, true)){
            const head = document.body;
            const loadingPopup = document.createElement('loading-popup');
            loadingPopup.id = 'loading-popup';
            loadingPopup.rel = 'stylesheet';
            loadingPopup.type = 'text/css';
            loadingPopup.className = 'loading-popup';
            head.appendChild(loadingPopup);

            ReactDOM.render(
                <StartLoadingPopup.Component
                    circularProgress={circularProgress}
                    backgroundColor={backgroundColor}
                    text={text}
                    fontColor={fontColor}
                    fontFamily={fontFamily}
                    loaderColor={loaderColor}
                    fontSize={fontSize}
                    context={$}
                />,
                document.getElementById('loading-popup')
            );
        }
        forwardEvent();
    }
}

StartLoadingPopup.Component = (props) => {
    const originalTheme = useMUITheme(props.context);

    const mainColor =
        originalTheme.palette?.[props.loaderColor] === undefined
            ? COLORS[props.loaderColor] || originalTheme.palette['primary'].main
            : originalTheme.palette[props.loaderColor].main;

    const theme = createTheme({
        ...originalTheme,
        palette: {
            primary: {
                main: mainColor,
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: props.backgroundColor?.toHexString(),
                    position: 'relative',
            }}
        >
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        top: '43%',
                        position: 'absolute',
                        margin: 'auto',
                }}
            >
                    <div
                        className={'loading-popup-text'}
                        style={{
                            marginBottom: '20px',
                            fontFamily: props.fontFamily ?? originalTheme.typography.fontFamily,
                            color: props.fontColor?.toHexString(),
                            fontSize: props.fontSize,
                    }}
                >
                        {props?.text}
                    </div>
                    {props.circularProgress ? (
                        <CircularProgress
                            style={{
                                width: '4%',
                                height: 'auto',
                            }}
                        />
                    ) : (
                        <LinearProgress
                            className={'loading-popup-linear'}
                            style={{
                                width: '50%',
                                margin: 'auto',
                            }}
                        />
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
};

registerBrick('017ed3425a7322c39242', StartLoadingPopup);
