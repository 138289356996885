import { Brick, registerBrick } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';

export default class Clamp extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {number} number
     * @param {number} lower
     * @param {number} upper
     * @param {function(number)} setResult
     */
    update($, [number, lower, upper], [setResult]) {
        const logger = getLogger('Clamp');

        // Guards
        if (typeof number !== 'number') {
            logger.error('Provided number input is not a number');
            return;
        }
        if (typeof lower !== 'number') {
            logger.error('Provided lower input is not a number');
            return;
        }
        if (typeof upper !== 'number') {
            logger.error('Provided upper input is not a number');
            return;
        }
        setResult(_.clamp(number, lower, upper));
    }
}

registerBrick('017ff8e6dd429d608836', Clamp);