import { Brick, registerBrick, ListDef, Sync, Context, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';
import { checkList } from './helper';

export default class JoinListToString extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {Array<Sync> | ListDef | QueryResult} list
     * @param {Sync} property
     * @param {string} separator
     * @param {function(string)} setJoinedString
     */
    update(context, [list, property, separator], [setJoinedString]) {
        const logger = getLogger('Join List');
        const isCorrectList = checkList(list);
        if (!isCorrectList) {
            logger.error('Input provided is not a ListDef, an Array or a QueryResult');
            return;
        }

        const PROPERTY_TAG = 'ff021000000000000005';
        if (property === null || (property && property.getModelTag() !== PROPERTY_TAG)) {
            logger.error('Property provided is not valid');
            return;
        }

        if(typeof separator !== 'string') {
            logger.error('Separator is not a valid string');
            return;
        }

        let joinedString = '';
        if (list instanceof ListDef) {
            list.onReady(() => {
                list.forEachCurrentValue((objectIn, id) => {
                    joinedString += objectIn.get(property);
                    const index = list.getCurrentRank(id);
                    if (index < list.getCurrentSize()-1) {
                        joinedString += separator;
                    }
                });
            });
        } else {
            const primaryArray = (list instanceof QueryResult) ? list.toArray() : list;
            primaryArray.forEach((objectIn, index) => {
                joinedString += objectIn.get(property);
                if (index < primaryArray.length-1) {
                    joinedString += separator;
                }
            });
        }
        setJoinedString(joinedString);
    }
}

registerBrick('017d2471df923538d2f9', JoinListToString);
