import {UIBrick, registerBrick, UIContext, DBView, StringPrimitive, Sync} from 'olympe';
import {combineProps, createEvents, mapListObservableToArray} from './_helpers';
import ReactDOM from 'react-dom';
import React, {useLayoutEffect} from 'react';
import * as PropTypes from 'prop-types';
import {getLogger} from '@olympeio/core';
import MUIChip from './MUIChip.jsx';

export default class MUIChipArray extends UIBrick {
    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!UIContext} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const events = createEvents([
            {
                name: 'onDelete',
                event: context.trigger.bind(context, 'On Delete'),
                before: (chipToDelete) => {
                    let value;
                    if(chipToDelete.tag !== undefined) {
                        value = Sync.getInstance(chipToDelete.tag);
                    } else {
                        value = chipToDelete.value;
                    }
                    context.getProperty('Deleted chip').set(value);
                }
            },
            {
                name: 'onClick',
                event: context.trigger.bind(context, 'On Click'),
                before: (chip) => {
                    let value;
                    if(chip.tag !== undefined) {
                        value = Sync.getInstance(chip.tag);
                    } else {
                        value = chip.value;
                    }
                    context.getProperty('Clicked chip').set(value);
                }
            },
        ]);

        combineProps([
            {name: 'labels', prop: context.getProperty('Labels'), defaultValue: [], observableMapper: mapListObservableToArray},
            {name: 'labelProperty', prop: context.getProperty('Label Property'), defaultValue: null},
            {name: 'icon', prop: context.getProperty('Icon'), defaultValue: ''},
            {name: 'clickable', prop: context.getProperty('Clickable'), defaultValue: false},
            {name: 'disabled', prop: context.getProperty('Disabled'), defaultValue: false},
            {name: 'deleteIcon', prop: context.getProperty('Delete Icon'), defaultValue: ''},
            {name: 'deletable', prop: context.getProperty('Deletable'), defaultValue: false},
            {name: 'variant', prop: context.getProperty('MUI Variant'), defaultValue: 'default'},
            {name: 'size', prop: context.getProperty('MUI Size'), defaultValue: 'medium'},
            {name: 'muiColor', prop: context.getProperty('MUI Color'), defaultValue: 'default'},
            {name: 'style', prop: context.getProperty('Style [JSON]'), defaultValue: '{}'},
            {name: 'width', prop: context.getProperty('Width')},
            {name: 'height', prop: context.getProperty('Height')}
        ]).subscribe((props) => {
            // transform list to plain objects containing the string and tag if available
            const dbView = DBView.get();
            const labels = [];
            props.labels.forEach((entry) => {
                if(typeof entry === 'string') {
                    labels.push({value: entry});
                }
                else if(props.labelProperty !== null) {
                    labels.push({
                        tag: entry.getTag(),
                        value: dbView.getProperty(entry, props.labelProperty, false)
                    });
                }
                else if(dbView.instanceOf(entry, StringPrimitive)) {
                    labels.push({
                        tag: entry.getTag(),
                        value: dbView.getProperty(entry, StringPrimitive.valueProp, false)
                    });
                } else {
                    getLogger('MUIChipArray').error('Label property is not defined, and provided list entry is not a string or String Object '+entry);
                }
            });

            ReactDOM.render((
                <MUIChipArray.Component
                    {...props}
                    {...events}
                    labels={labels}
                    uniqueId={this.getTag()}
                    updateHeight={(newHeight) => {
                        context.getProperty('Used Height').set(newHeight);
                    }} />
                ),
                elementDom
            );
        });
    }
}

registerBrick('017c0d59b28c54f9b9d1', MUIChipArray);


/**
 *
 * @param {Object} props
 * @param {Array} props.labels
 * @param {string} props.styleJSON
 * @param {function(*)} props.onClick
 * @param {function(*)} props.onDelete
 * @param {string} props.uniqueId
 * @param {function(Number)} props.updateHeight
 * @return {JSX.Element}
 * @constructor
 */
MUIChipArray.Component = (props) => {
    let labels = props.labels;

    // retrieve height used by the component
    // this is fired after the component has finished rendering,
    // but it does not handle dynamic resize of the component (we observe Width/Height property for that)
    useLayoutEffect(() => {
        const chipArrayElement = document.getElementById("chip-array-"+props.uniqueId);
        if(chipArrayElement !== null) {
            props.updateHeight(chipArrayElement.clientHeight);
        }
    });

    return (
            <div
                id={"chip-array-"+props.uniqueId} key={"chip-array"} style={{display: 'flex', flexWrap: 'wrap'}}
            >
                {labels.map((data, index) => {
                    return (
                        <div key={'chip '+index}>
                            <MUIChip.Component
                                {...props}
                                label={data.value}
                                margin={0.5}
                                onClick={ props.onClick.bind(null, data) }
                                onDelete={ props.onDelete.bind(null, data) }
                            />
                        </div>
                    );
                })}
            </div>
    );
};

MUIChipArray.Component.propTypes = {
    labels: PropTypes.array,
    styleJSON: PropTypes.string,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    uniqueId: PropTypes.string,
    updateHeight: PropTypes.func
};
