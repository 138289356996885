import { updateNavigationState, parseCurrentHash } from '@olympeio/core';
import React from 'react';
import List from '@mui/material/List';
import { OptionItem } from './OptionItem.jsx';

const makeTreeFlat = (array, result) => {
    array.forEach(elem => {
        result.push(elem);
        if (elem.childOptions) {
            makeTreeFlat(elem.childOptions, result);
        }
    });
}

const getSelectedIdByUrl = (options) => {
    const list = JSON.parse(localStorage.getItem('listOfMenus'));
    const expectedOption = options.find(option => option.navigationPath === parseCurrentHash());

    if (expectedOption && list) {
        const id = `${expectedOption.textProp}_${expectedOption.navigationPath}`;
        const changedListOfMenus = list.map((menuObj) => {
            menuObj.selected = menuObj.id === id;
            return menuObj;
        });
        localStorage.setItem('listOfMenus', JSON.stringify(changedListOfMenus));
        return id;
    }
    return `${options[0]?.textProp}_${options[0]?.navigationPath}`;
};

export const OptionsList = ({ options, styling }) => {
    const getSelected = () => {
        const flatOptions = [];
        makeTreeFlat(options, flatOptions);

        const selectedId = getSelectedIdByUrl(flatOptions);
        const selectedOption = flatOptions.find(option => selectedId === `${option.textProp}_${option.navigationPath}`);
        return selectedOption?.id;
    };

    const [activeKey, setActiveKey] = React.useState(getSelected());

    const onClick = (id, navigationPath) => {
        if (navigationPath) {
            updateNavigationState(navigationPath, true);
            setActiveKey(id);
        }
    };

    return (
        <List
            component="nav"
            disablePadding
        >
            {options &&
                options.map(({ id, textProp, iconProp, navigationPath, childOptions }) => (
                    <OptionItem
                        id={id}
                        key={id}
                        textProp={textProp}
                        iconProp={iconProp}
                        navigationPath={navigationPath}
                        childOptions={childOptions}
                        onClick={onClick}
                        activeKey={activeKey}
                        styling={styling}
                        nestedLevel={0}
                    />
                ))}
        </List>
    );
};
