import { ActionBrick, registerBrick, ListDef, Brick, BrickContext, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';

export default class SortListWithLambda extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {ListDef} list
     * @param {*} transformator
     * @param {boolean} ascending
     * @param {function()} forwardEvent
     * @param {function(ListDef)} setSortedList
     */
    async update($, [list, transformator, ascending], [forwardEvent, setSortedList]) {
        const logger = getLogger('Sort List With Lambda');

        if (!list || !(transformator instanceof Brick)) {
            logger.warn('Nothing to do: list or transformator is null or undefined');
            return;
        }

        const transformToPrimitive = async instance => {
            const [objectInput] = transformator.getInputs();
            const [objectOutput] = transformator.getOutputs();

            const transformator$ = $.runner(transformator).set(objectInput, instance);
            const output = await transformator$.waitFor(objectOutput);
            transformator$.destroy();

            if (typeof output === 'object' || output === undefined) {
                logger.error('Transformator output is not a primitive');
                return;
            }
            return output;
        };

        let primaryArray = Array.isArray(list) ? list : [];
        if (list instanceof ListDef) {
            list.forEachCurrentValue(item => primaryArray.push(item));
        } else if (list instanceof QueryResult) {
            primaryArray = list.toArray();
        } else if (!Array.isArray(list)) {
            logger.error('List is not a ListDef, an Array or a QueryResult');
            return;
        }

        const transformedList = await Promise.all(primaryArray.map(
            async (instance, index) => {
                const primitive = await transformToPrimitive(instance);
                return { index, primitive };
            }
        ));

        const sortedTransformedList = transformedList.sort((left, right) => {
            if (ascending) {
                return left.primitive === right.primitive ? 0 : (left.primitive < right.primitive ? -1 : 1);
            } else {
                return left.primitive === right.primitive ? 0 : (left.primitive > right.primitive ? -1 : 1);
            }
        });

        const sortedList = [];
        sortedTransformedList.forEach(elem => {
            sortedList.push(primaryArray[elem.index]);
        });

        setSortedList(sortedList);
        forwardEvent();
    }
}

registerBrick('017eb19359c3f7c64fb5', SortListWithLambda);
