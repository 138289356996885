import { Sync, registerSync } from 'olympe';

/**
 * @extends {Sync}
 */
export class GPSCoordinates extends Sync {

    /**
     * @return {number}
     */
    getLatitude() {
        return this.db.getProperty(this, GPSCoordinates.latitudeProp);
    }
    /**
     * @return {number}
     */
    getLongitude() {
        return this.db.getProperty(this, GPSCoordinates.longitudeProp);
    }

}

// Hardcoded tags
GPSCoordinates.entry = registerSync('01778b68003d6bd7d428', GPSCoordinates);
GPSCoordinates.latitudeProp = GPSCoordinates.entry.addProperty('01778b682176cac354fd');
GPSCoordinates.longitudeProp = GPSCoordinates.entry.addProperty('01778b6827316c958fcb');

