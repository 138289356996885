import { registerBrick } from 'olympe';
import VueBrick from './helper/VueBrick';
import { combineLatest, of, timer } from 'rxjs';
import { switchMap, startWith, debounce } from 'rxjs/operators';
import CarouselItem from './CarouselItem.vue';

class Carousel extends VueBrick {
    /**
     * @override
     */
    setupExecution($) {
        // All props
        const properties = [
            'Initial Slide',
            'Number Of Displayed Items',
            'Continuous',
            'Autoplay',
            'Show Controls',
            'Show Pagination',
            'CSS Property',
            'Height',
            'Width',
            'Default Color',
            'Color',
            'Border Color',
            'Border Radius',
            'Border Width',
        ];

        const dataObservable = $.observe('Data').pipe(startWith([]));

        // Observe the list size to retrigger an update if necessary
        const dataSizeObservable = dataObservable.pipe(switchMap(data => {
            if (!data) {
                return of(0);
            }
            return Array.isArray(data) ? of(data.length) : data.observeSize();
        }));

        const hiddenObservable =  $.observe('Hidden', false);
        const propertiesObservable = combineLatest(properties.map(p => $.observe(p).pipe(debounce((i) => timer(100)))));

        return combineLatest([dataObservable, hiddenObservable, propertiesObservable, dataSizeObservable]);
    }

    /**
     * @override
     * @protected
     * @return {Element}
     */
    getVueComponent() {
        return CarouselItem;
    };
}

registerBrick('017f6909a3af79857855', Carousel);
