import { UIBrick, registerBrick } from 'olympe';

export class FreeCSSDiv extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        context.observe('CSS').subscribe((css) => {
            const style = 'width: 100%; height: 100%; ' + css;
            elementDom.setAttribute('style', style);
        });
    }
}

registerBrick('017984ea16558e9018da', FreeCSSDiv);
