import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsArray } from '../../helper';

export default class Join extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {string} separator
     * @param {function(string)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [source, separator], [setResult, setErrorFlow]) {
        const logger = getLogger('Join');

        // Guards
        if (typeof separator !== 'string') {
            logger.error('Provided string input is not a string');
            return;
        }

        const { array, error } = getAsArray(source);

        if (!error) {
            const result = _.join(array, separator);
            setResult(result);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017fe3e07522392c7fd7', Join);