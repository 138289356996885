import { ActionBrick, registerBrick } from 'olympe';
import {ErrorFlow} from 'olympe';
import {binaryToDataUrl} from '@olympeio/core';
import fetch from 'node-fetch';
import { getLogger } from "@olympeio/core";


export default class HTTPPostDataURL extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} url
     * @param {string} body
     * @param {string} headers
     * @param {function()} forwardEvent
     * @param {function(*)} setErrorFlow
     * @param {function(string)} setDataURL
     */
    update(context, [url, body, headers], [forwardEvent, setErrorFlow, setDataURL]) {
        let parsedHeaders, myFetch;
        if(process && process.env) {
            myFetch = require('node-fetch');
            parsedHeaders = new myFetch.Headers();
            myFetch = myFetch.default;
        } else {
            myFetch = fetch;
            parsedHeaders = new Headers();
        }

        if (headers) {
            for (const [key, value] of Object.entries(JSON.parse(headers))) {
                parsedHeaders.append(key, value);
            }
        }
        const init = {
            method: 'POST',
            headers: parsedHeaders,
            mode: 'cors',
            cache: 'default'
        };
        init.body = body;

        let mimeType;
        myFetch(url, init)
            .then(response => {
                if (response.ok) {
                    for (const [key, value] of response.headers.entries()) {
                        if(key.toLowerCase() === 'content-type') {
                            mimeType = value; // how to pass this as promise?
                        }
                    }
                } else {
                    getLogger('HttpPostDataUrl').error('Network error: '+response.statusText, response.status);
                    setErrorFlow(ErrorFlow.create('Network error: '+response.statusText, response.status));
                }
                return response.arrayBuffer();
            })
            .then(data => {
                if(data.byteLength > 0) {
                    setDataURL(binaryToDataUrl(data, mimeType));
                    forwardEvent();
                } else {
                    getLogger('HttpPostDataUrl').error('An error occured:'+data);
                    setErrorFlow(ErrorFlow.create('An error occured: Empty data returned by WS', 2));
                }
            })
            .catch((error) => {
                getLogger('HttpPostDataUrl').error('An unexpected error occured: '+JSON.stringify(error));
                setErrorFlow(ErrorFlow.create('An unexpected error occured', 1));
            });
    }
}

registerBrick('017bbf62fb54ed9906bb', HTTPPostDataURL);
