import { VisualBrick, registerBrick } from 'olympe';
import {combineLatest} from 'rxjs';

export default class SVGContainer extends VisualBrick {
    
    setupExecution($) {
        return combineLatest([$.observe('SVG Canvas')]);
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array<*>} properties
     * @return {Element}
     */
    render($, [svg]) {

        const style = {
            'box-sizing': 'border-box',
            'border-style': 'solid',
        };
        let additionalCss = '';
        const subscriptions = [];
        subscriptions.push(combineLatest($.observe('Width'), $.observe('Height')).subscribe(([w, h]) => {
            svg.setAttribute("width", w);
            svg.setAttribute("height", h);
        }));

        subscriptions.push($.observe('Default Color').subscribe((color) => {
            style['background-color'] = color;
            svg.setAttribute("style", this.toCss(style, additionalCss));
        }));

        subscriptions.push($.observe('Border Color').subscribe((color) => {
            style['border-color'] = color;
            svg.setAttribute("style", this.toCss(style, additionalCss));
        }));

        subscriptions.push($.observe('Border Radius').subscribe((radius) => {
            style['border-radius'] = radius+'px';
            svg.setAttribute("style", this.toCss(style, additionalCss));
        }));

        subscriptions.push($.observe('Border Width').subscribe((width) => {
            style['border-width'] = width+'px';
            svg.setAttribute("style", this.toCss(style, additionalCss));
        }));

        subscriptions.push($.observe('CSS Property').subscribe((newCss) => {
            additionalCss = newCss;
            svg.setAttribute("style", this.toCss(style, additionalCss));
        }));

        $.onDestroy(() => {
            subscriptions.forEach(sub => sub.unsubscribe());
        });

        return svg;
    }

    toCss(styleObject, additionalCss){
        let styleString = '';
        for(const property in styleObject) {
            styleString += `${property}: ${styleObject[property]};`
        }

        return styleString + additionalCss;
    }
}

registerBrick('01860dc84242739326d7', SVGContainer);
