import { registerBrick } from 'olympe';
import { getLogger, ReactBrick, useProperty, jsonToSxProps } from '@olympeio/core';
import React from 'react';
import Box from '@mui/material/Box';
import { combineLatest, startWith } from 'rxjs';
import Gantt from 'frappe-gantt';
import { format } from 'date-fns';
import { bind } from 'size-sensor';

export default class GanttDiagram extends ReactBrick {
    /**
     * @override
     */
    setupExecution($) {
        return combineLatest([
            $.observe('Hidden', false),
            $.observe('Height', false),
            $.observe('Date Format', false).pipe(startWith('yyyy-MM-dd')),
            $.observe('Arrow Curve', false).pipe(startWith(5)),
            $.observe('Bar Padding', false).pipe(startWith(18)),
            $.observe('Bar Corner Radius', false).pipe(startWith(5)),
            $.observe('Language', false).pipe(startWith('en')),
            $.observe('Calculate Height', false),
            $.observe('Header Height', false).pipe(startWith(50)),
            $.observe('Bar Height', false).pipe(startWith(50)),
        ]);
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const [
                hidden, height, dateFormat,
                arrowCurve, barPadding, cornerRadius, language,
                calculateHeight, manualHeaderHeight, manualBarHeight
            ] = props.values;

            const data = $.get('Data');
            const isDataDefined = data && Array.isArray(data) && data.filter(item => item && typeof item === 'object').length !== 0;

            if (!isDataDefined) {
                getLogger('Gantt').warn('No tasks defined');
            }

            // to prevent raising error from the library if no tasks
            const tasks = isDataDefined ? data : [{name: 'mock-task', custom_class: 'mock-task'}];
            const headerHeight = 50;
            const singleRowHeight = (height - headerHeight - (barPadding * tasks.length + barPadding / 2)) / tasks.length;
            const barHeight = singleRowHeight < 10.5 ? 10.5 : singleRowHeight;

            const options = {
                bar_height: calculateHeight ? barHeight : manualBarHeight,
                header_height: calculateHeight ? headerHeight : manualHeaderHeight,
                date_format: dateFormat,
                arrow_curve: arrowCurve,
                padding: barPadding,
                bar_corner_radius: cornerRadius,
                language: language,
            };

            return !hidden && (height > 0) && (
                <GanttDiagram.Component
                    $={$}
                    tasks={tasks}
                    options={options}
                />
            );
        }
    }
}

GanttDiagram.Component = ({ $, tasks, options }) => {
    const rootRef = React.useRef();
    let ganttBar = null;

    const [gantt, setGantt] = React.useState(null);

    const viewMode = useProperty($, 'View Mode');
    const onDateChange = useProperty($, 'On Date Change');
    const onProgressChange = useProperty($, 'On Progress Change');
    const onTaskClick = useProperty($, 'On Task Click');
    const customPopup = useProperty($, 'Custom HTML Popup');
    const refreshEvent = useProperty($, 'Refresh');

    React.useEffect(() => {
        if (gantt && refreshEvent) {
            gantt.refresh($.get('Data'));
        }
    }, [refreshEvent]);

    React.useEffect(() => {
        if (gantt && viewMode) {
            gantt.change_view_mode(viewMode);
        }
    }, [viewMode]);

    React.useEffect(() => {
        if (gantt && customPopup) {
            gantt.options.custom_popup_html = (task) => {
                const transformedTask = transform(task);
                const [dataInput] = customPopup.getInputs();
                const [htmlOutput] = customPopup.getOutputs();

                const lambda$ = $.runner(customPopup)
                    .set(dataInput, transformedTask);
                return lambda$.get(htmlOutput);
            }
        }
    }, [customPopup]);

    const transform = (currentTask) => {
        const task = Object.assign({}, currentTask);
        const dateFormat = gantt.options.date_format === '' ? 'yyyy-MM-dd' : gantt.options.date_format;

        const startIsFormatted = format(Date.parse(task.start), dateFormat) === task.start;
        const endIsFormatted = format(Date.parse(task.end), dateFormat) === task.end;

        if (!startIsFormatted || !endIsFormatted) {
            getLogger('Gantt').warn('The format of dates is not related to the Date Format property');
        }

        task.start = task._start ? format(Date.parse(task._start), dateFormat) : task.start;
        task.end = task._end ? format(Date.parse(task._end), dateFormat) : task.end;

        const appendedProps = ['_start', '_end', '_index'];
        for (const prop of appendedProps) {
            delete task[prop];
        }

        return task;
    };

    React.useEffect(() => {
        if (gantt && onTaskClick) {
            gantt.options.on_click = (task) => {
                const transformedTask = transform(task);

                const [startInput, dataInput] = onTaskClick.getInputs();
                $.runner(onTaskClick)
                    .set(dataInput, transformedTask)
                    .trigger(startInput);
            }
        }
    }, [onTaskClick]);

    React.useEffect(() => {
        if (gantt && onDateChange) {
            gantt.options.on_date_change = (task, start, end) => {
                const transformedTasks = gantt.tasks.map(obj => transform(obj));
                $.set('Data', transformedTasks);
                const updatedTask = transformedTasks.find(transformedTask => transformedTask.id === task.id);

                const [controlFlowInput, taskInput, startInput, endInput] = onDateChange.getInputs();
                $.runner(onDateChange)
                    .set(taskInput, updatedTask)
                    .set(startInput, start)
                    .set(endInput, end)
                    .trigger(controlFlowInput);
            }
        }
    }, [onDateChange]);

    React.useEffect(() => {
        if (gantt && onProgressChange) {
            gantt.options.on_progress_change = (task, progress) => {
                const transformedTasks = gantt.tasks.map(obj => transform(obj));
                $.set('Data', transformedTasks);
                const updatedTask = transformedTasks.find(transformedTask => transformedTask.id === task.id);

                const [startInput, taskInput, progressInput] = onProgressChange.getInputs();
                $.runner(onProgressChange)
                    .set(taskInput, updatedTask)
                    .set(progressInput, progress)
                    .trigger(startInput);
            }
        }
    }, [onProgressChange]);

    React.useEffect(() => {
        // set bars to the beginning of the box after resize
        bind(rootRef.current, () => {
            if (ganttBar) {
                ganttBar.refresh(tasks);
            }
        });

        if (rootRef.current && tasks && tasks.length !== 0) {
            ganttBar = new Gantt(rootRef.current, tasks, options);
            setGantt(ganttBar);
        }
    }, []);

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                boxSizing: 'border-box',
                overflowY: 'scroll',
                ...jsonToSxProps(useProperty($, 'MUI sx [json]')),

                'svg .bar text': {
                    fontSize: `${useProperty($, 'Bar Font Size')}px`,
                    fontFamily: useProperty($, 'Bar Font Family'),
                },
                '.gantt .bar-progress': {
                    fill: useProperty($, 'Progress Bar Color')?.toHexString(),
                },
                '.gantt .bar': {
                    fill: useProperty($, 'Bar Color')?.toHexString(),
                },
                '.mock-task' : {
                    display: 'none'
                }
            }}
        >
            <svg
                ref={rootRef}
            />
        </Box>
    );
};

registerBrick('01801e08731f9e13e652', GanttDiagram);