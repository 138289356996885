import { Quill } from 'react-quill';

const BlockEmbed = Quill.import('blots/block/embed');

/**
 * Custom Quill Blot for rendering table blocks
 */
export class TableBlockEmbed extends BlockEmbed {
    /**
     * @type {string}
     */
    static blotName = 'TableBlockEmbed';

    /**
     * @type {string}
     */
    static tagName = 'table';

    /**
     * @override
     */
    static create(value) {
        const node = super.create();
        node.id = `generatedTableId-${Date.now()}`;
        node.innerHTML = value.split('\n').map((line) => line.trim()).join('');
        node.border = '1';
        node.style.borderCollapse = 'collapse';
        return node;
    }

    /**
     * @override
     */
    static value(node) {
        return node.innerHTML;
    }
}

Quill.register(TableBlockEmbed, true);
