import { TextField } from '@mui/material';
import { useProperty } from '@olympeio/core';
import React from 'react';
import { createEvents } from '../../helpers/_helpers';

export const SearchField = ({ $, isShown, isMobile }) => {
    const PLACEHOLDERS = {
        enUS: 'search',
        frFR: 'rechercher',
        itIT: 'ricerare',
        deDE: 'suchen',
    };
    const color = useProperty($, 'Text Color Override');
    const language = useProperty($, 'Language');
    const events = createEvents([{
        name: 'onFilterTextChange',
        event: () => {
            const onFilter = $.get('On Filter');
            if (onFilter) {
                const [startInput, currentFilterText] = onFilter.getInputs();
                $.runner(onFilter)
                    .set(currentFilterText, $.get('Filter Text'))
                    .trigger(startInput);
            }
        },
        before: (event) => {
            $.set('Filter Text', event.target.value);
            $.set('Page', 0);
        },
    }]);
    return (
        <TextField
            id="outlined-basic" variant="outlined"
            placeholder={PLACEHOLDERS[language] + '...'}
            size="small"
            fullWidth={isMobile}
            style={{
                height: '40px',
                width: isMobile ? '100%' : '200px',
                position: isMobile ? 'relative' : 'absolute',
                right: isMobile ? 0 : '3px',
                top: isMobile ? 0 : '3px',
                display: isShown ? 'block' : 'none',
                backgroundColor: '#fff',
                borderRadius: '5px',
                zIndex: 1
            }}
            sx={{
                '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                    borderColor: color?.toHexString(),
                }
            }}
            onChange={events.onFilterTextChange}
            type="search"
        />
    );
};
