import { ActionBrick, registerBrick, ListDef, ErrorFlow, File as OFile, Transaction, CloudObject, BrickContext } from 'olympe';
import {fromBase64, getLogger} from '@olympeio/core';
import XLSX from 'xlsx';
export default class CreateExcelFile extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} $
     * @param {(ListDef | Array)} data
     * @param {Map} mapping
     * @param {string} format
     * @param {string} fileName
     * @param {function()} forwardEvent
     * @param {function(File)} setFile
     * @param {function(*)} setErrorFlow
     */
    update($, [data, mapping, format, fileName], [forwardEvent, setFile, setErrorFlow]) {
        const logger = getLogger('CreateExcelFile');
        // Guards
        if(!(data instanceof ListDef || data instanceof Array)){
            logger.error(`The data input is supposed to be a list`);
            return;
        }
        if(!(mapping instanceof Map)){
            logger.error(`The mapping is supposed to be a Map object`);
            return;
        }
        if(typeof format !== 'string'){
            console.log(format);
            logger.error(`The format is supposed to be a string`);
            return;
        }

        // Getting the data
        const excelData = [Array.from(mapping.keys())];
        this.serializeDataAsArray(data, mapping, excelData);

        // Excel manipulation
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(excelData), 'sheet1');

        // Creating the olympe file
        const transaction = new Transaction(false);
        const fileContent = XLSX.write(workbook,{ bookType: format, type: 'base64' });
        const fileTag = transaction.create(OFile);
        OFile.setContent(transaction, fileTag, fileName, fromBase64(fileContent));
        transaction.execute().then(() => {
            setFile(CloudObject.get(fileTag));
            forwardEvent();
        }).catch(message => {
            setErrorFlow(ErrorFlow.create(message, 2));
        });
    }



    /**
     * @private
     * @param {(ListDef | Array)} inputData
     * @param { Map } mapping
     * @param { Array } outputArray
     */
    serializeDataAsArray(inputData, mapping, outputArray){
        inputData.forEach(object => {
            const dataLine = [];
            for (const value of mapping.values()){
                dataLine.push(object.get(value));
            }
            outputArray.push(dataLine);
        });

    }
}


registerBrick('017c2cf54dadb750cba9', CreateExcelFile);
