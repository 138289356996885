import { registerBrick, QueryResult, ListDef, GlobalProperties } from "olympe";
import { ReactBrick, useProperty, cssToSxProps, ifNotTransparent, ifNotNull, useMUITheme, jsonToSxProps } from "@olympeio/core";

import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Icon from "@mui/material/Icon";

import { combineLatestWith, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";

const AccordionSummaryComp = styled(AccordionSummary)({
    margin: 0,
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(0deg)",
    },
});

let actualHeightTimeoutKey = null;
export default class AccordionComp extends ReactBrick {
    /**
     * @override
     */
    setupExecution($) {
        // List of properties to observe normally
        const properties = ["Hidden", "Body Renderer", "Header Height"];

        // Observe the list size to re-trigger an update if necessary
        const observeList = $.observe("Object List", false);
        const observeListAsArray = observeList.pipe(
            switchMap((list) => {
                let sizeObservable;
                if (Array.isArray(list)) {
                    sizeObservable = of(list.length);
                } else if (list instanceof QueryResult) {
                    sizeObservable = of(list.size());
                } else if (list instanceof ListDef) {
                    sizeObservable = list.observeSize();
                } else {
                    sizeObservable = of(0);
                }
                return sizeObservable.pipe(
                    combineLatestWith($.observe("Reverse", false)),
                    map(([size]) => {
                        const reverse = false;
                        let elements = [];
                        if (size > 0) {
                            // Array case

                            if (Array.isArray(list)) {
                                elements = !reverse ? list : list.reverse();
                            }

                            // QueryResult case
                            else if (list instanceof QueryResult) {
                                elements = !reverse ? list.toArray() : list.toArray().reverse();
                            }

                            // ListDef case
                            else {
                                list.forEachCurrentValue((value, key) => {
                                    elements.push({
                                        value: value,
                                        rank: list.getCurrentRank(key),
                                    });
                                });
                                if (!reverse) {
                                    elements = elements.sort((a, b) => a.rank - b.rank);
                                } else {
                                    elements = elements.sort((a, b) => b.rank - a.rank);
                                }
                                elements = elements.map((e) => e.value);
                            }
                        }
                        return elements;
                    })
                );
            })
        );
        // Final observable
        return $.observe("Header Renderer", false).pipe(combineLatestWith(observeListAsArray), combineLatestWith(properties.map((p) => $.observe(p, false))));
    }

    /**
     * @override
     */
    static getReactComponent($) {
        const onItemCloseProps = $.get("On Item Close");
        const onItemClose = onItemCloseProps ? [$.runner(onItemCloseProps), ...onItemCloseProps.getInputs()] : null;
        const onItemExpandProps = $.get("On Item Expand");
        const onItemExpand = onItemExpandProps ? [$.runner(onItemExpandProps), ...onItemExpandProps.getInputs()] : null;
        return (props) => {
            return <Accordion.Component $={$} values={props.values} onItemExpand={onItemExpand} onItemClose={onItemClose} />;
        };
    }
}

Accordion.Component = ({ $, values, onItemExpand, onItemClose }) => {
    const [[renderer, elements], hidden, bodyRenderer, headerHeight] = values;
    const [selectedRankAccordion, setSelectedRankAccordion] = useState(null);
    const boxRef = useRef();

    const borderRadius = useProperty($, "Border Radius");
    const borderColor = useProperty($, "Border Color");
    const borderWidth = useProperty($, "Border Width") || 0;
    const defaultColor = useProperty($, "Default Color");
    const cssProperty = useProperty($, "Css Property");
    const expandIcon = useProperty($, "Expand Icon");
    const collapseIcon = useProperty($, "Collapse Icon");
    const actualHeight = useProperty($, "Actual Height");
    const muiSxJson = useProperty($, 'MUI sx [json]');
    const brickHeight = useProperty($, "Height");
    const brickWidth = useProperty($, "Width");
    const bodyWidth = brickWidth - borderWidth * 2;

    useEffect(() => {
        $.set("Actual Height", elements.length * headerHeight);
    }, []);

    useEffect(() => {
        if (boxRef && boxRef.current) {
            if (brickHeight < actualHeight) {
                boxRef.current.parentElement.style.overflow = "auto";
            }
        }
    }, [brickHeight, actualHeight]);

    const onAccordionClick = (type = "expand", item, rank) => {
        if (type === "expand") {
            $.set("Selected Item", item);
            setSelectedRankAccordion(rank);
            if (actualHeightTimeoutKey) {
                clearTimeout(actualHeightTimeoutKey);
            }
            if (onItemExpand) {
                const [onClick$, ctrlflowInput, selectedItem, currentRank] = onItemExpand;
                onClick$.set(selectedItem, item);
                onClick$.set(currentRank, rank);
                onClick$.trigger(ctrlflowInput);
            }
        } else {
            // collapse
            $.set("Selected Item", null);

            setSelectedRankAccordion(null);

            actualHeightTimeoutKey = setTimeout(() => {
                $.set("Actual Height", elements.length * headerHeight);
            }, 375); // 375 is time to wait accordion transition end
            if (onItemClose) {
                const [onClick$, ctrlflowInput, dataInput, currentRank] = onItemClose;
                onClick$.set(dataInput, item);
                onClick$.set(currentRank, rank);
                onClick$.trigger(ctrlflowInput);
            }
        }
    };

    if (hidden) {
        return null;
    }

    const theme = useMUITheme($);

    return (
        <ThemeProvider theme={theme}>
            <Box
                ref={boxRef}
                sx={{
                    width: "100%",
                    height: "auto",
                    overflow: "hidden",
                }}
            >
                {(elements && renderer) || !$.get(GlobalProperties.EDITION, true) ? (
                    // Only render if there is a list and a renderer, OR if we are not in draw
                    elements.map((item, rank) => {
                        return (
                            <Accordion
                                expanded={selectedRankAccordion === rank}
                                key={item}
                                disableGutters
                                sx={{
                                    "::before": {
                                        opacity: "1 !important",
                                    },
                                    padding: 0,
                                    // Border and background
                                    ...ifNotTransparent("borderColor", borderColor),
                                    ...ifNotNull("borderRadius", `${borderRadius}px`, borderRadius),
                                    ...ifNotNull("borderWidth", `${borderWidth}px`, borderWidth),
                                    ...ifNotTransparent("borderStyle", "solid", borderColor),
                                    ...ifNotTransparent("backgroundColor", defaultColor),
                                    ...ifNotNull("boxSizing", "border-box", borderWidth),

                                    // Additional
                                    ...cssToSxProps(cssProperty),
                                    ...jsonToSxProps(muiSxJson),
                                }}
                                onChange={() => {
                                    if (selectedRankAccordion === rank) {
                                        onAccordionClick("collapse", item, rank);
                                    } else {
                                        onAccordionClick("expand", item, rank);
                                    }
                                }}
                            >
                                <AccordionSummaryComp
                                    sx={{
                                        padding: 0,
                                    }}
                                    expandIcon={selectedRankAccordion === rank ? <Icon>{collapseIcon}</Icon> : <Icon>{expandIcon}</Icon>}
                                >
                                    <Accordion.headerRenderer $={$} renderer={renderer} item={item} rank={rank} headerHeight={headerHeight} />
                                </AccordionSummaryComp>
                                <AccordionDetails sx={{ padding: 0, margin: 0 }} className="accordion-detail">
                                    <Accordion.bodyRenderer
                                        $={$}
                                        renderer={bodyRenderer}
                                        item={item}
                                        rank={rank}
                                        headerHeight={headerHeight}
                                        bodyWidth={bodyWidth}
                                        accordionLength={elements.length}
                                        selectedRankAccordion={selectedRankAccordion}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                ) : (
                    // No list or renderer
                    <Box
                        sx={{
                            backgroundColor: "lightgrey",
                            width: 1,
                            height: 1,
                            overflow: "hidden",
                        }}
                    >
                        <Typography sx={{ color: "black", padding: 1 }}>
                            <b>Accordion</b>
                        </Typography>
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    );
};

Accordion.headerRenderer = ({ $, renderer, item, rank, headerHeight }) => {
    if (renderer === null) {
        return null;
    }
    const ref = useRef();
    useEffect(() => {
        if (!ref.current || !item) {
            return; // No need to render the line if not displayed by the tree
        }
        const el = ref.current;

        const renderer$ = $.runner(renderer).set("Current Item", item).set("Current Rank", rank).set("Current List", $.get("Object List")).setParentElement(el);

        const staticHeight = 52;
        el.style.height = `${headerHeight || staticHeight}px`;
        renderer$.set("Height", headerHeight || staticHeight);

        setTimeout(() => {
            if (el) {
                const bounds = el.parentElement?.parentElement?.getBoundingClientRect();
                bounds && renderer$.set("Width", bounds.width);
            }
        }, 0);
        return () => renderer$?.destroy();
    });

    return <Box sx={{ width: "100%" }} className="header-renderer" ref={ref}></Box>;
};

Accordion.bodyRenderer = ({ $, renderer, item, rank, headerHeight, bodyWidth, accordionLength, selectedRankAccordion }) => {
    if (renderer === null) {
        return null;
    }
    const ref = useRef();

    useLayoutEffect(() => {
        if (!ref.current) {
            return; // No need to render the line if not displayed by the tree
        }

        let bodyRenderer$ = null;
        if (selectedRankAccordion === rank) {
            const element$ = ref.current;
            bodyRenderer$ = $.runner(renderer)
                .set("Current Item", item)
                .set("Current Rank", rank)
                .set("Current List", $.get("Object List"))
                .set("Width", bodyWidth)
                .setParentElement(element$);
            bodyRenderer$.observe("Height").subscribe((height) => {
                if (height > 0) {
                    element$.style.height = `${height}px`;
                    const newActualHeight = accordionLength * headerHeight + height;
                    const oldActualHeight = $.get("Actual Height");

                    if (newActualHeight < oldActualHeight) {
                        if (actualHeightTimeoutKey) {
                            clearTimeout(actualHeightTimeoutKey);
                        }
                        actualHeightTimeoutKey = setTimeout(() => {
                            $.set("Actual Height", newActualHeight);
                        }, 375); // 375 is time to wait accordion transition end
                    } else {
                        $.set("Actual Height", newActualHeight);
                    }
                }
            });
        }

        return () => {
            if (bodyRenderer$) {
                setTimeout(() => {
                    bodyRenderer$?.destroy();
                }, 375);
            }
        };
    }, [selectedRankAccordion, rank]);

    return <Box sx={{ width: bodyWidth }} ref={ref}></Box>;
};

registerBrick("01838d004179a86e076b", AccordionComp);
