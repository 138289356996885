import { UIBrick, registerBrick } from 'olympe';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {combineProps} from "./_helpers";
import { theme } from './_theme';
import ReactDOM from "react-dom";
import React from "react";

export class Timer extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        this.runningId = null;
        this.elapsedTime = null;
        this.maxTime = null;
        combineProps([
            { name: 'maxTime', prop: context.getProperty('Max Time [s]'), defaultValue: 'Date' },
            { name: 'elapsedTime', prop: context.getProperty('Elapsed Time [s]'), defaultValue: 'standard' },
            { name: 'upward', prop: context.getProperty('Upward'), defaultValue: false },
            { name: 'running', prop: context.getProperty('Running'), defaultValue: true },
        ])
            .subscribe(props => {
                // Running
                this.elapsedTime = props.elapsedTime;
                this.maxTime = props.maxTime;
                this.handleRunning(context, props.running);

                // Render
                const time = props.upward ? this.elapsedTime : (props.maxTime - this.elapsedTime);
                ReactDOM.render((<Timer.Component time={time} />), elementDom);
            });
    }

    /**
     * @private
     * @param {!Context} context
     * @param {boolean} running
     */
    handleRunning(context, running) {
        // Enable running
        if (running && this.runningId === null) {
            this.runningId = setInterval(() => {
                // Update elapsed time + check for no time left
                this.elapsedTime += 1;
                context.getProperty('Elapsed Time [s]').set(this.elapsedTime);
                if (this.elapsedTime === this.maxTime) {
                    context.getProperty('Running').set(false);
                    context.getEvent('On No Time Left').set(Date.now());
                }
            }, 1000);
        }

        // Disable running
        else if (!running && this.runningId !== null) {
            clearInterval(this.runningId);
            this.runningId = null;
        }
    }
}

registerBrick('017940b7d52b25f34527', Timer);

// Custom styles
Timer.useStyles = makeStyles({
    timer: {
        fontWeight: '700',
        fontSize: '24px',
        color: '#FF9500'
    }
});

/**
 * @param {{time: number}} props
 * @return {Element}
 */
Timer.Component = (props) => {
    const classes = Timer.useStyles();
    const minuts = Math.floor(props.time / 60);
    const seconds = props.time % 60;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Typography className={classes.timer}>
                    {minuts <= 9 && '0'}
                    {minuts}
                    {':'}
                    {seconds <= 9 && '0'}
                    {seconds}
                </Typography>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
