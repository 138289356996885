import { ActionBrick, registerBrick } from 'olympe';
import anime from "animejs";

export class AnimateNumber extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {number} from
     * @param {number} to
     * @param {number} durationMs
     * @param {string} easing
     * @param {number} numberOfDecimals
     * @param {string} direction
     * @param {number} numberOfRepeats
     * @param {function()} forwardEvent
     * @param {function(number)} setValue
     */
    update(context, [from, to, durationMs, easing, numberOfDecimals, direction, numberOfRepeats], [forwardEvent, setValue]) {
        const object = { value: from };
        anime({
            targets: object,
            value: [from, to],
            duration: durationMs,
            easing: easing,
            round: Math.pow(10, numberOfDecimals) || 100, // by default show 2 decimals, (10 = 1 decimals, 100 = 2 decimals, 1000 = 3 decimals, ...)
            direction: direction,
            loop: numberOfRepeats < 1 ? true : numberOfRepeats,
            autoplay: true,
            update: () => {
                setValue(object.value);
            },
            complete: () => {
                forwardEvent();
            }
        });
    }
}

registerBrick('017a33b18148d2059201', AnimateNumber);
