import { Context, Brick, registerBrick, ListDef, transformers, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';
import { checkList } from './helper';

export default class ListHasDuplicate extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {ListDef | Array | QueryResult} list
     * @param {function(boolean)} setHasDuplicates
     */
    update(context, [list], [setHasDuplicates]) {
        const isCorrectList = checkList(list);
        if (!isCorrectList) {
            getLogger('List Has Duplicate').error('List is not a ListDef, an Array or a QueryResult');
            return;
        }

        if (list instanceof ListDef) {
            const distinctList = list.transform(new transformers.Distinct());

            let fullSize = list.getCurrentSize();
            let distinctSize = distinctList.getCurrentSize();

            const update = () => {
                setHasDuplicates(fullSize !== distinctSize);
            };

            list.observeSize().subscribe(size => {
                fullSize = size;
                update();
            });

            distinctList.observeSize().subscribe(size => {
                distinctSize = size;
                update();
            });
        } else {
            const primaryArray = list instanceof QueryResult ? list.toArray() : list;
            setHasDuplicates((new Set(primaryArray)).size !== primaryArray.length);
        }
    }
}

registerBrick('017a5bdf98ef3f80bc8f', ListHasDuplicate);
