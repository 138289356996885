import { ActionBrick, registerBrick } from 'olympe';

export default class AddElementToJSON extends ActionBrick {

    /**
     * @protected
     * @param {BrickContext} context
     * @param {string} source
     * @param {string} key
     * @param {Object} value
     * @param {function()} forwardEvent
     * @param {function(string)} setResultJSON
     */
    update(context, [source, key, value], [forwardEvent, setResultJSON]) {
        if (typeof source !== 'string') {
            console.error('[AddElementToJSON] source is not a string!');
            return;
        }

        let resultJSON = '';
        try {
            const jsonObj = source ? JSON.parse(source) : {};

            if (typeof value === 'string') {
                // If value is a string, check if it is a stringified JSON object.
                try {
                    value = JSON.parse(value);
                } catch (e) {
                    // If value is a real string, wrap is inside quotes.
                    value = JSON.parse(JSON.stringify(value));
                }
            }

            jsonObj[key] = value;

            resultJSON = JSON.stringify(jsonObj);
        } catch (e) {
            console.error('[AddElementToJSON] Error while parsing json!');
            console.error(e.message);
        }

        // resolve outputs outside try...catch to avoid catching errors from any following brick
        if(resultJSON !== '') {
            setResultJSON(resultJSON);
            forwardEvent();
        }
    }
}

registerBrick('017a5dd1798a078d0f89', AddElementToJSON);
