import { Brick, registerBrick } from 'olympe';
import { getLogger } from "@olympeio/core";

export default class StringRepeat extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} value
     * @param {number} times
     * @param {function(string)} setRepeatedInput
     */
    update(context, [value, times], [setRepeatedInput]) {
        try {
            const isTimesTypeNumber = typeof times === 'number';
            if (isTimesTypeNumber && (times < 0 || times === Infinity || isNaN(times))) {
                throw 'times input must be greater than or equal to 0 and not be Infinity';
            }
            if (typeof value === 'string' && isTimesTypeNumber) {
                setRepeatedInput(value.repeat(times));
            } else {
                throw 'Please check the inputs types';
            }
        } catch (error) {
            getLogger('StringRepeat').error(error);
        }
    }
}

registerBrick('017b344bd993d74b512a', StringRepeat);
