import { ActionBrick, registerBrick, ErrorFlow } from 'olympe';

export default class RateLimiter extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {number} interval
     * @param {number} max
     * @param {*} data
     * @param {function()} forwardEvent
     * @param {function(*)} setError
     * @param {function(*)} setData
     */
    update(context, [interval, max, data], [forwardEvent, setError, setData]) {
        if(context.get('nextInterval') === undefined || context.get('nextInterval') < Date.now()){
            context.set('counter', 0);
            context.set('nextInterval', Date.now() + interval * 1000);
        }

        const newCounter = context.get('counter') + 1;
        context.set('counter', newCounter);
        if(newCounter > max){
            setError(ErrorFlow.create('Rate of control flow too high, not dispatching the event', 500));
        } else {
            setData(data);
            forwardEvent();
        }
    }
}

registerBrick('017bc47351b205e56538', RateLimiter);
