import { ActionBrick, registerBrick } from 'olympe';
import $ from "jquery";
import "jquery-confirm";
import { getLogger } from '@olympeio/core';

export default class Confirm extends ActionBrick {

    init(_) {
        const head  = document.getElementsByTagName('head')[0];

        const jqueryConfirmId = 'jquery-confirm';
        if (!document.getElementById(jqueryConfirmId)) {
            const jqueryConfirm  = document.createElement('link');
            jqueryConfirm.id   = jqueryConfirmId;
            jqueryConfirm.rel  = 'stylesheet';
            jqueryConfirm.type = 'text/css';
            jqueryConfirm.href = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-confirm/3.3.2/jquery-confirm.min.css';
            jqueryConfirm.media = 'all';
            head.appendChild(jqueryConfirm);
        }

        const bootstrapId = 'bootstrap';
        if (!document.getElementById(bootstrapId)) {
            const bootstrap  = document.createElement('link');
            bootstrap.id   = bootstrapId;
            bootstrap.rel  = 'stylesheet';
            bootstrap.type = 'text/css';
            bootstrap.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
            bootstrap.media = 'all';
            head.appendChild(bootstrap);
        }
    }

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} title
     * @param {string} text
     * @param {string} validBtnText
     * @param {string} cancelBtnText
     * @param {string} color
     * @param {function()} forwardEvent
     * @param {function(boolean)} setConfirmed
     */
    update(context, [title, text, validBtnText, cancelBtnText, color], [forwardEvent, setConfirmed]) {

        // Escaping inputs
        title = title || '';
        text = text || '';
        validBtnText = validBtnText || 'Ok';
        cancelBtnText = cancelBtnText || 'Cancel';

        let colorType;
        switch (color) {
            case 'green':
            case 'red':
            case 'blue':
            case 'orange':
            case 'purple':
            case 'dark':
                colorType = color; break;
            default: colorType = 'default';
        }

        if(typeof title === 'object' || typeof text === 'object' || typeof validBtnText === 'object' || typeof cancelBtnText === 'object'){
            getLogger('Alert').error('Type of all parameters from alert should be string');
            return;
        } else {
            title = String(title);
            text = String(text);
            validBtnText = String(validBtnText);
            cancelBtnText = String(cancelBtnText);
        }

        // Displaying the popup
        $.confirm({
            title: title,
            content: text,
            type: colorType,
            columnClass: 'medium',
            buttons: {
                confirm: {
                    text: validBtnText,
                    btnClass: 'btn-'+ colorType,
                    keys: ['enter'],
                    action: function () {
                        setConfirmed(true);
                        forwardEvent();
                    }
                },
                cancel: {
                    text: cancelBtnText,
                    keys: ['esc'],
                    action: function () {
                        setConfirmed(false);
                        forwardEvent();
                    }
                }
            }
        });
    }
}

registerBrick('017b2f20d822af0f7242', Confirm);
