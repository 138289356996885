<template>
  <div>
    <div class="carousel__container"
         v-if="!hidden"
         v-bind:style="cssProps"
         v-bind:class="{ 'carousel__controls' : showArrows }"
    >
      <Carousel
          ref="carousel"
          :wrap-around="true"
          :items-to-show="numberOfItems"
          :autoplay="autoplay"
          :mouseDrag="false"
          v-model="initialSlide"
      >
        <Slide
            v-for="(item, index) in instances"
            :key="index"
            v-bind:style="[{
              height: `${carouselHeight}px`,
            }]"
        >
          <div class="carousel__item"
              @click="onSlideClick(item)"
              v-bind:style="[{
                background: background,
                color: color,
                height: `${carouselHeight}px`,
                borderRadius: `${borderRadius}px`,
                borderColor: borderColor,
                borderWidth: `${borderWidth}px`,
                borderStyle: borderColor ? 'solid' : 'none',
              }]"
          >
            <div class="slide__renderer"
                 v-if="this.instances.length > 1"
                 v-bind:style="[{
                   width: `${slideWidth}px`,
                   height: `${carouselHeight}px`,
                   position: 'relative',
                 }]"
                 :ref="(el) => {
                   const renderer = this.context.get('Slide Renderer');
                   if (el && renderer && el.childElementCount < 1) {
                     // Create renderer
                     const renderer$ = this.context?.runner(renderer)
                     .set('Current Index', index)
                    .set('Current Item', item)
                    .set('Current List', this.context.get('Data'))
                    .setParentElement(el);

                    renderer$.set('Width', this.slideWidth);
                    renderer$.set('Height', this.carouselHeight);
                 }
               }"
            ></div>
            <div v-else>
              {{ 'No data' }}
            </div>
          </div>
        </Slide>

        <template #addons="{ slidesCount, currentSlide }">
          <Navigation
              v-if="showArrows"
              :slidesCount="slidesCount"
              :currentSlide="currentSlide"
              :color="color"
              :background="background"
              :continuous="continuous"
              @toNext="toNext"
              @toPrev="toPrev"
          />
          <Pagination v-if="showPagination" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { ListDef } from 'olympe';
import { cssToSxProps } from '@olympeio/core'
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import Navigation from './Navigation.vue';

export default defineComponent({
  name: 'CarouselItem',
  props: ['context', 'values'],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    const [data, hidden, [
      initialSlide,
      numberOfItems,
      continuous,
      autoplay,
      showControls,
      showPagination,
      cssString,
      height,
      width,
      backgroundColor,
      color,
      borderColor,
      borderRadius,
      borderWidth,
    ]] = this.values;

    const instances = Array.isArray(data) ? data : [];
    if (data instanceof ListDef) {
      data.forEachCurrentValue((item) => instances.push(item));
    }
    return {
      hidden,
      instances : instances.length === 0 ? [ 'No data'] : instances,
      initialSlide,
      numberOfItems,
      continuous,
      autoplay,
      showPagination,
      borderRadius,
      borderWidth,
      slides: [],
      background: backgroundColor?.toHexString(),
      color: color?.toHexString(),
      carouselHeight: height - (showPagination ? 50 : 0), // height of pagination,
      slideWidth: width - 30 - numberOfItems * 10 / numberOfItems, // paddings
      showArrows: showControls && instances.length > 1,
      cssProps: cssToSxProps(cssString), // transform css string to object
      borderColor:  borderColor?.toHexString(),
    };
  },
  methods: {
    onSlideClick(item) {
      this.context.getProperty('On Item Click').observe()
        .subscribe((onItemClick) => {
          if (onItemClick) {
            const [CtrlFlowInput, DataInput] = onItemClick.getInputs();
            this.context.runner(onItemClick)
              .set(DataInput, item)
              .trigger(CtrlFlowInput);
          }
        });
    },
    onSlideChange(currentObject, previousObject, currentSlide) {
      this.context.getProperty('On Slide Change').observe()
          .subscribe((onSlideChange) => {
            if (onSlideChange) {
              const [
                CtrlFlowInput,
                CurrentObjectInput,
                PreviousObjectInput,
                CurrentSlideInput
              ] = onSlideChange.getInputs();

              this.context.runner(onSlideChange)
                  .set(CurrentObjectInput, currentObject)
                  .set(PreviousObjectInput, previousObject)
                  .set(CurrentSlideInput, currentSlide)
                  .trigger(CtrlFlowInput);
            }
          });
    },
    toNext(currentSlide, previousSlide) {
      this.$refs.carousel.next();
      const currentObject = this.instances[currentSlide];
      const previousObject = this.instances[previousSlide];
      this.onSlideChange(currentObject, previousObject, currentSlide);
    },
    toPrev(currentSlide, previousSlide) {
      this.$refs.carousel.prev();
      const currentObject = this.instances[currentSlide];
      const previousObject = this.instances[previousSlide];
      this.onSlideChange(currentObject, previousObject, currentSlide);
    },
  },
});
</script>

<style>
.carousel__controls {
  padding-left: 15px;
  padding-right: 15px;
}

.carousel__item {
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.carousel__slide {
  padding: 10px 5px 10px 5px;
}

.carousel__pagination {
  margin: 0;
  padding: 0;
}

.carousel__pagination-button {
  background-color: #939393;
}

.carousel__pagination-button--active {
  background-color: black;
}

.slide__renderer * {
  left: 1px;
}
</style>
