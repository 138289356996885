import { Brick, registerBrick } from 'olympe';
import { getLogger } from '@olympeio/core';
import { cacheManager } from '../CacheManager';

export default class GetFromCache extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} key
     * @param {function(*)} setValue
     */
    update(context, [key], [setValue]) {
        if (typeof key !== 'string') {
            getLogger('GetFromCache').error(`Provided key is not of type string: ${key}`);
            return;
        }

        // unregister a previous listener for the key
        this.cleanLatestCallback(context);

        // register a new listener
        const cbIndex = cacheManager().registerCallback(key, setValue);
        context.set(GetFromCache.INPUT_KEY, key);
        context.set(GetFromCache.CALLBACK_INDEX, cbIndex);

        // return current stored value
        setValue(cacheManager().getByKey(key));
    }

    /**
     * @private
     * @param {Context} context
     */
    cleanLatestCallback(context) {
        const inputKey = context.get(GetFromCache.INPUT_KEY);
        const callbackKey = context.get(GetFromCache.CALLBACK_INDEX);

        if (inputKey !== undefined && callbackKey !== undefined) {
            cacheManager().unregisterCallback(inputKey, callbackKey);
        }
    }

    /**
     * @param {Context} context
     */
    destroy(context) {
        this.cleanLatestCallback(context);
    }

    /**
     * @private
     * @type {string}
     */
    static INPUT_KEY = 'INPUT_KEY';

    /**
     * @private
     * @type {string}
     */
    static CALLBACK_INDEX = 'CALLBACK_INDEX';
}

registerBrick('017d76a63671f80e8ade', GetFromCache);
