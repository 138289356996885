import { ActionBrick, registerBrick, ListDef, CloudObject, Transaction, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';
import { performOperation, checkList } from './helper';

export default class PersistListOfLocalObjects extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {Array<CloudObject> | ListDef | QueryResult} list
     * @param {function()} forwardEvent
     */
    update(context, [list], [forwardEvent]) {
        const logger = getLogger('Persist List');
        const isCorrectList = checkList(list);
        if (!isCorrectList) {
            logger.error('List is not a ListDef, an Array or a QueryResult');
            return;
        }

        const transaction = new Transaction();
        const persist = (objectIn) => {
            if (CloudObject.exists(objectIn)) {
                transaction.persist(objectIn);
            } else {
                logger.error('Cannot persist object: ' + objectIn);
            }
        }

        const execute = () => {
            transaction.executeAsLarge().then(() => {
                forwardEvent();
            }).catch((errMsg) => {
                logger.error(`Could not execute transaction. ${errMsg}`);
            });
        };

        performOperation(list, persist, execute);
    }
}

registerBrick('017d09cddd6c1a21be63', PersistListOfLocalObjects);
