import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { updateLanguage } from "./_language";

i18next
    .use(initReactI18next)
    .init({
        resources: {
            en: {},
            fr: {}
        }
    });

updateLanguage('en');
