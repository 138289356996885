import { ActionBrick, registerBrick } from 'olympe';

export default class Now extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array} inputs
     * @param {function()} forwardEvent
     * @param {function(Date)} setNow
     */
    update($, inputs, [forwardEvent, setNow]) {
        setNow(new Date());
        forwardEvent();
    }
}

registerBrick('018097dd902e6b2083fb', Now);
