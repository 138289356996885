import { PropertyPrimitive, registerBrick, CloudObject, BrickContext, RelationModel, Direction, Relation } from 'olympe';
import { getLogger, ReactBrick, useProperty, cssToSxProps, ifNotNull, ifNotTransparent } from '@olympeio/core'
import { isValidDate, getMuiType } from '../helpers/_helpers';
import React from 'react';
import Box from '@mui/material/Box';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { format } from 'date-fns';
import { DatagridComponent } from './components/DatagridComponent.jsx';
import { SearchField } from './components/SearchField.jsx';
import { useMediaQuery } from '@mui/material';

/**
 * Provide a Data Grid visual component using MUI DataGrid
 */
export default class Datagrid extends ReactBrick {
    /**
     * @override
     */
    setupExecution($) {
        return combineLatest([
            $.observe('Hidden', false),
            $.observe('Model').pipe(startWith(null)),
        ]);
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const [hidden, model] = props.values;
            const renderers = Datagrid.getRenderers($);
            const columns = model === null ? [] : Datagrid.parseProperties($, model, renderers.length);
            // Listen to the props controlling features of the datatable
            const showToolbar = useProperty($, 'Show Toolbar');
            const showFilter = useProperty($, 'Show Filter');
            const offsetTopOfGrid = showFilter && !showToolbar;
            const borderRadius = useProperty($, 'Border Radius');
            const borderWidth = useProperty($, 'Border Width');
            const refresh = useProperty($, 'Refresh Query');
            const isMobile = useMediaQuery('(max-width:600px)');

            return !hidden && (
                <Box
                    style={{ height: '100%', width: '100%', boxSizing: 'border-box', position: 'relative', overflow: 'hidden' }}
                    sx={{
                        borderWidth: borderWidth,
                        tabIndex: useProperty($, 'Tab Index'),
                        ...ifNotTransparent('backgroundColor', useProperty($, 'Default Color')),
                        ...ifNotNull('borderStyle', 'solid', borderWidth > 0),
                        ...ifNotTransparent('borderColor', useProperty($, 'Border Color')),
                        ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                        ...cssToSxProps(useProperty($, 'CSS Property')),
                    }}
                >
                    <SearchField $={$} isShown={showFilter} isMobile={isMobile} />
                    <div style={{ position: isMobile ? 'relavtive' : 'absolute', top: (offsetTopOfGrid || isMobile) ? '45px' : '0px', bottom: '0px', width: '100%', boxSizing: 'border-box' }}>
                        <DatagridComponent $={$} columns={columns} renderers={renderers} refresh={refresh} />
                    </div>

                </Box>
            );
        }
    }

    /**
     * @param {!BrickContext} $
     * @return {*[]}
     */
    static getRenderers($) {
        const renderers = [];
        for (let index = 1; index <= 10; index++) {
            const renderer = $.get(`Column Renderer ${index}`);
            if (renderer !== null && renderer !== undefined) {
                renderers.push(renderer);
            }
        }
        return renderers;
    }

    /**
     * @param {!BrickContext} $
     * @param {CloudObject} model
     * @param {number} renderersLength
     * @return {Object[]}
     */
    static parseProperties($, model, renderersLength) {
        const properties = Datagrid.getProperties($, model);
        const headers = Datagrid.getHeaders($, properties);
        const widths = Datagrid.getWidths($);
        const dateFormat = useProperty($, 'Date Format');
        const cols = [];
        properties.map((pName, i) => {
            const col = Datagrid.getCol(model, pName);
            if (col.propTag !== null) {
                const [muiType, model] = Datagrid.getPropertyType(col);
                cols.push({
                    ...col,
                    model: model,
                    type: muiType,
                    headerName: headers[i] || col.field,
                    width: widths[i] || widths[0],
                    valueFormatter: (params) => muiType === 'dateTime' && dateFormat ? Datagrid.getFormattedDate(params.value, dateFormat) : params.value,
                    valueGetter: (params) => muiType === 'dateTime' && dateFormat ? Datagrid.getFormattedDate(params.value, dateFormat) : params.value,
                });
            }
        });

        const arePropertiesMissed = $.get('Properties') === '';
        const areOnlyRenderersDefined = renderersLength > 0 && arePropertiesMissed;

        // Computing data grid columns according to the input properties, headers & renderers
        if (headers.length > cols.length || areOnlyRenderersDefined) {
            const startIndex = !arePropertiesMissed ? cols.length : 0;
            // create columns that will contain renderers
            const columnsFromHeaders = Datagrid.getColumnsFromHeaders(startIndex, headers, widths, renderersLength);
            return areOnlyRenderersDefined ? columnsFromHeaders : cols.concat(columnsFromHeaders);
        }
        return cols;
    }

    /**
     * @param {!BrickContext} $
     * @param {CloudObject} model
     * @return {string[]}
     */
    static getProperties($, model) {
        const props = useProperty($, 'Properties');
        if (props === '') {
            // Get properties from model
            return model.follow(CloudObject.propertyRel).executeFromCache().toArray().map(p => p.name());
        }
        return props ? props.split(',').map(p => p.trim()) : [];
    }

    /**
     * @param {!BrickContext} $
     * @return {number[]}
     */
    static getWidths($) {
        const widths = useProperty($, 'Column Widths');
        return widths ? widths.split(',').map(w => Number.parseFloat(w.trim()) || 250) : [250];
    }

    /**
     * @param {!BrickContext} $
     * @param {string[]} properties
     * @return {number[]}
     */
    static getHeaders($, properties) {
        let headers = useProperty($, 'Header Names');
        if (headers === '') {
            headers = properties.join(',');
            $.set('Header Names', headers);
            return headers;
        }
        return headers ? headers.split(',').map(h => h.trim()) : [];
    }

    /**
     * @param {CloudObject} model
     * @param {string} pName
     * @return {{field: string, propTag: *}}
     */
    static getCol(model, pName) {
        // Relation
        if (pName.indexOf('::') > -1) {
            return Datagrid.getRelProperty(pName.split('::'), model);
        }
        // Property
        return { propTag: model.follow(CloudObject.propertyRel).executeFromCache().find(p => p.name() === pName), field: pName };
    }

    /**
     * Iterates by model names, founds the property tag by last model
     *
     * @param {string[]} rels
     * @param {CloudObject} model
     * @return {{field: string, propTag: Empty, relation: Relation<CloudObject, CloudObject>}}
     */
    static getRelProperty(rels, model) {
        const pName = rels.pop();
        let destinationModel = model;
        let rel;
        for (let i = 0, l = rels.length; i < l; ++i) {
            rel = Datagrid.getRelModel(destinationModel, rels[i]);
            // no model found
            if (rel.relModel === null) return { propTag: null };
            destinationModel = rel.relModel.followSingle(RelationModel.destinationModelRel).executeFromCache();
        }
        const propTag = destinationModel.follow(CloudObject.propertyRel).executeFromCache().find(p => p.name() === pName);
        const relation = new Relation(rel.relModel, rel.isInverse ? Direction.ORIGIN : Direction.DESTINATION);
        return { propTag, relation, field: pName };
    }

    /**
     * @param {CloudObject} model
     * @param {string} name
     * @return {{isInverse: boolean, relModel: *}}
     */
    static getRelModel(model, name) {
        let relModel = model.follow(RelationModel.originModelRel.getInverse()).executeFromCache().find(m => m.name() === name);
        // Relation model may be inversive (from destination to origin)
        if (relModel === null) {
            relModel = model.follow(RelationModel.destinationModelRel.getInverse()).executeFromCache().find(m => m.name() === name);
            return { relModel, isInverse: true };
        }
        return { relModel, isInverse: false };
    }

    /**
     * @param {*} value
     * @param {string} dateFormat
     * @return {string}
     */
    static getFormattedDate(value, dateFormat) {
        const isValidDateType = isValidDate(value) && dateFormat;
        if (isValidDateType) {
            try {
                return format(value, dateFormat);
            } catch (e) {
                getLogger('Datagrid').error('Incorrect Date Format: ' + e.message);
            }
        }
        return value;
    };

    /**
     * @param {Object} col
     * @return {(string|CloudObject)[]}
     */
    static getPropertyType(col) {
        const [[type, model]] = col.propTag.follow(PropertyPrimitive.typeRel).andReturn().follow(PropertyPrimitive.modelRel).andReturn()
            .executeFromCache().toArray();
        const muiType = getMuiType(type.tag, col.field);
        return [muiType, model];
    }

    /**
     * protected
     * @param {number} startIndex
     * @param {string[]} headerNames
     * @param {number[]} widths
     * @param {number} renderersLength
     * @return {!Object[]}
     */
    static getColumnsFromHeaders(startIndex, headerNames, widths, renderersLength) {
        const columns = [];
        let rendererIndex = 0;
        for (let i = startIndex, l = headerNames.length; i < l; i++) {
            if (headerNames[i] && rendererIndex < renderersLength) {
                columns.push({
                    rendererIndex: rendererIndex++,
                    // React props
                    field: headerNames[i],
                    headerName: headerNames[i],
                    width: widths[i] || 250,
                });
            }
        }
        return columns;
    }
}

registerBrick('0179f0cf8b9ba2828d35', Datagrid);
