import { Brick, registerBrick } from 'olympe';

export default class FormatCurrency extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} countryCode
     * @param {string} currency
     * @param {number} value
     * @param {function(string)} setFormattedValue
     */
    update(context, [countryCode, currency, value], [setFormattedValue]) {
        setFormattedValue(new Intl.NumberFormat(countryCode, { style: 'currency', currency: currency }).format(value));
    }
}

registerBrick('017a8b167446bd8c9fd7', FormatCurrency);
