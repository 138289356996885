import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsArray } from '../../helper';

export default class Union extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} first
     * @param {*} second
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [first, second], [setResult, setErrorFlow]) {
        const logger = getLogger('Union');

        const firstArray = getAsArray(first);
        const secondArray = getAsArray(second);

        if (!firstArray.error && !secondArray.error) {
            const result = _.union(first, second);
            setResult(result);
        } else {
            const code = firstArray.error?.code || secondArray.error.code;
            const message = firstArray.error?.message + ' ' + secondArray.error?.message;
            logger.error(message);
            setErrorFlow(ErrorFlow.create(message, code));
        }
    }
}

registerBrick('017fe5b557a23f1dfd08', Union);