import { ActionBrick, registerBrick, File } from 'olympe';
import hash from 'crypto-hash'

export default class HashFile extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {File} fileToHash
     * @param {string} hashMethod
     * @param {function()} forwardEvent
     * @param {function(string)} setHashgenerated
     */
    update(context, [fileToHash, hashMethod], [forwardEvent, setHashgenerated]) {
        fileToHash.getContentAsBinary(content => {
            if(hash[hashMethod.toLowerCase()] !== undefined){
                hash[hashMethod.toLowerCase()](content).then(hashValue => {
                    setHashgenerated(hashValue);
                    forwardEvent();
                });
            } else {
                console.error('The hash method is unrecognised', hashMethod.toLowerCase());
                setHashgenerated('');
                forwardEvent();
            }
        });
    }
}

registerBrick('017a381492c854fb6ff8', HashFile);
