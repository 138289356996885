import { Brick, registerBrick } from 'olympe';
import { format, utcToZonedTime } from "date-fns-tz";

function formatInTimeZone(date, fmt, tz) {
    const formattedTime = format(utcToZonedTime(date, tz), fmt, {
        timeZone: tz
    });
    return formattedTime;
}

export default class LocalDateToUTC extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {Date} localDate
     * @param {function(Date)} setUtcDate
     */
    update(context, [localDate], [setUtcDate]) {
        const formattedTime = formatInTimeZone(localDate, "yyyy-MM-dd kk:mm:ss xxx", "UTC");
        setUtcDate(formattedTime);
    }
}

registerBrick('017cb74f6ee55a5ba3ae', LocalDateToUTC);
