import React from 'react';
import { Color } from 'olympe';

const createMaterialIconsElement = () => {
    const materialIconsElement = document.createElement('link');
    materialIconsElement.id = 'materialIconsStyle';
    materialIconsElement.rel = 'stylesheet';
    materialIconsElement.type = 'text/css';
    materialIconsElement.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';

    return materialIconsElement;
}

/**
 * Pour utiliser les icônes Material UI, il faut ajouter
 * <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
 * dans le fichier index.html
 *
 * @param {string} name
 * @param {boolean} useDefaultIcon
 * @param {Object=} props
 * @return {JSX.Element}
 */
export function getIcon(name, useDefaultIcon = true, props = {}) {
    const head = document.getElementsByTagName('head')[0];

    const hasMaterialIcon = document.getElementById('materialIconsStyle');
    if (!hasMaterialIcon) {
        const linkElement = createMaterialIconsElement();
        head.appendChild(linkElement);
    }

    if (!name) {
        name = useDefaultIcon ? 'Help' : '';
    }

    let css = props.style;
    if (props.color instanceof Color) {
        css.color = props.color.toHexString();
    }

    return (<i className="material-icons" style={css}>{name.toLowerCase()}</i>);
}
