import { registerBrick } from 'olympe';
import { ReactBrick, useProperty, useMUITheme, ifNotNull, ifNotTransparent, cssToSxProps } from '@olympeio/core';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import MUISkeleton from '@mui/material/Skeleton';

/**
 * Provide a Skeleton visual component using MUI Skeleton
 */
export default class Skeleton extends ReactBrick {
    /**
     * @type {Map<string, string | boolean>}
     */
    static animations = new Map([
        ['default', 'pulse'],
        ['pulse', 'pulse'],
        ['wave', 'wave'],
        ['none', false]
    ]);

    /**
     * @override
     */
    updateParent(parent, element) {
        parent.style.overflow = 'visible'; // Allow overflow
        return super.updateParent(parent, element);
    }

    /**
     * @override
     */
    setupExecution($) {
        return $.observe('Hidden');
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const [hidden] = props.values;
            const theme = useMUITheme($);

            // Properties
            const variant = useProperty($, 'Variant');
            const animation = useProperty($, 'Animation');

            // UI
            const height = useProperty($, 'Height');
            const width = useProperty($, 'Width');
            const color = useProperty($, 'Default Color');
            const borderRadius = useProperty($, 'Border Radius');
            const cssProperty = useProperty($, 'CSS Property');
            const borderColor = useProperty($, 'Border Color');
            const borderWidth = useProperty($, 'Border Width');

            return !hidden && (
                <ThemeProvider theme={theme}>
                    <MUISkeleton
                        width={width}
                        height={height}
                        variant={variant}
                        animation={Skeleton.animations.get(animation)}
                        sx={{
                            ...ifNotTransparent('backgroundColor', color),
                            ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                            ...ifNotNull('borderWidth', borderWidth),
                            ...ifNotTransparent('borderColor', borderColor),
                            ...ifNotTransparent('borderStyle', 'solid', borderColor),
                            ...ifNotNull('boxSizing', 'border-box', borderWidth),
                            ...cssToSxProps(cssProperty),
                        }}
                    />
                </ThemeProvider>
            );
        };
    }
}

registerBrick('01881e63a57779bd7a1d', Skeleton);
