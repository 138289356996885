import { ActionBrick, registerBrick } from 'olympe';

let objectAttribute = '';
let matchingValue = '';

function findJSONObject(object) {
    return object[objectAttribute] === matchingValue;
}

export default class JSONFind extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} jSONArray
     * @param {string} attribute
     * @param {string} value
     * @param {function()} forwardEvent
     * @param {function(*)} setObject
     * @param {function(boolean)} setMatch
     */
    update($, [JSONArray, attribute, value], [forwardEvent, setObject, setMatch]) {

        try {
            objectAttribute = attribute;
            matchingValue = value;

            //const startTime = new Date();
            const foundObject = JSONArray.find(findJSONObject);
            //console.log(JSON.stringify(foundObject));
            //const endTime = new Date();
            //console.log(endTime-startTime + 'ms');
          
            if (foundObject === undefined) {
                setObject({});
                setMatch(false);
            } else {
                setObject(foundObject);
                setMatch(true);
            }
        } catch(error) {
            console.log('Error in JSONFind brick');
        };
        forwardEvent();
    }
}

registerBrick('0180d1825dbb480b7aa6', JSONFind);
