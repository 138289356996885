import { Brick, registerBrick } from 'olympe';

export default class SVGCanvas extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array} inputs
     * @param {function(*)} setSVGRoot
     */
    update($, inputs, [setSVGRoot]) {
        setSVGRoot(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
    }
}

registerBrick('01860dc4c476554552b2', SVGCanvas);
