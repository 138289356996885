import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core'
import {getAsArray, Helper} from '../helper';

export default class FindLast extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {string} source
     * @param {*} wherePredicate
     * @param {number} fromIndex
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    async update($, [source, wherePredicate, fromIndex], [setResult, setErrorFlow]) {
        const logger = getLogger('FindLast');

        // Guards
        if (!(wherePredicate instanceof Brick)) {
            logger.error('Provided where input is not a Brick');
            return;
        }
        if (typeof fromIndex !== 'number') {
            logger.error('Provided fromIndex input is not a number');
            return;
        }

        const { array, error } = getAsArray(source);

        if (error) {
            logger.error(error.message);
            return;
        }

        try {
            const result = await this.processReverse($, array, fromIndex, wherePredicate);
            setResult(result);
        } catch (error) {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {!Array} array
     * @param {number} fromIndex
     * @param {!Brick} wherePredicate
     * @return {!Promise<Object>}
     */
    async processReverse($, array, fromIndex, wherePredicate) {
        for (let i = fromIndex === -1 ? array.length - 1 : fromIndex; i >= 0; i--) {
            if (await Helper.predicate($, array[i], i, array, wherePredicate)) {
                return array[i];
            }
        }
        return null;
    }
}

registerBrick('017ff371838376226f9e', FindLast);
