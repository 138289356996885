import { ActionBrick, registerBrick } from 'olympe';
import {getLogger} from "@olympeio/core";

export default class SVGAppend extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {SVGElement} svgParent
     * @param {SVGElement} svgChild
     * @param {function()} forwardEvent
     * @param {function(SVGElement)} setSvgParent
     * @param {!Array} outputs
     */
    update($, [svgParent, svgChild], [forwardEvent, setSvgParent]) {
        if(svgParent instanceof SVGElement && svgChild instanceof SVGElement){
            svgParent.appendChild(svgChild);
            setSvgParent(svgParent);
            forwardEvent();
        } else {
            getLogger('SVGAppend').error('The parent and the child should be SVG components');
        }
    }
}

registerBrick('01860dd3a21390e2eab1', SVGAppend);
