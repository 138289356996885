import { Brick, registerBrick } from 'olympe';

export default class Null extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {!Array} inputs
     * @param {function(*)} setNull
     */
    update(context, inputs, [setNull]) {
        setNull(null);
    }
}

registerBrick('017bbbd55e7205eddf1f', Null);
