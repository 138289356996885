import { Brick, registerBrick, ListDef } from 'olympe';
import {getLogger} from '@olympeio/core';

export default class ExecuteListQuery extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {ListDef} list
     * @param {function()} setControlFlow
     * @param {function(ListDef)} setList
     */
    update(context, [list], [setControlFlow, setList]) {
        if(!(list instanceof ListDef)) {
            getLogger('ExecuteListQuery').error(`Provided list is not of type ListDef`);
            return;
        }

        list.onReady(() => {
            setList(list);
            setControlFlow(Date.now());
        });
    }
}

registerBrick('017d941e2199c0f0f0ce', ExecuteListQuery);
