import { Brick, registerBrick } from 'olympe';
import { getLanguage } from "./_language";

export class GetCurrentLanguage extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {function(string)} setLanguage
     */
    update(context, [], [setLanguage]) {
        getLanguage().subscribe(/** @param {string} _language */ _language => {
            setLanguage(_language);
        });
    }
}

registerBrick('0178e95864de5ab8a760', GetCurrentLanguage);
