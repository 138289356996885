import { BrickContext, Brick, registerBrick } from 'olympe';
import {getLogger} from '@olympeio/core';

export default class Debounce extends Brick {

    /**
     * @protected
     * @param {BrickContext} $
     * @param {number} duration
     * @param {*} flow
     * @param {function(*)} setFlow
     */
    update($, [duration, flow], [setFlow]) {
        // Guards
        if (typeof duration !== 'number' || duration < 0) {
            getLogger('Debounce').error('Duration provided is not a positive number', duration);
            return;
        }
        const timeoutHandler = setTimeout(() => setFlow(flow), duration);
        // Clear previous timeout in case the brick is re-triggered before it has been ended
        $.onClear(() => clearTimeout(timeoutHandler));
    }
}

registerBrick('017acce9ed94589246c6', Debounce);
