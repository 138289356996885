import { Brick, registerBrick, ErrorFlow } from 'olympe';
import * as _ from 'lodash';
import { getLogger } from '@olympeio/core';
import { getAsJson } from '../../helper';

export default class Keys extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [source], [setResult, setErrorFlow]) {
        const logger = getLogger('Keys');

        const { json, error } = getAsJson(source);

        if (!error) {
            setResult(_.keys(json));
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017ff8ed24ba59833d15', Keys);