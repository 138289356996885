import { VisualBrick, registerBrick, EnumValue, Sync, DBView } from 'olympe';
import React from 'react';
import ReactDOM from 'react-dom';
import { of, combineLatest, Observable } from 'rxjs';
import { combineLatestWith, map, switchMap } from 'rxjs/operators';
import { KanbanBoard } from './components/KanbanBoard.jsx';

export default class Kanban extends VisualBrick {
    /**
     * @override
     */
    setupExecution($) {
        const properties = [
            'Status', 'Title', 'Description',
            'X', 'Y', 'Columns Color', 'Tiles Color',
            'Font Family', 'Font Size'
        ];

        // Observe the list size to retrigger an update if necessary
        const observeList = $.observe('Data', false);
        const observeListSize = observeList.pipe(switchMap(list => {
            if (!list) {
                return of(0);
            }
            return Array.isArray(list) ? of(list.length) : list.observeSize();
        }));

        const observeListAsArray = observeList.pipe(
            combineLatestWith(observeListSize),
            map(([list, size]) => list)
        );

        // Observe the list size to retrigger an update if necessary
        const observeEnum = $.observe('Columns', false);
        const observeEnumsSize = observeEnum.pipe(
            switchMap((enumModel) => enumModel === null ? of(0) : enumModel.getValues().observeSize())
        );

        const observeColumns = $.observe($.getProperty('Columns')).pipe(
            switchMap((enumModel) => {
                return enumModel === null ? of([]) : of(enumModel.getValues()).pipe(
                    combineLatestWith(observeEnumsSize),
                    switchMap(([list, size]) => {
                        const values = [];

                        if (size > 0) {
                            list.forEach(enumValue => values.push(combineLatest([
                                enumValue.observeProperty(EnumValue.valueProp),
                                enumValue.observeProperty(EnumValue.nameProp),
                                enumValue.observeProperty(EnumValue.rankProp)
                            ])));
                            return combineLatest(values);
                        }
                        return of([]);
                    }),
                    map(values => values.map(value => ({
                        value: value[0],
                        label: value[1] || value[0], // EnumItem may not have a name
                        rank: value[2]
                    }))),
                    map(values => values.sort((a, b) => a.rank - b.rank))
                );
            })
        );

        return $.observe('Width', false).pipe(
            combineLatestWith(observeListAsArray, observeColumns),
            combineLatestWith(properties.map(p => $.observe(p)))
        );
    }

    /**
     * @override
     * @param {!Element} parent
     * @param {!Element} element
     * @return {function()}
     */
    updateParent(parent, element) {
        parent.style.overflow = 'visible';
        ReactDOM.render(element, parent);
        return () => ReactDOM.unmountComponentAtNode(parent);
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array<*>} properties
     * @return {Element}
     */
    render($, properties) {
        const [
            [width, instances, columnNames],
            status, title, description,
            x, y, columnColor, cardColor,
            fontFamily, fontSize
        ] = properties;

        const cards = this.getCards(instances, { status, title, description }, $);
        const columns = this.getColumns(columnNames);
        const board = this.getBoard(columns, cards);
        const cardWidth = this.getWidth(width, columns.length);

        const onStatusChangedObs = new Observable(observer => {
            $.getProperty('On Status Changed').observe().subscribe(onStatusChanged => {
                const onStatusChangedContext = $.createChild('onStatusChanged');
                onStatusChanged?.run(onStatusChangedContext);
                observer.next({ ctx: onStatusChangedContext, inputs: onStatusChanged?.getInputs() });
            });
        });

        const onTileClickObs = new Observable(observer => {
            $.getProperty('On Tile Click').observe().subscribe(onTileClick => {
                const onTileClickContext = $.createChild('onTileClick');
                onTileClick?.run(onTileClickContext);
                observer.next({ ctx: onTileClickContext, inputs: onTileClick?.getInputs() });
            });
        });

        const boardProps = { cardWidth, cardColor, columnColor, fontSize, fontFamily, x, y, status };

        return (
            <KanbanBoard
                board={board}
                boardProps={boardProps}
                onStatusChangedContext={onStatusChangedObs}
                onTileClickContext={onTileClickObs}
            />
        );
    }

    /**
     * @private
     * @param {number} totalWidth
     * @param {number} columnsNumber
     * @return {number}
     */
    getWidth(totalWidth, columnsNumber) {
        /*
          5 - column margin
          15 - column padding
          10 - board padding
        */
        const cardWidth = (totalWidth - (columnsNumber * 2 * (5 + 15) + 10)) / columnsNumber;
        return cardWidth;
    };

    /**
     * @private
     * @param {Array} instances
     * @param {Object} boardProps
     * @return {Array}
     */
    getCards(instances, boardProps, $) {
        const cards = [];
        instances.forEach(instance => {
            cards.push(this.proccessInstanceToCard(instance, boardProps));
        });

        $.getProperty('Tile Color').observe().subscribe(tileColor => {
            const [currentObject] = tileColor?.getInputs();
            const [color] = tileColor?.getOutputs();

            cards.forEach(card => {
                const onColorChangedContext = $.createChild('tileColor' + card.id);
                onColorChangedContext.set(currentObject, card.id);
                tileColor?.run(onColorChangedContext);

                const colorObservable = onColorChangedContext.observe(color);
                card.color = colorObservable;
            });
        });
        return cards;
    }

    /**
     * @private
     * @param {Object} instance
     * @param {Object} boardProps
     * @return {Object}
     */
    proccessInstanceToCard(instance, boardProps) {
        const cardObject = {
            id: Sync.getInstance(instance).getTag(),
            status: DBView.get().getProperty(instance, boardProps.status),
            title: DBView.get().getProperty(instance, boardProps.title),
            description: DBView.get().getProperty(instance, boardProps.description)
        };
        return cardObject;
    }

    /**
     * @private
     * @param {Array} columnEnums
     * @return {Array}
     */
    getColumns(columnEnums) {
        const columns = [];

        let index = 0;
        columnEnums.forEach(columnEnum => {
            const columnObject = {
                id: index++,
                title: columnEnum.label,
                cards: []
            };
            columns.push(columnObject);
        });
        return columns;
    }

    /**
    * @private
    * @param {Array} emptyColumns
    * @param {Array} cards
    * @return {Object}
    */
    getBoard(emptyColumns, cards) {
        const board = {
            columns: []
        };

        emptyColumns.forEach(column => {
            column.cards = cards.filter(card => card?.status?.toLowerCase() === column?.title?.toLowerCase());
            board.columns.push(column);
        });
        return board;
    }
}

registerBrick('017e2a4207cbafa22474', Kanban);
