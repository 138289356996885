import { registerBrick, CloudObject, Transaction, File, ErrorFlow } from 'olympe';
import { getLogger, ReactBrick, useProperty, cssToSxProps, dataUrlToBinary, ifNotNull } from '@olympeio/core'
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SignaturePad from 'signature_pad';

export default class DrawingBox extends ReactBrick {
    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const width = useProperty($, 'Width');
            const height = useProperty($, 'Height');
            const borderRadius = useProperty($, 'Border Radius');

            return (
                <Box
                    sx={{
                        width: width,
                        height: height,
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        borderStyle: useProperty($, 'Border Width') ? 'solid' : 'none',
                        borderColor: useProperty($, 'Border Color')?.toHexString(),
                        ...ifNotNull('borderWidth', useProperty($, 'Border Width')),
                        ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                        ...cssToSxProps(useProperty($, 'CSS Property')),
                    }}
                >
                    <DrawingBox.Component
                        $={$}
                        width={width}
                        height={height}
                        showSave={useProperty($, 'Show Save Button')}
                        showClear={useProperty($, 'Show Clear Button')}
                    />
                </Box>
            );
        }
    }
}

DrawingBox.Component = ({ $, width, height, showSave, showClear }) => {
    const signatureRef = React.useRef();
    const [signaturePad, setSignaturePad] = React.useState(null);

    const penColor = useProperty($, 'Pen Color');
    const backgroundColor = useProperty($, 'Default Color');
    const buttonColor = useProperty($, 'Button Color');
    const dotSize = useProperty($, 'Dot Size');
    const maxThickness = useProperty($, 'Max Line Thickness');
    const minThickness = useProperty($, 'Min Line Thickness');
    const data = useProperty($, 'Current Drawing');
    const onDrawingChange = useProperty($, 'On Drawing Change');
    const onSave = useProperty($, 'On Save');

    React.useEffect(() => {
        if (signaturePad) {
            signaturePad.penColor = penColor?.toHexString();
            signaturePad.dotSize = dotSize;
            signaturePad.minWidth = minThickness;
            signaturePad.maxWidth = maxThickness;
        }
    });

    React.useEffect(() => {
        if (onDrawingChange && signaturePad) {
            signaturePad.addEventListener('endStroke', async () => {
                const file = await getFileFromCanvas();
                $.set('Current Drawing', file);

                const [startInput, dataInput] = onDrawingChange.getInputs();
                $.runner(onDrawingChange)
                    .set(dataInput, file)
                    .trigger(startInput);
            }, {});
        }
    }, [onDrawingChange]);

    const handleOnSave = async () => {
        if (onSave && signaturePad) {
            const file = await getFileFromCanvas();
            const [startInput, dataInput] = onSave.getInputs();
            $.runner(onSave)
                .set(dataInput, file)
                .trigger(startInput);
        }
    };

    const onClear = () => {
        if (signaturePad) {
            signaturePad.clear();
        }
        // On Clear Event was triggered outside the component
        else {
            const context = signatureRef.current.getContext('2d');
            context.clearRect(0, 0, signatureRef.current.width, signatureRef.current.height);
        }
        $.set('Current Drawing', null);
    };

    React.useEffect(() => {
        setSignaturePad(new SignaturePad(signatureRef.current, {}));

        const subscription = $.observe('On Clear').subscribe(() => {
            onClear();
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    React.useEffect(() => {
        // initialize data
        if (data instanceof File && signaturePad?._data.length === 0) {
            data.getContentUrl((content) => {
                signaturePad.fromDataURL(content);
            });
        }
    }, [data]);

    const getFileFromCanvas = async () => {
        const base64Content = signaturePad.toDataURL();
        const transaction = new Transaction(false);
        const fileTag = File.createFromContent(
            transaction,
            'signature',
            dataUrlToBinary(base64Content),
            'image/png'
        );

        const file = await new Promise((done) => {
            transaction.execute()
                .then(() => {
                    done(CloudObject.get(fileTag));
                }).catch(message => {
                    getLogger('Signature Box').error(ErrorFlow.create(message, 1));
                })
        });

        return file;
    };

    const buttonSx = {
        color: buttonColor?.toHexString(),
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        display: 'block',
    };

    return (
        <>
            {showSave ? (
                <Button
                    onClick={handleOnSave}
                    sx={{
                        right: 0,
                        ...buttonSx,
                    }}
                >
                    Save
                </Button>
            ) : null}
            {showClear ? (
                <Button
                    onClick={onClear}
                    sx={buttonSx}
                >
                    Clear
                </Button>
            ) : null}
            <canvas
                ref={signatureRef}
                width={width}
                height={height}
                style={{
                    backgroundColor: backgroundColor?.toHexString(),
                    position: 'relative',
                }}
            />
        </>
    );
};

registerBrick('0180085b2632e2f98eec', DrawingBox);