import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import { getAsArray, Helper } from '../helper';

export default class UniqWith extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {*} comparator
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    async update($, [source, comparator], [setResult, setErrorFlow]) {
        const logger = getLogger('Uniq With');

        // Guards
        if (!(comparator instanceof Brick)) {
            logger.error('Provided comparator input is not a Brick');
            return;
        }

        const { array, error } = getAsArray(source);

        if (error) {
            logger.error(error.message);
            return;
        }

        try {
            const result = await Helper.uniqueWithAsync($, array, comparator);
            setResult(result);
        } catch (error) {
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017ff4c357143f8bf1ed', UniqWith);
