import { Brick, registerBrick } from 'olympe';
import i18next from "i18next";
import {getLanguage} from "./_language";
import {GetLocalisedText} from "./GetLocalisedText";

export class GetLocalisedTextWithCount extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} key
     * @param {number} count
     * @param {function(string)} setText
     */
    update(context, [key, count], [setText]) {
        getLanguage().subscribe(() => {
            GetLocalisedText.addKey(key);
            setText(i18next.t(key, { count: count }));
        });
    }
}

registerBrick('017988adb30f99de3ec1', GetLocalisedTextWithCount);
