import { ActionBrick, registerBrick, ListDef, ErrorFlow, File as OFile, Transaction, CloudObject } from 'olympe';
import { getLogger, stringToBinary } from '@olympeio/core';
import { XMLBuilder } from 'fast-xml-parser';

export default class CreateXMLFile extends ActionBrick {

    /**
     * @override
     */
    init($) {
        this.db = DBView.get();
        this.logger = getLogger('CreateXMLFile');
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {ListDef | Array | QueryResult} data
     * @param {string} tagNames
     * @param {Map} mapping
     * @param {string} fileName
     * @param {function()} forwardEvent
     * @param {function(File)} setFile
     * @param {function(*)} setErrorFlow
     */
    update($, [data, tagNames, mapping, fileName], [forwardEvent, setFile, setErrorFlow]) {

        if (!(data instanceof ListDef || data instanceof Array)) {
            this.logger.error(`The data input is supposed to be a list`);
            setErrorFlow(ErrorFlow.create(`The data input is supposed to be a list`, 1));
            return;
        }
        if (!(mapping instanceof Map)) {
            this.logger.error(`The mapping is supposed to be a Map object`);
            setErrorFlow(ErrorFlow.create(`The mapping is supposed to be a Map object`, 1));
            return;
        }
        if (typeof tagNames !== 'string') {
            this.logger.error(`The tagNames is supposed to be a string`);
            setErrorFlow(ErrorFlow.create(`The tagNames is supposed to be a string`, 1));
            return;
        }
        if(tagNames.split('.').length <= 1){
            this.logger.error(`The tag names must have at least two tags: The root tag, and a tag for each element in the collection`);
            setErrorFlow(ErrorFlow.create(`The tag names must have at least two tags: The root tag, and a tag for each element in the collection`, 3));
            return;
        }

        const dataList = this.serializeDataAsArrayOfObjects(data, mapping);

        const xmlData = this.generateData(tagNames, dataList);
        const builder = new XMLBuilder();
        const output = builder.build(xmlData);

        const transaction = new Transaction(false);
        const fileTag = transaction.create(OFile);
        OFile.setContent(transaction, fileTag, fileName, stringToBinary(output));
        transaction.execute().then(() => {
            setFile(CloudObject.get(fileTag));
            forwardEvent();
        }).catch((message) => {
            setErrorFlow(ErrorFlow.create(message, 2));
        })
    }

    /**
     * Generate a nested object from path
     *
     * @param {string} path
     * @param {*} value
     */
    generateData(path, value) {
        const object = {};
        const splittedPath = path.split('.');

        splittedPath.reduce(
            (previousValue, currentValue, i) => {
                if (splittedPath.length === ++i) {
                    previousValue[currentValue] = value;
                } else {
                    previousValue[currentValue] = previousValue[currentValue] || {};
                }
                return previousValue[currentValue];
            },
            object
        );
        return object;
    }

    /**
     * @private
     * @param {(ListDef | Array | QueryResult)} inputData
     * @param { Map } mapping
     * @return { Array }
     */
    serializeDataAsArrayOfObjects(inputData, mapping) {
        const dataList = [];
        const array = Array.isArray(inputData) ? inputData : [];

        if (inputData instanceof ListDef) {
            inputData.forEachCurrentValue((item) => array.push(item))
        }

        array.forEach(object => {
            const dataLine = {};
            for (let key of mapping.keys()) {
                dataLine[key] = object.get(mapping.get(key));
            }
            dataList.push(dataLine);
        });
        return dataList;
    }
}

registerBrick('017e43decc4b70bc50e3', CreateXMLFile);
