import { UIBrick, registerBrick } from 'olympe';
import { combineProps, getObjectFromJSON } from "./_helpers";
import ReactDOM from "react-dom";
import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';

export class MUICircularProgress extends UIBrick {


    colorsCircularProgress = [
        'error', 'primary', 'secondary', 'info', 'success', 'warning', 'inherit'
    ];

    variants = ['indeterminate', 'determinate'];

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        combineProps([
            { name: 'styleJSON', prop: context.getProperty('Style [JSON]') },
            { name: 'muiColor', prop: context.getProperty('MUI Color') },
            { name: 'value', prop: context.getProperty('Value'), defaultValue: 0 },
            { name: 'size', prop: context.getProperty('Size'), defaultValue: 40 },
            { name: 'thickness', prop: context.getProperty('Thickness'), defaultValue: 3.6 },
            { name: 'minValue', prop: context.getProperty('Min Value'), defaultValue: 0 },
            { name: 'maxValue', prop: context.getProperty('Max Value'), defaultValue: 100 },
            { name: 'muiVariant', prop: context.getProperty('MUI Variant') },
        ]).subscribe(props => {
            const { muiColor, muiVariant, ...otherProps } = props;
            const verifiedMUIColor = this.colorsCircularProgress.includes(muiColor) ? muiColor : 'primary';
            const verifiedVariant = this.variants.includes(muiVariant) ? muiVariant : 'indeterminate';
            ReactDOM.render((
                <MUICircularProgress.Component
                    {...otherProps}
                    muiColor={verifiedMUIColor}
                    muiVariant={verifiedVariant}
                />),
                elementDom
            );
        });
    }
}

registerBrick('01791dc01d3a1711f3f8', MUICircularProgress);

/**
 * @param {{
 *   styleJSON: string
 *   muiColor: string
 *   value: number
 *   size: number
 *   thickness: number
 *   minValue: number
 *   maxValue: number
 *   muiVariant: string
 *   }} props
 * @return {Element}
 */
MUICircularProgress.Component = (props) => {
    const style = getObjectFromJSON(props.styleJSON);
    const step = 100 / (props.maxValue - props.minValue);
    const value = (props.value - props.minValue) * step;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CircularProgress
                    color={props.muiColor}
                    style={style}
                    value={value}
                    size={props.size}
                    thickness={props.thickness}
                    variant={props.muiVariant}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
