import { ActionBrick, registerBrick, BrickContext } from 'olympe';
import { snackbarManager } from './SnackbarManager.jsx';

export default class ShowSnackbar extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} _$
     * @param {string} message
     * @param {number} autoHideDuration
     * @param {string} severity
     * @param {string} variant
     * @param {string} anchor
     * @param {function()} forwardEvent
     */
    update(_$, [message, autoHideDuration, severity, variant, anchor], [forwardEvent]) {
        snackbarManager().show(message, autoHideDuration, severity, variant, anchor);
        forwardEvent();
    }
}

registerBrick('01881e58c498cf31fedf', ShowSnackbar);
