import { Brick, ListDef, Property, Sync, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';

export default class AggregateFunction extends Brick {

    /**
     * @public
     * @param {ListDef | Array | QueryResult} list
     * @param {Property<number>} prop
     * @param {function(Array<number>) : number} aggregateFunction
     */
    aggregate(list, prop, aggregateFunction) {
        const logger = getLogger('Aggregate Function');
        const map = {};
        const update = () => {
            aggregateFunction(Object.values(map));
        };

        if (Array.isArray(list) || list instanceof QueryResult) {
            list.forEach(
                (obj) => {
                    if (obj instanceof Sync) {
                        const tag = obj.getTag();
                        obj.observeProperty(prop).subscribe(value => {
                            map[tag] = value;
                            update();
                        });
                    } else {
                        logger.error('List should only contain Sync!');
                    }
                }
            );
        } else if (list instanceof ListDef) {
            list.forEach(
                (obj, key) => {
                    obj.observeProperty(prop).subscribe(value => {
                        map[key] = value;
                        update();
                    });
                },
                (key) => {
                    delete map[key];
                    update();
                }
            );
        } else {
            logger.error('List is not a ListDef, an Array or a QueryResult');
        }
    }
}
