import { UIBrick, registerBrick, Sync, Color } from 'olympe';
import { convertObservableToBehaviorSubject, combineProps } from '@olympeio-extensions/commons';
import React from 'react';
import ReactDOM from 'react-dom';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import compareVersions from 'compare-versions';
import { OptionsList } from './components/OptionsList.jsx';
import { Menu as MenuModel } from '../../common/models/Menu';
import { getLogger } from '@olympeio/core';

export default class Menu extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        this.context = context;

        const menuItems = this.processLocalData(context);
        const styleProps = this.processStyleProps(context);

        combineLatest([
            styleProps,
            menuItems,
        ]).subscribe(([styling, options]) => {
            const optionsWithChildren = this.addChildrenToMenuOption(options);

            ReactDOM.render(
                (
                    <OptionsList
                        options={optionsWithChildren}
                        styling={styling}
                    />
                ),
                elementDom,
            );
        });
    }

    /**
     * @private
     * @param {!Context} context
     * @return {Observable<[]>}
     */
    processLocalData(context) {
        const data = context.observe(context.getProperty('Data (local)'));

        const menuOptions = data.pipe(map((_data) => {
            let index = 0;
            const options = [];
            try {
                _data?.forEach(/** @type Menu */ entry => {
                    const entryInstance = Sync.getInstance(entry);
                    if (!(entryInstance instanceof MenuModel)) {
                        throw 'Data (local) must be a list created from Create Menu Item brick';
                    }
                    options.push(this.entryToMenuOption(index++, entryInstance));
                });
            } catch (error) {
                getLogger('Menu').error(error);
            }

            return options;
        }));

        return convertObservableToBehaviorSubject(menuOptions, []).subject;
    }

    /**
     * @private
     * @param {!Context} context
     * @return {Observable<[]>}
     */
    processStyleProps(context) {
        const propStyling = combineProps([
            {
                name: 'optionHeight',
                prop: context.getProperty('Option Height'),
                defaultValue: 50,
            },
            {
                name: 'primaryColor',
                prop: context.getProperty('Primary color'),
                defaultValue: Color.create(255, 255, 255, 0.9),
            },
            {
                name: 'secondaryColor',
                prop: context.getProperty('Secondary color'),
                defaultValue: Color.create(224, 224, 224, 0.9),
            },
            {
                name: 'fontColor',
                prop: context.getProperty('Font color'),
                defaultValue: Color.create(0, 0, 0, 1),
            },
            {
                name: 'fontFamily',
                prop: context.getProperty('Font family'),
                defaultValue: 'Roboto',
            },
        ]);

        return convertObservableToBehaviorSubject(propStyling, []).subject;
    }

    /**
     * @private
     * @param {number} index
     * @param {MenuModel} entryInstance
     * @return {Object}
     */
    entryToMenuOption(index, entryInstance) {
        const valuesEntry = {
            textProp: entryInstance.getTextProp(),
            iconProp: entryInstance.getIconProp(),
            navigationPath: entryInstance.getNavigationPath(),
            rank: entryInstance.getRank()?.toString()
        }
        for (const key in valuesEntry) {
            if (Object.hasOwnProperty.call(valuesEntry, key)) {
                const value = valuesEntry[key];
                if (!value) {
                    throw `Menu data local is invalid: value of ${key} is not exist`;
                }
            }
        }
        const optionObject = {
            id: index,
            childOptions: [],
            ...valuesEntry
        };

        return optionObject;
    }

    /**
    * Creating nested array from flat array
    * https://stackoverflow.com/questions/62144258/creating-nested-array-from-flat-array-data-structure
    * @private
    * @param {Array} list
    * @return {Array}
    */
    addChildrenToMenuOption(list) {
        const sortedList = list.sort((a, b) => compareVersions(a.rank, b.rank));

        /* 
          using rank as an index for each object in the array, e. g.:
          { 1.1: {rank: '1.1', id: 1, ..restObjFields }, 2: {rank: '2', id: 2, ..restObjFields }} 
        */
        const index = sortedList.reduce((prevValue, { rank, ...rest }) => ({
            ...prevValue,
            [rank]: { rank, ...rest }
        }), {});

        /* 
        root - an object at the level of which everything is considered,
        { rank } - destructurization to get only the rank from the entire object
        */
        const treeRoot = sortedList.reduce((root, { rank }) => {
            // takes the element by rank
            const node = index[rank];

            // a rank without the last digit (remove the last one .x, where x is a digit)
            const i = rank.split('.').slice(0, -1).join('.');

            // takes element from the index 
            const parent = index[i] || root;

            /* 
            push childOptions or an empty array if there are none of them)
            + the node that we found in the first step
            */
            parent.childOptions = [...(parent.childOptions || []), node];

            // return the element for the next iteration
            return root;
        }, { childOptions: [] });

        return treeRoot.childOptions;
    }
}

registerBrick('017cc23b797f4d717e70', Menu);
