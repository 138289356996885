import { Brick, registerBrick, Auth } from 'olympe';

export default class GetSSONames extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array} inputs
     * @param {function(ListDef)} setSSONames
     */
    update($, inputs, [setSSONames]) {
        setSSONames(Auth.getIDPNames());
    }
}

registerBrick('0184f0b6947a3583436a', GetSSONames);
