import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsArray } from '../../helper';

export default class Last extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {function(*)} setValue
     * @param {function(*)} setErrorFlow
     */
    update($, [source], [setValue, setErrorFlow]) {
        const logger = getLogger('Last');

        const { array, error } = getAsArray(source);

        if (!error) {
            const result = _.last(array);
            setValue(result);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017fe3e53d4537f8763c', Last);