import { UIBrick, registerBrick, UIContext } from 'olympe';
import {combineProps, createEvents} from '../_helpers';
import React from 'react';
import ReactDOM from 'react-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import TextField from '@mui/material/TextField';

export default class DatePicker extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!UIContext} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const selectedDateProp = context.getProperty('Selected Date');
        const pickTimeProp = context.getProperty('Pick Time');

        const dateFormatProp = context.getProperty('Date/time Format');
        // give a default value to the Date/time Format prop, depending on the Pick Time prop
        if(!dateFormatProp?.get()) {
            dateFormatProp.set(pickTimeProp.get() ? 'dd/MM/yyyy hh:mm' : 'dd/MM/yyyy');
        }

        const events = createEvents([
            {
                name: 'onChange',
                event: context.trigger.bind(context, 'On Change'),
                /**
                 * @param {Date} newDate
                 */
                before: (newDate) => {
                    if(newDate && !pickTimeProp.get()) {
                        // remove the current hour that is added to the date by default
                        newDate.setHours(0, 0, 0, 0);
                    }
                    selectedDateProp.set(newDate);
                }
            }
        ]);
        combineProps([
            {name: 'label', prop: context.getProperty('Label'), defaultValue: 'Date'},
            {name: 'variant', prop: context.getProperty('Variant'), defaultValue: 'standard'},
            {name: 'selectedDate', prop: selectedDateProp, defaultValue: new Date()},
            {name: 'pickTime', prop: pickTimeProp, defaultValue: false},
            {name: 'mobileDisplay', prop: context.getProperty('Mobile Display'), defaultValue: false},
            {name: 'dateFormat', prop: dateFormatProp} // default value is given above
        ]).subscribe(props => {
            if(props.variant !== 'standard' && props.variant !== 'outlined' && props.variant !== 'filled') {
                return;
            }

            let ComponentClass;
            if(props.mobileDisplay) {
                ComponentClass = props.pickTime ? MobileDateTimePicker : MobileDatePicker;
            } else {
                ComponentClass = props.pickTime ? DesktopDateTimePicker : DesktopDatePicker;
            }
            ReactDOM.render(
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ComponentClass
                        label={props.label}
                        value={props.selectedDate}
                        inputFormat={props.dateFormat}
                        renderInput={(params) => <TextField sx={{top: '10px'}} variant={props.variant} {...params} />}
                        {...events}
                    />
                </LocalizationProvider>,
                elementDom
            );
        });
    }
}

registerBrick('017cfead3e1f927c2474', DatePicker);
