import { combineLatest, Observable, BehaviorSubject } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { blue, green, purple, lightBlue, red, orange, common } from '@mui/material/colors';
import { format } from 'date-fns';
import {CloudObject, Query, StringModel} from "olympe";

export const utils = {};
utils.TAGS = {
    STRING: 'ff021000000000000011',
    NUMBER: 'ff021000000000000013',
    DATETIME: 'ff021000000000000014',
    BOOLEAN: 'ff021000000000000012',
    METADATA_REL: '0161ae97622bb21f335b',
    TYPE_REL: 'ff02200000000000000d',
    PROPERTY_REL: 'ff02200000000000000c',
    MODEL: 'ff022000000000000000',
    EXTEND: 'ff02200000000000000e',
    CONTAINS: 'ff02200000000000000f',
    ORIGIN_OF_RELATION_REL: 'ff02200000000000000a',
};



/**
 * Combine props together in an object, combine also the current language
 *
 * @param {![{name: string, prop: Property, defaultValue: *}]} props
 */
export function combineProps(props) {
    const names = props.map(prop => prop.name).concat('_language');
    const sources = props.map(prop => {
        let obs = prop.prop.observe();
        // TODO remove defaultValue once bug with number defaultValue is fixed
        if(prop.defaultValue !== undefined) {
            obs = obs.pipe(startWith(prop.defaultValue));
        }
        return obs;
    });
    return combineLatest(sources).pipe(map(values => {
        let i = 0;
        return values.reduce((acc, cur) => ({ ...acc, [names[i++]]: cur }), {});
    }));
}

/**
 * Create events usable by React component
 *
 * @param {![{name: string, event: Event, before: function(*, *)}]} events
 */
export function createEvents(events) {
    return events.reduce((acc, cur) => ({ ...acc, [cur.name]: (a, v) => { if(cur.before) cur.before(a, v); cur.event(); } }), {});
}

/**
 * Get an observable that retains its value (with method getValue() ) from an observable
 *
 * From https://stackoverflow.com/questions/46207592/rxjs-5-converting-an-observable-to-a-behaviorsubject
 *
 * @param {Observable} observable
 * @param {*} initValue
 * @return {{subject: BehaviorSubject, stopWatching: (function(): *)}}
 */
export function convertObservableToBehaviorSubject(observable, initValue) {
    const subject = new BehaviorSubject(initValue);
    const subscription = observable.subscribe(subject);
    return {
        subject,
        stopWatching: () => subscription.unsubscribe()
    };
}

export { getIcon } from './_icons.jsx';

/**
 * Filter an array of objects whose any properties contains a value
 * https://stackoverflow.com/questions/44312924/filter-array-of-objects-whose-any-properties-contains-a-value
 * @param {[*]} array
 * @param {string} filterText
 * @param {string} dateFormat
 * @return {*}
 */
export function filterByValue(array, filterText, dateFormat = 'YYYY-MM-DDTHH:mm:ss. sssZ') {
    return array.filter(item => Object.keys(item).some(key => {
        if (['id', 'tag', 'filterable'].includes(key)) {
            return false;
        }
        try {
            const searchValue = isValidDate(item[key]) ? format(item[key], dateFormat) : item[key];
            return searchValue?.toString().toLowerCase().includes(filterText.toLowerCase());
        } catch (e) {
            return false;
        }
    }));
}

/**
 * @param {*} date
 * @return {Boolean}
 */
export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}

export function debounce(func, wait, immediate) {
    let timeout;
    return (...args) => {
        let context = this;

        let later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/**
 * Get the column property type as defined at https://material-ui.com/components/data-grid/columns/#column-types
 *
 * @param {string} propTypeTag
 * @param {string} propName
 * @return {string}
 */
export function getMuiType(propTypeTag, propName) {
    const stringAndEnums = new Set(Query.from(StringModel)
        .followRecursively(CloudObject.extendedByRel, true)
        .executeFromCache().keys());
    switch (true) {
        case stringAndEnums.has(propTypeTag):
            return 'string';
        case propTypeTag === utils.TAGS.NUMBER:
            return 'number';
        case propTypeTag === utils.TAGS.DATETIME:
            return 'dateTime';
        case propTypeTag === utils.TAGS.BOOLEAN:
            return 'boolean';
        case propTypeTag === null:
            return 'string';
        default:
            console.warn(`Type "${propTypeTag}" from property "${propName}" is not supported.`);
            return 'string';
    }
}

export const COLORS = {
    primary: blue[700],
    secondary: purple[500],
    success: green[800],
    warning: orange[700],
    info: lightBlue[700],
    error: red[700],
    inherit: common.black,
    white: common.white,
};
