import {BrickContext, Brick, registerBrick, GlobalProperties, CloudObject, Config, Query, Relation, Predicate} from 'olympe';
import {getLogger} from '@olympeio/core';

export default class GetScreenByName extends Brick {

    /**
     * @protected
     * @param {!BrickContext} $
     * @param {string} screenName
     * @param {function(*)} setScreen
     */
    async update($, [screenName], [setScreen]) {
        const logger = getLogger('GetScreenByName');

        // 1. Ensure that the application tag exists
        const applicationTag = /** @type {string} */ (Config.getParameter('sc.app'));
        if (!CloudObject.exists(applicationTag)) {
            throw new Error('UI Application containing this call to GetScreenByName not found. May happen if calling through multiple reusable (non-anonymous) bricks.');
        }

        // 2. Retrieve the screen definition, from the application
        const screenFlow = Query.from(applicationTag)
            .follow(new Relation('01692a4af82e8984742c')) // RunnableDefinition -> runRel -> ScreenFlow
            .follow(new Relation('01692a4af82e8984742b')).andReturn() // Runnable -> definitionRel -> ScreenFlow definition
            .follow(CloudObject.modelRel).filter(Predicate.in('016c8f3b84720165aac5')) // ScreenFlow definition model
            .executeFromCache().getFirst();

        if (!screenFlow) {
            throw new Error('GetScreenByName brick did not find any screenFlow for the current application. Is it running in a UI Application');
        }

        // 3. Retrieve screens of the application with proper name
        const screens = await screenFlow // Runnable -> definitionRel -> ScreenFlow definition
            .follow(new Relation('01692a4af82e8984742d')).andReturn() // RunnableDefinition -> conditionalRunRel -> Screen instances (only)
            .follow(new Relation('01692a4af82e8984742b')) // Runnable -> definition -> Screen Definitions (only)
            .filter(Predicate.equals(CloudObject.nameProp, screenName)) // Only get the screens with the right name.
            .execute();

        // 4. Warnings if multiple screens or none, only in dev mode
        if (!$.get(GlobalProperties.PRODUCTION, true)) {
            if (screens.size() > 1) {
                // it might be that the dev has several screen with the same name in the UI App
                logger.warn(`Multiple screens with name "${screenName}" found.`);
            } else if (screens.size() === 0) {
                logger.warn(`Screen with name "${screenName}" might not exist.`);
            }
        }

        setScreen(screens.getFirst());
    }
}

registerBrick('017cb7c277b1485a9a64', GetScreenByName);
