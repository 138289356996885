import { QueryResult, ListDef } from 'olympe';

/**
 * @param {*} list
 * @return {boolean}
 */
export const checkList = (list) => {
    const isListDef = list instanceof ListDef;
    const isQueryResult = list instanceof QueryResult;
    return Array.isArray(list) || isListDef || isQueryResult;
};

/**
 * @param {Array<Sync> | ListDef | QueryResult} list
 * @param {function(*)} operation
 * @param {function()} execute
 */
export const performOperation = (list, operation, execute) => {
    if (Array.isArray(list) || list instanceof QueryResult) {
        list.forEach(operation);
        execute();
    } else {
        list.onReady(() => {
            list.forEachCurrentValue(operation);
            execute();
        })
    }
};
