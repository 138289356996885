import { UIBrick, registerBrick } from 'olympe';
import {combineProps, createEvents, getObjectFromJSON} from "./_helpers";
import ReactDOM from 'react-dom';
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';

export class MUICheckbox extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const events = createEvents([
            {
                name: 'onChange',
                event: context.trigger.bind(context, 'On Change'),
                before: (event) => {
                    context.getProperty('Checked').set(event.target.checked);
                }
            }
        ]);
        combineProps([
            {name: 'checked', prop: context.getProperty('Checked')},
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'disabled', prop: context.getProperty('Disabled')},
            {name: 'id', prop: context.getProperty('MUI DOM ID')},
            {name: 'muiSize', prop: context.getProperty('MUI Size')},
        ]).subscribe(props => {
            ReactDOM.render((<MUICheckbox.Component {...props} {...events}/>), elementDom);
        });
    }
}

registerBrick('01791d9caa082cf4e923', MUICheckbox);

/**
 * @param {{
 *     checked: boolean
 *     disabled: boolean
 *     muiColor: string
 *     muiSize: string
 *     styleJSON: string
 *     id: string
 *     onChange: function(*)
 *   }} props
 * @return {Element}
 */
MUICheckbox.Component = (props) => {
    const style = getObjectFromJSON(props.styleJSON);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Checkbox
                    checked={props.checked}
                    disabled={props.disabled}
                    color={props.muiColor}
                    size={props.muiSize}
                    style={style}
                    id={props.id}
                    onChange={props.onChange.bind(this)}/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};