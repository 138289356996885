import { Brick, registerBrick } from 'olympe';

export default class IsNotANumber extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {number} number
     * @param {function(boolean)} setIsNotANumber
     */
    update($, [number], [setIsNotANumber]) {
        setIsNotANumber(isNaN(number));
    }
}

registerBrick('0180d6fc9cc101bb4c7a', IsNotANumber);
