import { ActionBrick, registerBrick, File, ErrorFlow } from 'olympe';
import XLSX from 'xlsx';
import Fuse from 'fuse.js'
import {getLogger} from "@olympeio/core";

export default class CheckExcelHeaders extends ActionBrick {
    
    /**
     * @override
     */
    init($){
        this.logger = getLogger('CheckExcelHeaders');
    }

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {File} file
     * @param {Map} mapping
     * @param {string} sheetName
     * @param {function()} forwardEvent
     * @param {function(boolean)} setExactMatch
     * @param {function(boolean)} setFuzzyMatch
     * @param {function(Map)} setMapping
     * @param {function(*)} setErrorFlow
     */
    update(context, [file, mapping, sheetName], [forwardEvent, setExactMatch, setFuzzyMatch, setMapping, setErrorFlow]) {
        // Guards
        if(!(file instanceof File)){
            this.logger.error(`The file input is supposed to be a file`);
            return;
        }
        if(mapping && !(mapping instanceof Map)){
            this.logger.error(`The mapping is supposed to be a Map object`);
            return;
        }
        if(sheetName && typeof sheetName !== 'string'){
            this.logger.error(`The sheetName is supposed to be a string`);
            return;
        }


        file.getContentAsBinary(data => {
            const workbook = XLSX.read(data, {type:"array"});
            const sheet_name = (sheetName && sheetName.trim() !== '' ? sheetName : workbook.SheetNames[0]);
            const worksheet = workbook.Sheets[sheet_name];

            // Wrong inputs
            if(worksheet === undefined){
                setErrorFlow(ErrorFlow.create(`The specified sheet (${sheetName}) doesn't exist. (First sheet name : "${workbook.SheetNames[0]}")`, 2));
                return;
            }

            const excelData = XLSX.utils.sheet_to_json(worksheet, {header: 1});

            let exactMatch = true;
            for(let key of mapping.keys()){
                if(!excelData[0].includes(key)){
                    exactMatch = false;
                }
            }

            let fuzzyMatch = true;
            const fuse = new Fuse(excelData[0]);
            const newMapping = new Map();
            for(let [key, value] of mapping){
                const results = fuse.search(key);
                if(results.length === 0){
                    fuzzyMatch = false;
                } else {
                    newMapping.set(results[0].item, value);
                }
            }

            if(fuzzyMatch && !exactMatch){
                mapping = newMapping;
            }

            setExactMatch(exactMatch);
            setFuzzyMatch(fuzzyMatch);
            setMapping(mapping);
            forwardEvent();

        }, error => {
            setErrorFlow(ErrorFlow.create(error, 4));
        })
    }
}

registerBrick('017c2cb30f8e732acf54', CheckExcelHeaders);
