import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsJson } from '../../helper';

export default class Merge extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} first
     * @param {*} second
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [first, second], [setResult, setErrorFlow]) {
        const logger = getLogger('Merge');

        const firstObject = getAsJson(first);
        const secondObject = getAsJson(second);

        if (!firstObject.error && !secondObject.error) {
            const result = _.merge(firstObject.json, secondObject.json);
            setResult(result);
        } else {
            const code = firstObject.error?.code || secondObject.error.code;
            const message = [firstObject.error?.message, secondObject.error?.message].join('; ');
            logger.error(message);
            setErrorFlow(ErrorFlow.create(message, code));
        }
    }
}

registerBrick('017ff98ff9674ecaf405', Merge);