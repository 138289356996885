import { UIBrick, registerBrick, DBView } from 'olympe';
import { combineProps, createEvents, getObjectFromJSON } from "./_helpers";
import ReactDOM from 'react-dom';
import React from 'react';
import TextField from '@mui/material/TextField';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';
import { GetLocalisedText } from "../i18n/GetLocalisedText";
import { startWith } from 'rxjs/operators';

export class LocalisedMUITextField extends UIBrick {

    /**
     * This method is run when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const events = createEvents([
            {
                name: 'onChange',
                event: context.trigger.bind(context, 'On Change'),
                before: (event) => {
                    context.getProperty('Value').set(event.target.value);
                }
            }
        ]);
        combineProps([
            {name: 'isLocalised', prop: context.getProperty('Is Localised')},
            {name: 'value', prop: context.getProperty('Value')},
            {name: 'muiVariant', prop: context.getProperty('MUI Variant')},
            {name: 'type', prop: context.getProperty('Type')},
            {name: 'muiSize', prop: context.getProperty('MUI Size')},
            {name: 'muiRowsMax', prop: context.getProperty('MUI Rows Max'), defaultValue: 3},
            {name: 'muiRows', prop: context.getProperty('MUI Rows'), defaultValue: 3},
            {name: 'required', prop: context.getProperty('Required')},
            {name: 'placeholder', prop: context.getProperty('Placeholder')},
            {name: 'name', prop: context.getProperty('Name')},
            {name: 'muiMultiline', prop: context.getProperty('MUI Multiline')},
            {name: 'id', prop: context.getProperty('MUI DOM ID')},
            {name: 'muiAutoFocus', prop: context.getProperty('MUI Auto Focus')},
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'disabled', prop: context.getProperty('Disabled')},
            {name: 'muiError', prop: context.getProperty('MUI Error')},
            {name: 'muiFullWidth', prop: context.getProperty('MUI Full Width')},
            {name: 'muiLabel', prop: context.getProperty('MUI Label')},
            {name: 'muiHelperText', prop: context.getProperty('MUI Helper Text')},
        ]).subscribe(props => {
            // Guards
            if(props.muiVariant !== 'standard' && props.muiVariant !== 'outlined' && props.muiVariant !== 'filled')
                return;

            ReactDOM.render((<LocalisedMUITextField.Component {...props} {...events}/>), elementDom);
        });
    }
}

registerBrick('0178e91eda3071baa31e', LocalisedMUITextField);

/**
 * @param {{
 *     isLocalised: boolean
 *     value: string
 *     muiVariant: string
 *     type: string
 *     muiSize: string
 *     muiRowsMax: number
 *     muiRows: number
 *     required: boolean
 *     placeholder: string
 *     name: string
 *     muiMultiline: boolean
 *     id: string
 *     muiAutoFocus: boolean
 *     styleJSON: string
 *     muiColor: string
 *     disabled: boolean
 *     muiError: boolean
 *     muiFullWidth: boolean
 *     muiLabel: string
 *     muiHelperText: string
 *     onChange: function(*)
 *   }} props
 * @return {Element}
 */
LocalisedMUITextField.Component = (props) => {
    const placeholder = props.isLocalised ? GetLocalisedText.getTranslation(props.placeholder) : props.placeholder;
    const label = props.isLocalised ? GetLocalisedText.getTranslation(props.muiLabel) : props.muiLabel;
    const helperText = props.isLocalised ? GetLocalisedText.getTranslation(props.muiHelperText) : props.muiHelperText;
    let style = getObjectFromJSON(props.styleJSON);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <TextField
                    value={props.value}
                    variant={props.muiVariant}
                    type={props.type}
                    size={props.muiSize}
                    maxRows={props.muiRowsMax}
                    rows={props.muiRows}
                    required={props.required}
                    placeholder={placeholder}
                    name={props.name}
                    multiline={props.muiMultiline}
                    id={props.id}
                    autoFocus={props.muiAutoFocus}
                    style={style}
                    color={props.muiColor}
                    disabled={props.disabled}
                    error={props.muiError}
                    fullWidth={props.muiFullWidth}
                    label={label}
                    helperText={helperText}
                    onChange={props.onChange.bind(this)}/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
