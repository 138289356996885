
/**
 * Copyright 2021 Olympe S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionBrick, registerBrick, ErrorFlow, File as OFile, CloudObject, Transaction } from 'olympe';
import { dataUrlToBinary } from '@olympeio/core'

export default class CreateFileFromDataURL extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string=} fileName
     * @param {string} dataURL
     * @param {function()} forwardEvent
     * @param {function(File)} setFile
     * @param {function(ErrorFlow)} setErrorFlow
     */
    update(context, [fileName, dataURL], [forwardEvent, setFile, setErrorFlow]) {
        const transaction = new Transaction(true);
        const fileTag = transaction.create(OFile);
        OFile.setContent(
            transaction,
            fileTag,
            fileName || 'new_File_from_CreateFileFromDataURL_brick',
            dataUrlToBinary(dataURL)
        );
        transaction.execute().then(() => {
            setFile(CloudObject.get(fileTag));
            forwardEvent();
        }).catch((message) => {
            setErrorFlow(ErrorFlow.create(message, 1));
        });
    }
}

registerBrick('017bc03095d2ac74840f', CreateFileFromDataURL);
