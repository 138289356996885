import { Sync, registerSync, RelationDirection } from 'olympe';

/**
 * @extends {Sync}
 */
export class Menu extends Sync {

    /**
     * @return {string}
     */
    getTextProp() {
        return this.db.getProperty(this, Menu.textPropProp);
    }

    /**
    * @return {string}
    */
    getIconProp() {
        return this.db.getProperty(this, Menu.iconPropProp);
    }

    /**
    * @return {string}
    */
    getNavigationPath() {
        return this.db.getProperty(this, Menu.navigationPathProp);
    }

    /**
    *
    * @return {Menu[]}
    */
    getChildren() {
        return this.db.getRelated(this.tag, Menu.childrenRel).map(Sync.getInstance);
    }

    /**
    * @return {number}
    */
    getRank() {
        return this.db.getProperty(this, Menu.rankProp);
    }
};

// Hardcoded tags
Menu.entry = registerSync('017cc5aa7bb7f0139031', Menu);
Menu.textPropProp = Menu.entry.addProperty('017cc5aab01236b53fdc');
Menu.iconPropProp = Menu.entry.addProperty('017cc5aab711c75e1db9');
Menu.navigationPathProp = Menu.entry.addProperty('017cc5aabfdaf5e342eb');
Menu.childrenRel = Menu.entry.addRelation('017ccadf274178cc294d');
Menu.rankProp = Menu.entry.addProperty('017d56b9357b8c4ac355');
