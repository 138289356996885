import { ActionBrick, registerBrick, ErrorFlow, File as OFile, Transaction, CloudObject } from 'olympe';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import expressions from "angular-expressions";
import assign from "lodash/assign";

export default class PublipostageWordDocument extends ActionBrick {
    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {OFile} document
     * @param {*} data
     * @param {string} outputFileName
     * @param {function()} forwardEvent
     * @param {function(*)} setOutputFile
     * @param {function(*)} setErrorFlow
     */
    update($, [document, data, outputFileName], [forwardEvent, setOutputFile, setErrorFlow]) {
        document.getContentAsBinary((content) => {
            const zip = new PizZip(content);
            const options = {
                paragraphLoop: true,
                linebreaks: true,
                parser: this.angularParser
            };
            const doc = new Docxtemplater(zip, options);
            doc.render(data);
            const blob = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            blob.arrayBuffer().then(arrayBuffer => {
                const t = new Transaction(false);
                const outputFileTag = t.create(OFile);
                OFile.setContent(t, outputFileTag, outputFileName ? outputFileName : 'output.docx', arrayBuffer, blob.mimeType);
                t.execute().then(() => {
                    const file = CloudObject.get(outputFileTag);
                    setOutputFile(file);
                    forwardEvent();
                }).catch(error => {
                    setErrorFlow(ErrorFlow.create('Error while executing the transaction: ' + error, 1));
                })
            }).catch(error => {
                setErrorFlow(ErrorFlow.create('Error while loading the file array buffer: ' + error, 1));
            })
        }, (error) => {
            setErrorFlow(ErrorFlow.create('Error while getting the content binary of the file: ' + error, 1));
        });
    }

    angularParser(tag) {
        tag = tag
            .replace(/^\.$/, "this")
            .replace(/(’|‘)/g, "'")
            .replace(/(“|”)/g, '"');
        const expr = expressions.compile(tag);
        return {
            get: function (scope, context) {
                let obj = {};
                const scopeList = context.scopeList;
                const num = context.num;
                for (let i = 0, len = num + 1; i < len; i++) {
                    obj = assign(obj, scopeList[i]);
                }
                return expr(scope, obj);
            },
        };
    };
}

registerBrick('01818531d72ff8bdb0ac', PublipostageWordDocument);
