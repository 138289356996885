import { UIBrick, registerBrick } from 'olympe';
import {combineProps, getObjectFromJSON} from "./_helpers";
import ReactDOM from "react-dom";
import React from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';

export class MUILinearProgress extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        combineProps([
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'value', prop: context.getProperty('Value'), defaultValue: 0},
            {name: 'bufferValue', prop: context.getProperty('Buffer Value'), defaultValue: 0},
            {name: 'minValue', prop: context.getProperty('Min Value'), defaultValue: 0},
            {name: 'maxValue', prop: context.getProperty('Max Value'), defaultValue: 100},
            {name: 'muiVariant', prop: context.getProperty('MUI Variant')},
        ]).subscribe(props => {
            ReactDOM.render((<MUILinearProgress.Component {...props}/>), elementDom);
        });
    }
}

registerBrick('01791dafb86eedb2840c', MUILinearProgress);

/**
 * @param {{
 *   styleJSON: string
 *   muiColor: string
 *   value: number
 *   bufferValue: number
 *   minValue: number
 *   maxValue: number
 *   muiVariant: string
 *   }} props
 * @return {Element}
 */
MUILinearProgress.Component = (props) => {
    const style = getObjectFromJSON(props.styleJSON);
    const step = 100 / (props.maxValue - props.minValue);
    const value = (props.value - props.minValue) * step;
    const bufferValue = (props.bufferValue - props.minValue) * step;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LinearProgress
                    color={props.muiColor}
                    style={style}
                    value={value}
                    valueBuffer={bufferValue}
                    variant={props.muiVariant}/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
