import { ActionBrick, registerBrick } from 'olympe';
import { updateLanguage } from "./_language";

export class ChangeLanguage extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} language
     * @param {function()} forwardEvent
     */
    update(context, [language], [forwardEvent]) {
        updateLanguage(language);
        forwardEvent();
    }
}

registerBrick('0178e920f2f5da00a984', ChangeLanguage);
