class CacheManager {
    /**
     * @public
     * @return {CacheManager}
     */
    constructor() {
        // Check if an instance already exists (Lazy loading)
        if (!CacheManager.instance) {
            /**
             * @type {Map<String, Object>}
             */
            this.cacheMap = new Map();

            /**
             * @type {Map<string, Map<number,function()>>}
             */
            this.cacheCallbacksMap = new Map();

            /**
             * Counter to provide IDs
             *
             * @type {number}
             */
            this.counter = 0;

            /**
             * The CacheManager Singleton instance
             * @private
             * @type {CacheManager}
             */
            CacheManager.instance = this;
        }
        return CacheManager.instance;
    }

    /**
     * @public
     * @return {Object}
     */
    getByKey(key) {
        return this.cacheMap.get(key);
    }

    /**
     * @public
     * @return {Array}
     */
    getKeys() {
        return [...this.cacheMap.keys()];
    }

    /**
     * @public
     */
    set(key, value) {
        this.cacheMap.set(key, value);
        // notify brick GetFromCache
        this.notifyValueUpdate(key, value);
    }

    /**
     * @public
     */
    removeByKey(key) {
        const isRemoved = this.cacheMap.delete(key);
        if (isRemoved) {
            // notify brick GetFromCache
            this.notifyValueUpdate(key, null);
        }
    }

    /**
     * @public
     */
    clear() {
        const keys = this.getKeys();
        this.cacheMap.clear();
        for (const key of keys) {
            // notify brick GetFromCache
            this.notifyValueUpdate(key, null);
        }
    }

    /**
    * Notify that a value was updated to the registered callbacks
    *
    * @param {string} key
    * @param {?string} value
    */
    notifyValueUpdate(key, value) {
        const cacheCallbacksMap = this.cacheCallbacksMap;
        const callbacksForKey = cacheCallbacksMap && cacheCallbacksMap.get(key);
        if (callbacksForKey !== undefined) {
            for (const cb of callbacksForKey.values()) {
                cb(value);
            }
        }
    }

    /**
     * Register a callback that will be fired when a new value is stored in the map
     *
     * @param {string} forKey
     * @param {function(string)} callback
     * @return {number}
     */
    registerCallback(forKey, callback) {
        let callbacksForKey;
        const id = this.counter++;
        if (this.cacheCallbacksMap.has(forKey)) {
            callbacksForKey = this.cacheCallbacksMap.get(forKey);
        }
        else {
            callbacksForKey = new Map();
            this.cacheCallbacksMap.set(forKey, callbacksForKey);
        }
        callbacksForKey.set(id, callback);
        return id;
    }


    /**
     * Unregister a given callback
     *
     * @param {string} forKey
     * @param {number} id
     */
    unregisterCallback(forKey, id) {
        const cacheCallbacksMap = this.cacheCallbacksMap;
        const callbacksForKey = cacheCallbacksMap && cacheCallbacksMap.get(forKey);
        if (callbacksForKey !== undefined) {
            callbacksForKey.delete(id);
        }
    }

}

let instance = null;
export const cacheManager = () => {
    if (instance === null) {
        // Instantiate the Singleton
        instance = new CacheManager();
    }
    return instance;
}
