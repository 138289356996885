import {VisualBrick} from 'olympe';
import {createApp} from 'vue';

export default class VueBrick extends VisualBrick {
    /**
     * Creates a vue.js widget (app) and mounts it to the rendered element, therefore biding the element
     * to the vue.js context
     * @param parent
     * @param element
     * @return {function(...[*]=)}
     */
    updateParent(parent, element) {
        const clearingParentFunction = super.updateParent(parent, element);
        const vueBrick = this.getVueComponent();
        const elementId = element.id;
        let vueApp;
        if (!vueBrick) {
            element.innerHTML = '<p>VueBrick: <code>getVueComponent()</code> not implemented.</p>';
        } else {
                vueApp = createApp(vueBrick, {
                    context: element.context,
                    values: element.values,
                });
                vueApp.mount(element);
        }
        return () => {
            clearingParentFunction();
            if (vueApp) {
                vueApp.unmount();
                document.getElementById(elementId)?.remove();
            }
        }
    }

    /**
     * @override
     * returns an empty div that is bound (mounted) by the vue.js context
     */
    render($, properties) {
        const element = document.createElement('div');
        element.id = Date.now().toString();
        element.context = $;
        element.values = properties;
        return element;
    }

    /**
     * Gets the .vue file object (must be overridden by child)
     * @return {{}}
     */
    getVueComponent() {
        return undefined;
    }
}