import { BehaviorSubject } from 'rxjs';
import i18next from "i18next";

const language = new BehaviorSubject(undefined);

export function getLanguage() {
    return language;
}

export function updateLanguage(value) {
    i18next.changeLanguage(value);
    language.next(value);
}
