import { registerBrick, GlobalProperties } from 'olympe';
import { ReactBrick, useProperty, cssToSxProps, ifNotTransparent, ifNotNull, useMUITheme, jsonToSxProps } from '@olympeio/core';

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { combineLatest } from 'rxjs';

import { Box, Typography, SwipeableDrawer } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

const applyStylePaperFlexDirection = (anchor) => {
    if (anchor === 'bottom') {
        return {
            flexDirection: 'column-reverse',
        };
    }
    if (anchor === 'right') {
        return {
            flexDirection: 'row-reverse',
        };
    }
};
export default class Drawer extends ReactBrick {
    /**
     * @override
     */
    setupExecution($) {
        return combineLatest([$.observe('Expand location', true), $.observe('Drawer Renderer'), $.observe('Hidden', false)]);
    }
    /**
     * @override
     * @param {!Element} parent
     * @param {!Element} element
     * @return {function()}
     */
    updateParent(parent, element) {
        parent.style.pointerEvents = 'none';
        ReactDOM.render(element, parent);
        return () => ReactDOM.unmountComponentAtNode(parent);
    }
    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const [expandLocation, DrawerRenderer, hidden] = props.values;
            const open = useProperty($, 'open') ?? false;
            const width = useProperty($, 'Width');
            const height = useProperty($, 'Height');
            const anchor = expandLocation?.toLowerCase();

            const borderRadius = useProperty($, 'Border Radius');
            const borderColor = useProperty($, 'Border Color');
            const borderWidth = useProperty($, 'Border Width');
            const defaultColor = useProperty($, 'Default Color');
            const cssProperty = useProperty($, 'Css Property');
            const muiSxJson = useProperty($, 'MUI sx [json]');

            if (hidden) {
                return null;
            }

            if ($.get(GlobalProperties.EDITION, true)) {
                if (DrawerRenderer) {
                    return <Box sx={{ backgroundColor: 'transparent', width, height }}></Box>;
                }
                return (
                    <Box sx={{ backgroundColor: 'lightgrey', width: 1, height: 1, overflow: 'hidden' }}>
                        <Typography sx={{ color: 'black', padding: 1 }}>
                            <b>Drawer</b>
                            <br />
                            The following properties have to be defined for the component to render:
                            <br />- <code>Drawer Renderer</code>
                            <br />- You can then change the property <code>open</code> to display or not the drawer
                        </Typography>
                    </Box>
                );
            }

            let style = {
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    border: 'none',
                    // Anchor bottom case
                    ...applyStylePaperFlexDirection(anchor),

                    // Border and background
                    ...ifNotTransparent('borderColor', borderColor),
                    ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                    ...ifNotNull('borderWidth', `${borderWidth}px`, borderWidth),
                    ...ifNotTransparent('borderStyle', 'solid', borderColor),
                    ...ifNotTransparent('backgroundColor', defaultColor),
                    ...ifNotNull('boxSizing', 'border-box', borderWidth),

                    // Additional
                    ...cssToSxProps(cssProperty),
                    ...jsonToSxProps(muiSxJson),
                },
            };

            if (anchor === 'left' || anchor === 'right') {
                style = {
                    ...style,
                    '& .MuiDrawer-paper': {
                        ...style['& .MuiDrawer-paper'],
                        width,
                        height: '100%',
                    },
                };
            } else {
                style = {
                    ...style,
                    '& .MuiDrawer-paper': {
                        ...style['& .MuiDrawer-paper'],
                        height,
                        width: '100%',
                    },
                };
            }
            const theme = useMUITheme($);
            return (
                <ThemeProvider theme={theme}>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={open}
                        sx={style}
                        ModalProps={{ keepMounted: true }}
                        SwipeAreaProps={{ style: { pointerEvents: 'auto' } }}
                        onClose={() => {
                            $.trigger('On Close Drawer');
                        }}
                        onOpen={() => {
                            $.trigger('On Open Drawer');
                        }}
                    >
                        <Drawer.Content $={$} renderer={DrawerRenderer} anchor={anchor} borderWidth={borderWidth} />
                    </SwipeableDrawer>
                </ThemeProvider>
            );
        };
    }
}

Drawer.Content = ({ $, renderer, anchor, borderWidth }) => {
    const ref = useRef();
    useEffect(() => {
        const el = ref.current;
        if (!el) {
            return;
        }
        const renderer$ = $.runner(renderer).setParentElement(el);
        if (anchor === 'top' || anchor === 'bottom') {
            renderer$.set('Width', window.innerWidth - borderWidth * 2);
            renderer$.repeat('Height', $.observe('Renderer Height'));
        } else {
            renderer$.set('Height', window.innerHeight - borderWidth * 2);
            renderer$.repeat('Width', $.observe('Renderer Width'));
        }

        return () => {
            renderer$?.destroy();
        };
    });
    return (
        <Box
            sx={{
                display: 'contents',
            }}
            ref={ref}
        />
    );
};

registerBrick('01838d95a9b2b8292c85', Drawer);
