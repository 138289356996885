import { UIBrick, registerBrick } from 'olympe';
import { combineLatest } from 'rxjs';

export default class IFrame extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * 
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {

        const iframe = document.createElement('iframe');
        combineLatest(
            context.observe('src'),
            context.observe('Width'),
            context.observe('Height'),
            context.observe('Default Color'),
            context.observe('Border Width'),
            context.observe('Border Color'),
            context.observe('Border Radius'),
        ).subscribe(([src, width, height, bgColor, borderWidth, borderColor, borderRadius]) => {
            iframe.width = width;
            iframe.height = height;
            iframe.src = src;
            iframe.style.border = '0';

            const maxAllowedBorder = Math.min(width/2, height/2, borderWidth);

            elementDom.style.boxSizing = 'border-box';
            elementDom.style.backgroundColor = bgColor;
            elementDom.style.borderWidth = `${maxAllowedBorder}px`;
            elementDom.style.borderColor = borderColor;
            elementDom.style.borderRadius = `${borderRadius}px`;

        });
        elementDom.appendChild(iframe);
    }
}

registerBrick('017c9db39275a276da5f', IFrame);
