import { Brick, registerBrick, ErrorFlow } from 'olympe';
import * as jsonpatch from 'fast-json-patch';

export default class RemoveElementFromJSON extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {string} source
     * @param {string} path
     * @param {function(string)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [source, path], [setResult, setErrorFlow]) {
        // Guards
        if (typeof source !== 'string') {
            throw 'Provided source is not a string';
        }
        if (typeof path !== 'string') {
            throw 'Provided path is not a string';
        }

        const handleErrors = (message, code) => {
            setErrorFlow(ErrorFlow.create(message, code));
            setResult(null);
        };

        const escapeRegExp = stringToReplace => {
            return stringToReplace.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };

        const replaceAll = (str, find, replace) => {
            return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
        };

        const unifiedPath = '/' + replaceAll(path, '.', '/').replace(']', '');
        const sourceObject = JSON.parse(source);

        if (path.includes('[')) {
            const openBracketIndex = unifiedPath.indexOf('[');
            const pathToArray = unifiedPath.substring(0, openBracketIndex - 1);
            const arrayToRemoveFrom = jsonpatch.getValueByPointer(sourceObject, pathToArray);

            for (const index in arrayToRemoveFrom) {
                const pathToDelete = unifiedPath.replace('[', index + '/');
                const operation = { op: 'remove', path: pathToDelete };
                jsonpatch.applyOperation(sourceObject, operation).newDocument;
            }
        } else {
            const isPathExists = jsonpatch.getValueByPointer(sourceObject, unifiedPath);
            if (isPathExists) {
                const operation = { op: 'remove', path: unifiedPath };
                jsonpatch.applyOperation(sourceObject, operation).newDocument;
            }
        }

        const result = JSON.stringify(sourceObject);
        // delete all whitespaces in strings
        if (result.replace(/ /g, '') === source.replace(/ /g, '')) {
            handleErrors('No such path found', 1);
        } else {
            setResult(result);
        }
    }
}

registerBrick('017ebf46227433d9ed44', RemoveElementFromJSON);
