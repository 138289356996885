(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("olympe"));
	else if(typeof define === 'function' && define.amd)
		define(["olympe"], factory);
	else if(typeof exports === 'object')
		exports["@olympeio-extensions/qrcode-zxing"] = factory(require("olympe"));
	else
		root["@olympeio-extensions/qrcode-zxing"] = factory(root["olympe"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_olympe__) => {
return 