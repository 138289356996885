import { ActionBrick, registerBrick, Context, File } from 'olympe';

/**
 * @param {!ArrayBuffer} value
 * @return {string}
 */
export function toBase64(value) {
    let binStr = '';
    // Byte after byte, recreate the binary string.
    const bytes = new Uint8Array(value);
    for (let i = 0, l = bytes.length; i < l; i++) {
        binStr += String.fromCodePoint(bytes[i]);
    }
    return window.btoa(binStr);
}

/**
 WARNING: this implementation works only on the browser

 ## Description
 Get the file content as an array buffer.
 ## Inputs
 | Name | Type | Description |
 | --- | :---: | --- |
 | File | File |  |
 ## Outputs
 | Name | Type | Description |
 | --- | :---: | --- |
 | Content | ArrayBuffer |  |
**/
export default class GetRawFileContent extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {File} file
     * @param {function()} forwardEvent
     * @param {function(ArrayBuffer)} setContent
     */
    update(context, [file], [forwardEvent, setContent]) {

        if(file.getContentAsBinary === undefined) {
            // warning for legacy usage of the brick, where string/ArrayBuffer was provided as input
            console.warn('GetRawFileContent:', file, 'is not a valid file');
            return;
        }

        const onFailure = (message) => {
            console.error('GetRawFileContent: could not retrieve content of', file, '\n', message);
        };

        const mimeType = file.getProperty(File.mimeTypeProp) || '';
        const asText = (mimeType.startsWith('text') || mimeType === 'application/json')
        // TODO the ArrayBuffer is returned in this implementation, to correspond with the legacy usage of GetRawFileContent
        file.getContentAsBinary((content) => {
            setContent(content);
            forwardEvent();
        }, onFailure);
    }
}

registerBrick('0177fe2096fc87616816', GetRawFileContent);
