import { ActionBrick, registerBrick, BrickContext } from 'olympe';
import ReactDOM from 'react-dom';
import { getBackdropKeys, getBackdropContainer, getRootContext } from './backdrop-utils';

export default class HideGlobalBackdrop extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {string} key
     * @param {function()} forwardEvent
     */
    update($, [key], [forwardEvent]) {
        const rootContext = getRootContext($);
        const [showKey, labelKey] = getBackdropKeys(key);

        if (rootContext.get(showKey)) {
            rootContext.set(showKey, false);
            rootContext.remove(labelKey);
            this.removeGlobalBackdrop(key);
        }
        forwardEvent();
    }

    /**
     * @param {string} key
     */
    removeGlobalBackdrop(key) {
        const container = getBackdropContainer(key);
        if (!container.children.length) {
            return;
        }
        ReactDOM.unmountComponentAtNode(container);
    }
}

registerBrick('01881e6267470d7939bd', HideGlobalBackdrop);
