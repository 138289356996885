import { Brick, registerBrick } from 'olympe';
import {combineLatest, of} from 'rxjs';

export default class SVGLine extends Brick {

    setupExecution($) {
        let line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        return combineLatest([of(line)].concat(this.getInputs().map((i) => $.observe(i))));
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {SVGElement} line
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @param {Color} fill
     * @param {number} strokeWidth
     * @param {Color} stroke
     * @param {function(SVGElement)} setLine
     */
    update($, [line, x1, y1, x2, y2, strokeWidth, stroke], [setLine]) {
        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);
        line.setAttribute('stroke-width', strokeWidth);
        line.setAttribute('stroke', "rgb("+stroke.getRed()+","+stroke.getGreen()+","+stroke.getBlue()+")");

        setLine(line);
    }
}

registerBrick('01860db6207b0add1cd7', SVGLine);
