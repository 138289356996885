import { ActionBrick, registerBrick, Brick, BrickContext } from 'olympe';

export default class While extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {Brick} condition
     * @param {Brick} statement
     * @param {function()} forwardEvent
     */
    update($, [condition, statement], [forwardEvent]) {
        // Checks
        if(!(condition instanceof Brick)) {
            throw new Error('Condition lambda function is not correctly defined.');
        }
        if(!(statement instanceof Brick)) {
            throw new Error('Statement lambda function is not correctly defined.');
        }

        const runStatement = async (index) => {
            const [startInput, indexInput] = statement.getInputs();
            const [endOutput] = statement.getOutputs();

            const statement$ = $.runner(statement)
                .set(indexInput, index)
                .trigger(startInput);
            await statement$.waitFor(endOutput);
            statement$.destroy();
        }

        const runCondition = async (index) => {
            const [startInput, indexInput] = condition.getInputs();
            const [endOutput, resultOutput] = condition.getOutputs();

            const lambdaContext = $.runner(condition);
            lambdaContext.set(indexInput, index);
            lambdaContext.trigger(startInput);
            await lambdaContext.waitFor(endOutput);
            const result = lambdaContext.get(resultOutput);
            lambdaContext.destroy();
            return result;
        }

        // run loop within an async function
        const runLoop = async () => {
            let index = 0;
            while (await runCondition(index)) {
                await runStatement(index);
                index++;
            }
        }

        runLoop().then(forwardEvent);
    }
}

registerBrick('017ec55183131e6e2d0a', While);
