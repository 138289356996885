import { ActionBrick, registerBrick } from 'olympe';

export default class DQ_JDRCSVToJSON extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {string} csv
     * @param {function()} forwardEvent
     * @param {function(*)} setResult
     */
    update($, [csv], [forwardEvent, setResult]) {
        // This assumes that the separator is never found within quotes
        const sep = ";"
        var lines = csv.split("\r\n");

        var result = [];
        var headers=lines[0].split(sep);
      
        for (var i=1;i<lines.length;i++){
            if (lines[i].length === 0) // skip empty lines
                continue

            var obj = {};
            var currentline=lines[i].split(sep);

            for (var j=0;j<headers.length;j++){
                // Remove "
                const value = currentline[j].replace(/\"/g, "");
                obj[headers[j]] = value;
            }

            result.push(obj);
        }
        setResult(result);
        forwardEvent();
    }
}

registerBrick('0185109a806f0837654f', DQ_JDRCSVToJSON);
