import { UIBrick, registerBrick } from 'olympe';
import { combineProps, getObjectFromJSON } from "./_helpers";
import ReactDOM from 'react-dom';
import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';
import { GetLocalisedText } from "../i18n/GetLocalisedText";

export class LocalisedMUITypography extends UIBrick {

    /**
     * This method is run when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const divDisplay = elementDom.style.display;
        const divAlignItems = elementDom.style.alignItems;
        combineProps([
            {name: 'value', prop: context.getProperty('Value')},
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'isLocalised', prop: context.getProperty('Is Localised')},
            {name: 'muiVariant', prop: context.getProperty('MUI Variant')},
            {name: 'id', prop: context.getProperty('MUI DOM ID')},
            {name: 'muiAlign', prop: context.getProperty('MUI Align')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'muiDisplay', prop: context.getProperty('MUI Display')},
            {name: 'muiNoWrap', prop: context.getProperty('MUI No Wrap')},
            {name: 'centerVertically', prop: context.getProperty('Center Vertically')}
        ]).subscribe(props => {
            elementDom.style.display = props.centerVertically ? 'flex' : divDisplay;
            elementDom.style.alignItems = props.centerVertically ? 'center' : divAlignItems;
            ReactDOM.render((<LocalisedMUITypography.Component {...props}/>), elementDom);
        });
    }
}

registerBrick('0178e91e44001699a209', LocalisedMUITypography);

/**
 * @param {{
 *     value: string
 *     styleJSON: string
 *     isLocalised: boolean
 *     muiVariant: string
 *     id: string
 *     muiAlign: string
 *     muiColor: string
 *     muiDisplay: string
 *     muiNoWrap: boolean
 *     centerVertically: boolean
 *   }} props
 * @return {Element}
 */
LocalisedMUITypography.Component = (props) => {
    const value = props.isLocalised ? GetLocalisedText.getTranslation(props.value) : props.value;
    let style = getObjectFromJSON(props.styleJSON);
    if(props.centerVertically)
        style.width = 'inherit';
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Typography
                    style={style}
                    variant={props.muiVariant}
                    id={props.id}
                    align={props.muiAlign}
                    color={props.muiColor}
                    display={props.muiDisplay}
                    noWrap={props.muiNoWrap}>{value}</Typography>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
