import { Brick, registerBrick } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';

export default class InRange extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {number} number
     * @param {number} start
     * @param {number} end
     * @param {function(boolean)} setResult
     */
    update($, [number, start, end], [setResult]) {
        const logger = getLogger('In Range');

        // Guards
        if (typeof number !== 'number') {
            logger.error('Provided number input is not a number');
            return;
        }
        if (typeof start !== 'number') {
            logger.error('Provided start input is not a number');
            return;
        }
        if (typeof end !== 'number') {
            logger.error('Provided end input is not a number');
            return;
        }
        setResult(_.inRange(number, start, end));
    }
}

registerBrick('017ff8d869cae5a74cfc', InRange);