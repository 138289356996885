import { ActionBrick, registerBrick, ListDef, Context, instanceToTag, InstanceTag, Transaction, QueryResult } from 'olympe';
import { getLogger } from '@olympeio/core';
import { checkList, performOperation } from './helper';

export default class CreateRelationOnListOfObjects extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {Array<InstanceTag> | ListDef | QueryResult} list
     * @param {InstanceTag} relation
     * @param {InstanceTag} destination
     * @param {boolean} invertedRelation
     * @param {function()} forwardEvent
     * @param {function(Array<InstanceTag> | ListDef)} setList
     */
    update(context, [list, relation, destination, invertedRelation], [forwardEvent, setList]) {
        const logger = getLogger('Create Relation On List');
        const isCorrectList = checkList(list);
        if (!isCorrectList) {
            logger.error('Input provided is not a ListDef, an Array or a QueryResult');
            return;
        }

        if (instanceToTag(relation) === '') {
            logger.error(`Relation provided is not valid: ${relation}`);
            return;
        }

        if (instanceToTag(destination) === '') {
            logger.error(`Destination Object ${destination} is not an Olympe object or Olympe Object tag`);
            return;
        }

        if (typeof invertedRelation !== 'boolean') {
            logger.error(`Input Inverted Relation is not a boolean`);
            return;
        }

        const transaction = Transaction.from(context);
        const createRelation = (objectIn) => {
            if (instanceToTag(objectIn) !== '') {
                if(invertedRelation) {
                    transaction.createRelation(relation, destination, objectIn);
                } else {
                    transaction.createRelation(relation, objectIn, destination);
                }
            } else {
                logger.error('Object ' + objectIn +  ' is not an Olympe object or Olympe Object tag');
            }
        }

        const execute = () => {
            Transaction.process(context, transaction).then(() => {
                setList(list);
                forwardEvent();
            }).catch((message) => {
                logger.error(`Could not execute transaction. ${message}`);
            });
        };

        performOperation(list, createRelation, execute);
    }
}

registerBrick('017d5bb8b816086a20bb', CreateRelationOnListOfObjects);
