import { Brick, registerBrick } from 'olympe';
import { getLogger } from "@olympeio/core";
import {combineLatest} from "rxjs";
import {map} from "rxjs/operators";

export default class FormatString8 extends Brick {

    /**
     * @override
     */
    setupExecution($) {
        const inputs = this.getInputs();
        return combineLatest(...inputs.map((i) => $.observe(i, false)))
            .pipe(map(() => {
                if($.get(inputs[0]) === null || $.get(inputs[0]) === undefined){
                    return null;
                } else {
                    return inputs.map((i) => $.get(i));
                }
            }));
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array<string>} inputs
     * @param {function(string)} setResult
     */
    update($, inputs, [setResult]) {
        const template = inputs[0];
        if (typeof template === 'string') {
            setResult(this.replacePart([template], 0, inputs));
        } else {
            getLogger('FormatString8').error('Template is not a string')
        }
    }

    /**
     *
     * @param arr
     * @param i
     * @param inputs
     * @return {*}
     */
    replacePart(arr, i, inputs){
        if(i >= inputs.length){
            return arr.join('');
        } else {
            return arr
                .map(part => {
                    return this.replacePart(part.split(`\$${i+1}`), i+1, inputs)
                })
                .join(inputs[i]?.toString() || '');
        }
    }
}

registerBrick('0183fad23ca3d416f6e2', FormatString8);