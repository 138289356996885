/**
 * Copyright 2021 Olympe S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Import Olympe runtime
import 'olympe';

// Import project bricks (we use webpack-import-glob-loader to import all bricks)
import './core/auth/GetCurrentUser.js'; import './core/auth/GetIDPNames.js'; import './core/auth/GetUserToken.js'; import './core/auth/IDPLogin.js'; import './core/auth/IsAuthenticated.js'; import './core/auth/KeepSessionAlive.js'; import './core/auth/Login.js'; import './core/auth/Logout.js';
import './core/context/GetClosestContext.js'; import './core/context/GetCurrentContext.js'; import './core/context/GetFromContext.js'; import './core/context/GetParentContext.js'; import './core/context/GetParentOfContext.js'; import './core/context/SetInContext.js';
// Exclude the "postgresql connectors"
import './core/data/base/AreObjectsRelated.js'; import './core/data/base/CreateColor.js'; import './core/data/base/GetObjectByTag.js'; import './core/data/base/GetObjectProperty.js'; import './core/data/base/GetObjectTag.js'; import './core/data/base/GetUniqueRelatedObject.js'; import './core/data/base/IsInstanceOf.js'; import './core/data/base/IsNull.js';
import './core/data/offline/ClearCacheEntry.js'; import './core/data/offline/ClearPendingChanges.js'; import './core/data/offline/ExecuteAndCacheQuery.js'; import './core/data/offline/GetCacheEntriesList.js'; import './core/data/offline/GetPendingChanges.js'; import './core/data/offline/HasPendingChanges.js'; import './core/data/offline/IsCacheProcessing.js'; import './core/data/offline/LoadPendingChanges.js'; import './core/data/offline/ObserveAndCacheQuery.js'; import './core/data/offline/ObserveCacheErrors.js'; import './core/data/offline/SyncChanges.js';
import './core/data/converters/CloudObjectsToJsonAction.js'; import './core/data/converters/CloudObjectToCSV.js'; import './core/data/converters/CloudObjectToExcel.js'; import './core/data/converters/CloudObjectToJSON.js'; import './core/data/converters/CSVToCloudObjects.js'; import './core/data/converters/CSVToJSON.js'; import './core/data/converters/CSVToJsonAction.js'; import './core/data/converters/ExcelToCloudObjects.js'; import './core/data/converters/ExcelToCSV.js'; import './core/data/converters/ExcelToCsvAction.js'; import './core/data/converters/ExcelToJSON.js'; import './core/data/converters/ExcelToJsonAction.js'; import './core/data/converters/helpers/dataFormatHandlers.js'; import './core/data/converters/helpers/handleError.js'; import './core/data/converters/json_mapper/MapJSONToObjects.js'; import './core/data/converters/json_mapper/MapperConfig.js'; import './core/data/converters/JSON_To_CSV.js'; import './core/data/converters/JSON_To_XML.js'; import './core/data/converters/JSONToCloudObject.js'; import './core/data/converters/JSONToExcel.js'; import './core/data/converters/JSONToString.js'; import './core/data/converters/JSONToStringAction.js'; import './core/data/converters/JSONToXMLAction.js'; import './core/data/converters/XML_To_JSON.js'; import './core/data/converters/XMLToJsonAction.js';
import './core/data/json/deprecated/GetFromJSONAction.js'; import './core/data/json/deprecated/GetFromJSONFunction.js'; import './core/data/json/deprecated/helper.js'; import './core/data/json/deprecated/JSONToCloudObjectOld.js'; import './core/data/json/deprecated/JSONToCSV.js'; import './core/data/json/deprecated/JsonToObject.js'; import './core/data/json/deprecated/JsonToObjectList.js'; import './core/data/json/deprecated/JsonToXml.js'; import './core/data/json/deprecated/ObjectListToJson.js'; import './core/data/json/deprecated/ObjectToJson.js'; import './core/data/json/deprecated/ParseJson.js'; import './core/data/json/deprecated/XmlToJson.js'; import './core/data/json/GetFromJSONAction.js'; import './core/data/json/GetFromJSONFunction.js'; import './core/data/json/helper.js'; import './core/data/json/NewJsonObject.js'; import './core/data/json/RemoveFromJSON.js'; import './core/data/json/StringToJSON.js'; import './core/data/json/UpdateJSONAction.js'; import './core/data/json/UpdateJSONFunction.js';
import './core/data/list/AddElementToListAction.js'; import './core/data/list/AddElementToListFunction.js'; import './core/data/list/AddToListAction.js'; import './core/data/list/AddToListFunction.js'; import './core/data/list/ConcatList.js'; import './core/data/list/ContinuousForEach.js'; import './core/data/list/CreateListAction.js'; import './core/data/list/CreateListFunction.js'; import './core/data/list/Filter.js'; import './core/data/list/FilterHasRelated.js'; import './core/data/list/FilterListBigger.js'; import './core/data/list/FilterListContains.js'; import './core/data/list/FilterListDistinct.js'; import './core/data/list/FilterListEquals.js'; import './core/data/list/FilterListRange.js'; import './core/data/list/FilterListSmaller.js'; import './core/data/list/ForEach.js'; import './core/data/list/GetEnumValues.js'; import './core/data/list/GetFirstObjectInList.js'; import './core/data/list/GetObjectAtInList.js'; import './core/data/list/GetObjectList.js'; import './core/data/list/GetRecursiveRelated.js'; import './core/data/list/GetRelatedObjects.js'; import './core/data/list/GetSizeOfList.js'; import './core/data/list/IsListEmpty.js'; import './core/data/list/ListFollowRelation.js'; import './core/data/list/ListFollowRelationRecursively.js'; import './core/data/list/ListForEachEntries.js'; import './core/data/list/ListToEnum.js'; import './core/data/list/Map.js'; import './core/data/list/Reduce.js'; import './core/data/list/RemoveFromListAt.js'; import './core/data/list/SortList.js'; import './core/data/list/utils.js';
import './core/data/operation/CreateLocalObject.js'; import './core/data/operation/CreateRelation.js'; import './core/data/operation/PersistObject.js'; import './core/data/operation/SetObjectProperty.js';
import './core/data/queries/ExecuteQuery.js'; import './core/data/queries/ExecuteQueryFromCache.js'; import './core/data/queries/ObserveQuery.js'; import './core/data/queries/QueryFilter.js'; import './core/data/queries/QueryFilterContains.js'; import './core/data/queries/QueryFilterIn.js'; import './core/data/queries/QueryFilterIsNull.js'; import './core/data/queries/QueryFilterRegex.js'; import './core/data/queries/QueryFollowBack.js'; import './core/data/queries/QueryFollowRelation.js'; import './core/data/queries/QueryFollowRelationFromObject.js'; import './core/data/queries/QueryFollowRelationRecursively.js'; import './core/data/queries/QueryInstancesOf.js'; import './core/data/queries/QueryLimit.js'; import './core/data/queries/QueryObject.js'; import './core/data/queries/QueryResultToArray.js'; import './core/data/queries/QuerySort.js';
import './core/data/transaction/_helpers.js'; import './core/data/transaction/BeginTransaction.js'; import './core/data/transaction/ClearObjectProperty.js'; import './core/data/transaction/CreateLocalObject.js'; import './core/data/transaction/CreateRelation.js'; import './core/data/transaction/DeleteAllRelations.js'; import './core/data/transaction/DeleteListOfObjects.js'; import './core/data/transaction/DeleteObject.js'; import './core/data/transaction/DeleteRelation.js'; import './core/data/transaction/EndTransaction.js'; import './core/data/transaction/EndTransactionAsLarge.js'; import './core/data/transaction/FeedObjectProperty.js'; import './core/data/transaction/PersistObject.js'; import './core/data/transaction/SetObjectProperty.js';
import './core/data/map/AddElementToMap.js'; import './core/data/map/GetFromMap.js'; import './core/data/map/GetMapEntries.js'; import './core/data/map/GetMapSize.js'; import './core/data/map/JSONToMap.js'; import './core/data/map/MapHasKey.js'; import './core/data/map/MapToURLParams.js'; import './core/data/map/NewMap.js'; import './core/data/map/ObjectToMap.js'; import './core/data/map/RemoveFromMap.js'; import './core/data/map/URLParamsToMap.js';
import './core/datetime/DateTimeAdd.js'; import './core/datetime/DateTimeSub.js'; import './core/datetime/FormatDateTime.js'; import './core/datetime/GetDateTimeAttributes.js'; import './core/datetime/IsDateTimeAfter.js'; import './core/datetime/IsDateTimeBefore.js'; import './core/datetime/IsDateTimeEqual.js'; import './core/datetime/IsDateTimeValid.js'; import './core/datetime/Now.js'; import './core/datetime/ParseDateTime.js';
import './core/file/CreateFile.js'; import './core/file/CreateFileFromBase64.js'; import './core/file/CreateFileFromURL.js'; import './core/file/DownloadFile.js'; import './core/file/GetBinaryFileContent.js'; import './core/file/GetBinarySize.js'; import './core/file/GetFileContent.js'; import './core/file/SetFileBase64Content.js'; import './core/file/SetFileContent.js'; import './core/file/SetFileContentFromURL.js';
import './core/localstorage/actions/ClearLocalStorage.js'; import './core/localstorage/actions/GetLocalStorage.js'; import './core/localstorage/actions/RemoveLocalStorage.js'; import './core/localstorage/actions/SetLocalStorage.js'; import './core/localstorage/functions/GetLocalStorage.js'; import './core/localstorage/functions/SetLocalStorage.js'; import './core/localstorage/LocalStorageManager.js';
import './core/net/DecodeURIComponent.js'; import './core/net/EncodeURIComponent.js'; import './core/net/httpActions/HTTPDelete.js'; import './core/net/httpActions/HTTPGet.js'; import './core/net/httpActions/HTTPHead.js'; import './core/net/httpActions/HTTPPatch.js'; import './core/net/httpActions/HTTPPost.js'; import './core/net/httpActions/HTTPPut.js'; import './core/net/httpFunctions/HTTPDelete.js'; import './core/net/httpFunctions/HTTPGet.js'; import './core/net/httpFunctions/HTTPHead.js'; import './core/net/httpFunctions/HTTPPatch.js'; import './core/net/httpFunctions/HTTPPost.js'; import './core/net/httpFunctions/HTTPPut.js'; import './core/net/utils/httpResponseHandlers.js';
import './core/system/CheckParameter.js'; import './core/system/DebugLog.js'; import './core/system/GetGeolocation.js'; import './core/system/GetParameter.js'; import './core/system/GetPlatformInfo.js'; import './core/system/GoOffline.js'; import './core/system/GoOnline.js'; import './core/system/IsOnline.js'; import './core/system/Log.js'; import './core/system/RegisterHealthCheck.js';
import './core/text/Base64Decoder.js'; import './core/text/Base64Encoder.js'; import './core/text/CharAt.js'; import './core/text/CompareStrings.js'; import './core/text/CompareStringsIgnoreCase.js'; import './core/text/Concat.js'; import './core/text/Contains.js'; import './core/text/IndexOf.js'; import './core/text/IsStringEmpty.js'; import './core/text/LengthOfString.js'; import './core/text/PadEndOfString.js'; import './core/text/PadStartOfString.js'; import './core/text/ParseBoolean.js'; import './core/text/ParseNumber.js'; import './core/text/Regex.js'; import './core/text/Replace.js'; import './core/text/StringEndsWith.js'; import './core/text/StringSplit.js'; import './core/text/StringStartsWith.js'; import './core/text/SubString.js'; import './core/text/SubString2.js'; import './core/text/ToLowercase.js'; import './core/text/ToString.js'; import './core/text/ToUppercase.js'; import './core/text/TrimString.js';
import './core/util/BottomOrRight.js'; import './core/util/Branch.js'; import './core/util/CatchErrors.js'; import './core/util/CatchErrorType.js'; import './core/util/CenterOrMiddle.js'; import './core/util/ControlFlowMultiplexer.js'; import './core/util/ControlflowSynchronization.js'; import './core/util/DefaultValue.js'; import './core/util/ErrorFlowMultiplexer.js'; import './core/util/ExecuteOnRuntimeOnly.js'; import './core/util/LengthInPercent.js'; import './core/util/OnControlFlow.js'; import './core/util/OnValue.js'; import './core/util/Pause.js'; import './core/util/ThrowError.js'; import './core/util/Ticker.js'; import './core/util/TopOrLeft.js';
import './core/ui/event/DispatchEvent.js'; import './core/ui/event/GetGyroscopeCoordinates.js'; import './core/ui/event/GetMouseCoordinates.js'; import './core/ui/event/GetMouseEventAttributes.js'; import './core/ui/event/GetTouchEventAttributes.js'; import './core/ui/event/GetTouchEventDetailsAttributes.js'; import './core/ui/event/ListenEvent.js'; import './core/ui/FeedUIProperty.js'; import './core/ui/GetUIProperty.js'; import './core/ui/navigation/GetNavigationRouteImpl.js'; import './core/ui/navigation/GetNavigationState.js'; import './core/ui/navigation/OnNavigationUpdateBrowser.js'; import './core/ui/navigation/OpenURL.js'; import './core/ui/navigation/PushNavigationState.js'; import './core/ui/navigation/RefreshApp.js'; import './core/ui/navigation/ReplaceNavigationState.js'; import './core/ui/navigation/SetNavigationRouteImpl.js'; import './core/ui/SetUIProperty.js'; import './core/ui/util/updateContextProperty.js';
import './core/ui/components/Camera.jsx'; import './core/ui/components/Checkbox.jsx'; import './core/ui/components/CustomizeAlertDialog.jsx'; import './core/ui/components/Dialog.jsx'; import './core/ui/components/Divider.jsx'; import './core/ui/components/Dropdown.jsx'; import './core/ui/components/FileUpload.jsx'; import './core/ui/components/IconTooltip.jsx'; import './core/ui/components/Image.jsx'; import './core/ui/components/Label.jsx'; import './core/ui/components/List.jsx'; import './core/ui/components/MediaPlayer.jsx'; import './core/ui/components/QRCodeViewer.jsx'; import './core/ui/components/Rectangle.jsx'; import './core/ui/components/ShowAlertDialog.jsx'; import './core/ui/components/Slider.jsx'; import './core/ui/components/Switch.jsx'; import './core/ui/components/TextField.jsx'; import './core/ui/components/Touchpad.jsx';
import './core/logic/And.ts'; import './core/logic/Equals.ts'; import './core/logic/If.ts'; import './core/logic/Not.ts'; import './core/logic/Or.ts'; import './core/logic/Xor.ts'; import './core/math/Ceiling.ts'; import './core/math/Divide.ts'; import './core/math/Floor.ts'; import './core/math/IsGreaterThan.ts'; import './core/math/IsSmallerThan.ts'; import './core/math/MathFunctions.ts'; import './core/math/Max.ts'; import './core/math/Min.ts'; import './core/math/Minus.ts'; import './core/math/Modulo.ts'; import './core/math/Multiply.ts'; import './core/math/Plus.ts'; import './core/math/Power.ts'; import './core/math/RandomAction.ts'; import './core/math/Round.ts'; import './core/system/ShutdownApp.ts';
import './core/ui/components/Button.tsx';


export { markdownTextToReactElement } from './helpers/web/remarkable';
export { getLogger } from './helpers/logging';
export {TimeoutMap, TimeoutSet} from './helpers/timeoutData';
export * from './helpers/lambdaExecutor';
export * from 'helpers/binaryConverters';
export * from 'helpers/navigation';
export * from 'helpers/mui';
export * from 'helpers/react.jsx';
export * from 'helpers/ThemeObserver';
export * from 'helpers/httpRequest';
export * from 'helpers/platformInfo';
