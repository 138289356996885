import {combineLatest, Observable, BehaviorSubject, of} from "rxjs";
import {map, startWith, concatMap} from 'rxjs/operators';
import { getLanguage } from "../i18n/_language";
import { ListDef } from "olympe";

/**
 * Combine props together in an object, combine also the current language
 *
 * @param {![{name: string, prop: Property, defaultValue: *, observableMapper: function(Observable): Observable}]} props
 */
export function combineProps(props) {
    const names = props.map(prop => prop.name).concat('_language');
    const sources = props.map(prop => {
        let obs = prop.prop.observe();
        if(prop.defaultValue !== undefined) {
            obs = obs.pipe(startWith(prop.defaultValue));
        }
        if(prop.observableMapper !== undefined) {
            obs = prop.observableMapper(obs);
        }
        return obs;
    }).concat(getLanguage());
    return combineLatest(sources).pipe(map(values => {
        let i = 0;
        return values.reduce((acc, cur) => ({ ...acc, [names[i++]]: cur }), {});
    }));
}

/**
 * Create events usable by React component
 *
 * @param {![{name: string, event: Event, before: function(*)}]} events
 */
export function createEvents(events) {
    return events.reduce((acc, cur) => ({ ...acc, [cur.name]: (a, b) => { if(cur.before) cur.before(a, b); cur.event(); } }), {});
}

/**
 * Get an observable that retains its value (with method getValue() ) from an observable
 *
 * From https://stackoverflow.com/questions/46207592/rxjs-5-converting-an-observable-to-a-behaviorsubject
 *
 * @param {Observable} observable
 * @param {*} initValue
 * @return {{subject: BehaviorSubject, stopWatching: (function(): *)}}
 */
export function convertObservableToBehaviorSubject(observable, initValue) {
    const subject = new BehaviorSubject(initValue);
    const subscription = observable.subscribe(subject);
    return {
        subject,
        stopWatching: () => subscription.unsubscribe()
    };
}
/**
 * Returns a JSON as an Object, with a default value
 *
 * @param {string} json
 * @param {Object} defaultValue
 */
export function getObjectFromJSON(json, defaultValue = {}) {
    let style = defaultValue;
    try {
        style = JSON.parse(json);
    } catch (e) {}
    return style ? style : defaultValue;
}

/**
 * Converts an observable with a ListDef to an Array (or forward the input array)
 * A new array is emitted every time the size of the ListDef changes
 *
 * @param {Observable<ListDef|Array>} listObservable
 * @return {Observable<Array>}
 */
export function mapListObservableToArray(listObservable) {
    return listObservable.pipe(concatMap((list) => {
        if(list?.observeSize !== undefined) {
            return list.observeSize().pipe(map((size) => {
                const array = [];
                if(size > 0) { // prevents calling forEachCurrentValue when list is not ready
                    list.forEachCurrentValue((item) => { array.push(item); } );
                }
                return array;
            }));
        }
        else if(Array.isArray(list)) {
            return of(list);
        } else {
            console.error("Provided value is not a ListDef or Array");
            return of([]);
        }
    }));
}
