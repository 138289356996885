import { ActionBrick, registerBrick } from 'olympe';
import { getLogger } from '@olympeio/core';
import { cacheManager } from '../CacheManager';

export default class AddToCache extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} key
     * @param {*} value
     * @param {function()} forwardEvent
     */
    update(context, [key, value], [forwardEvent]) {
        if (typeof key !== 'string') {
            getLogger('AddToCache').error(`Provided key is not of type string: ${key}`);
            return;
        }
        cacheManager().set(key, value);
        forwardEvent();
    }
}

registerBrick('017d76a6a4755b31ece1', AddToCache);
