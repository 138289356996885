import { ActionBrick, registerBrick, DBView, File, Sync, PropertyPrimitive, BusinessObject, CloudObject, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import { XMLParser } from 'fast-xml-parser';
import { JSONPath } from 'jsonpath-plus';

export default class ReadXMLFile extends ActionBrick {

    /**
     * @override
     */
    init($) {
        this.db = DBView.get();
        this.logger = getLogger('ReadXMLFile');
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {File} file
     * @param {string} dataPath
     * @param {*} businessObject
     * @param {Map} mapping
     * @param {boolean} autoDiscovery
     * @param {function()} forwardEvent
     * @param {function(ListDef)} setObjects
     * @param {function(*)} setErrorFlow
     */
    update($, [file, dataPath, businessObject, mapping, autoDiscovery], [forwardEvent, setObjects, setErrorFlow]) {

        if (!(file instanceof File || typeof file === 'string')) {
            this.logger.error(`The file input is supposed to be a file or string`);
            setErrorFlow(ErrorFlow.create(`The file input is supposed to be a file or string`, 1));
            return;
        }
        if (dataPath && typeof dataPath !== 'string') {
            this.logger.error(`The dataPath is supposed to be a string`);
            setErrorFlow(ErrorFlow.create(`The dataPath is supposed to be a string`, 1));
            return;
        }
        if (!(businessObject instanceof Sync)) {
            this.logger.error(`The business object is supposed to be a olympe object`);
            setErrorFlow(ErrorFlow.create(`The business object is supposed to be a olympe object`, 1));
            return;
        }
        if (mapping && !(mapping instanceof Map)) {
            this.logger.error(`The mapping is supposed to be a Map object`);
            setErrorFlow(ErrorFlow.create(`The mapping is supposed to be a Map object`, 1));
            return;
        }
        if (typeof autoDiscovery !== 'boolean') {
            this.logger.error(`The automatic discovery is supposed to be a boolean`);
            setErrorFlow(ErrorFlow.create(`The automatic discovery is supposed to be a boolean`, 1));
            return;
        }
        if (mapping === undefined && autoDiscovery === false) {
            this.logger.error(`You should either provide a mapping or activate the automatic discovery`);
            setErrorFlow(ErrorFlow.create(`You should either provide a mapping or activate the automatic discovery`, 1));
            return;
        }

        if (autoDiscovery) {
            mapping = this.createMappingFromBusinessObject(businessObject);
        }

        if (typeof file === 'string') {
            this.handleXMLData(file, dataPath, businessObject, mapping, setObjects, forwardEvent, setErrorFlow);
        } else {
            file.getContentAsString(data => {
                this.handleXMLData(data, dataPath, businessObject, mapping, setObjects, forwardEvent, setErrorFlow);
            }, error => {
                setErrorFlow(ErrorFlow.create(error, 4));
            })
        }
    }

    handleXMLData(data, dataPath, businessObject, mapping, setObjects, forwardEvent, setErrorFlow) {
        const parser = new XMLParser();
        const objects = [];
        const jObj = parser.parse(data);
        let result = JSONPath(dataPath, jObj);

        if(result === undefined) {
            throw new Error("Internal Error when parsing XML");
        }
        // unwrap result
        result = result[0];

        if(!Array.isArray(result)) {
            // in case there was a single entry corresponding to the path in the XML,
            // the XMLParser library returns the object directly, not an array. => make sure we have an array
            if(typeof result === 'object') {
                result = [result];
            }
            else if(result === undefined) {
                setErrorFlow(ErrorFlow.create(`Found no data at path "${dataPath}" in XML.`, 5));
                return;
            }
            else {
                throw new Error("Internal Error when parsing XML");
            }
        }
        result.forEach(line => {
            objects.push(this.createOlympeObject(businessObject, mapping, line))
        })
        setObjects(objects);
        forwardEvent();
    }

    /**
     * Generate a mapping configuration according to the property names
     *
     * @param businessObject
     */
    createMappingFromBusinessObject(businessObject) {
        const mapping = new Map();
        this.db.getRelated(businessObject, BusinessObject.propertyRel).forEach(prop => {
            mapping.set(this.db.name(prop), prop);
        });
        return mapping;
    }

    /**
     * Create the olympe object in the local DC
     *
     * @param businessObject
     * @param mapping
     * @param line
     * @return {CloudObject}
     */
    createOlympeObject(businessObject, mapping, line) {

        const properties = new Map();
        for (let [key, prop] of mapping) {
            const type = this.db.getUniqueRelated(prop, PropertyPrimitive.typeRel);

            const rawValue = JSONPath(`\$.${key}`, line);
            let value;
            switch (this.db.name(type)) {
                case 'Number': value = (typeof rawValue === 'string' ? Number(rawValue.replace(/,/g, '.')) : Number(rawValue)); break;
                case 'Date/Time': value = (typeof rawValue === 'string' ? new Date(rawValue) : rawValue); break;
                case 'Boolean': value = Boolean(rawValue); break;
                case 'String': value = String(rawValue); break;
                default: value = rawValue;
            }
            properties.set(prop, value);
        }
        return CloudObject.createWith(properties, businessObject);
    }
}

registerBrick('017e2a5c290235679a22', ReadXMLFile);
