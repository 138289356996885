import { ActionBrick, registerBrick } from 'olympe';
import { cacheManager } from '../CacheManager';

export default class ClearCache extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {!Array} inputs
     * @param {function()} forwardEvent
     */
    update(context, inputs, [forwardEvent]) {
        cacheManager().clear();
        forwardEvent();
    }
}

registerBrick('017d76a94712ec9c94c3', ClearCache);
