import { UIBrick, registerBrick } from 'olympe';
import { combineProps, createEvents, getObjectFromJSON } from "./_helpers";
import ReactDOM from 'react-dom';
import React from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';
import { getIcon } from '@olympeio-extensions/commons';
import { GetLocalisedText } from "../i18n/GetLocalisedText";

export class LocalisedMUIButton extends UIBrick {

    /**
     * This method is run when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const events = createEvents([
            {name: 'onClick', event: context.trigger.bind(context, 'On Click')}
        ]);
        combineProps([
            {name: 'id', prop: context.getProperty('MUI DOM ID')},
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'isLocalised', prop: context.getProperty('Is Localised')},
            {name: 'disabled', prop: context.getProperty('Disabled')},
            {name: 'value', prop: context.getProperty('Value')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'muiSize', prop: context.getProperty('MUI Size')},
            {name: 'muiVariant', prop: context.getProperty('MUI Variant')},
            {name: 'muiFullWidth', prop: context.getProperty('MUI Full Width')},
            {name: 'muiStartIcon', prop: context.getProperty('MUI Start Icon')},
            {name: 'muiEndIcon', prop: context.getProperty('MUI End Icon')},
        ]).subscribe(props => {
            ReactDOM.render((<LocalisedMUIButton.Component {...props} {...events}/>), elementDom);
        });
    }
}

registerBrick('0178e91e6c0a1f18ec8a', LocalisedMUIButton);

/**
 * @param {{
 *     value: string,
 *     styleJSON: string,
 *     muiColor: string,
 *     muiSize: string,
 *     muiVariant: string,
 *     muiStartIcon: string,
 *     muiEndIcon: string,
 *     muiFullWidth: boolean,
 *     id: string,
 *     isLocalised: boolean,
 *     disabled: boolean,
 *     onClick: function()
 *   }} props
 * @return {Element}
 */
LocalisedMUIButton.Component = (props) => {
    const value = props.isLocalised ? GetLocalisedText.getTranslation(props.value) : props.value;
    let style = getObjectFromJSON(props.styleJSON);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Button
                    style={style}
                    color={props.muiColor}
                    size={props.muiSize}
                    variant={props.muiVariant}
                    fullWidth={props.muiFullWidth}
                    startIcon={getIcon(props.muiStartIcon, false)}
                    endIcon={getIcon(props.muiEndIcon, false)}
                    id={props.id}
                    disabled={props.disabled}
                    onClick={props.onClick.bind(this)}>
                        {value}
                </Button>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
