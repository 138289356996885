import {ActionBrick, registerBrick, Transaction, Query, CloudObject, BrickContext} from 'olympe';

export default class DeleteAllInstances extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} $
     * @param {CloudObject} dataType
     * @param {function()} forwardEvent
     */
    async update($, [dataType], [forwardEvent]) {
        const instances = Query.instancesOf(dataType, false).executeFromCache();
        const transaction = instances.reduce((t, i) => t.delete(i), new Transaction(true));
        await transaction.execute();
        forwardEvent();
    }
}

registerBrick('0179f0901afadcd337da', DeleteAllInstances);
