import {UIBrick, registerBrick, UIContext} from 'olympe';
import {combineProps, createEvents, getObjectFromJSON} from './_helpers';
import ReactDOM from 'react-dom';
import React from 'react';
import Chip from '@mui/material/Chip';
import * as PropTypes from 'prop-types';
import { getIcon } from '@olympeio-extensions/commons';
import {checkMUIColor, checkMUISize, checkMUIVariant} from './_enums';

export default class MUIChip extends UIBrick {
    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!UIContext} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const events = createEvents([
            {
                name: 'onDelete',
                event: context.trigger.bind(context, 'On Delete'),
                before: (event, values) => {},
            },
            {
                name: 'onClick',
                event: context.trigger.bind(context, 'On Click'),
                before: (event, values) => {},
            },
        ]);

        combineProps([
            {name: 'deleteIcon', prop: context.getProperty('Delete Icon'), defaultValue: ''},
            {name: 'clickable', prop: context.getProperty('Clickable'), defaultValue: false},
            {name: 'variant', prop: context.getProperty('MUI Variant'), defaultValue: 'default'},
            {name: 'size', prop: context.getProperty('MUI Size'), defaultValue: 'medium'},
            {name: 'muiColor', prop: context.getProperty('MUI Color'), defaultValue: 'default'},
            {name: 'icon', prop: context.getProperty('Icon'), defaultValue: ''},
            {name: 'deletable', prop: context.getProperty('Deletable'), defaultValue: false},
            {name: 'disabled', prop: context.getProperty('Disabled'), defaultValue: false},
            {name: 'label', prop: context.getProperty('Label'), defaultValue: ''},
            {name: 'style', prop: context.getProperty('Style [JSON]'), defaultValue: '{}'},
        ]).subscribe((props) => {
            ReactDOM.render((<MUIChip.Component {...props} {...events} margin={0} />), elementDom);
        });
    }
}

registerBrick('017c0d4596cfe2a7d9f9', MUIChip);


/**
 *
 * @param {Object} props
 * @param {string} props.deleteIcon
 * @param {boolean} props.clickable
 * @param {string} props.variant
 * @param {string} props.size
 * @param {string} props.muiColor
 * @param {string} props.icon
 * @param {boolean} props.deletable
 * @param {boolean} props.disabled
 * @param {string} props.label
 * @param {string} props.style
 * @param {number} props.margin
 * @param {function(*)} props.onDelete
 * @param {function(*)} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
MUIChip.Component = (props) => {
    const style = getObjectFromJSON(props.style);
    return (
        <Chip
            label={props.label}
            variant={checkMUIVariant(props.variant)}
            color={checkMUIColor(props.muiColor)}
            size={checkMUISize(props.size)}
            disabled={props.disabled}
            icon={props.icon ? getIcon(props.icon) : undefined}
            deleteIcon={props.deleteIcon ? getIcon(props.deleteIcon) : undefined}
            onDelete={props.deletable ? props.onDelete : undefined}
            onClick={props.clickable ? props.onClick : undefined}
            style={style}
            sx={ { margin: props.margin } }
        />
    );
};

MUIChip.Component.propTypes = {
    deleteIcon: PropTypes.string,
    clickable: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    muiColor: PropTypes.string,
    icon: PropTypes.string,
    deletable: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.string,
    onDelete: PropTypes.func,
    onClick: PropTypes.func
};

