import { registerBrick, Context, ListDef, Property, QueryResult } from 'olympe';
import AggregateFunction from './AggregateFunction.js';
import { getLogger } from '@olympeio/core';

export default class Sum extends AggregateFunction {


    /**
     * @protected
     * @param {!BrickContext} context
     * @param {ListDef | Array | QueryResult} list
     * @param {Property} prop
     * @param {function(number)} setResult
     */
    update(context, [list, prop], [setResult]) {
        this.aggregate(
            list,
            prop,
            list => {
                let total = 0;
                list.forEach(
                    value => {
                        if (typeof value !== 'number') {
                            getLogger('Sum').error(`[Sum] '${value}' is not a number!`)
                        } else {
                            total += value;
                        }
                    }
                );
                setResult(total);
            }
        );
    }
}

registerBrick('017a5bdf87390fc7332c', Sum);
