import { UIBrick, registerBrick } from 'olympe';
import {combineProps, createEvents, getObjectFromJSON} from "./_helpers";
import ReactDOM from "react-dom";
import React from "react";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import {theme} from "./_theme";
import { getIcon } from '@olympeio-extensions/commons';

export class MUIIconButton extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!Context} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        // Center component
        elementDom.style.display = 'flex';
        elementDom.style.justifyContent = 'center';
        elementDom.style.alignItems = 'center';

        const events = createEvents([
            {name: 'onClick', event: context.trigger.bind(context, 'On Click')}
        ]);
        combineProps([
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]')},
            {name: 'disabled', prop: context.getProperty('Disabled')},
            {name: 'muiColor', prop: context.getProperty('MUI Color')},
            {name: 'muiSize', prop: context.getProperty('MUI Size')},
            {name: 'muiIcon', prop: context.getProperty('MUI Icon')},
        ]).subscribe(props => {
            ReactDOM.render((<MUIIconButton.Component {...props} {...events}/>), elementDom);
        });
    }
}

registerBrick('01794092e9e6681bfcb5', MUIIconButton);

/**
 * @param {{
 *     styleJSON: string,
 *     muiColor: string,
 *     muiSize: string,
 *     muiIcon: string,
 *     disabled: boolean,
 *     onClick: function()
 *   }} props
 * @return {Element}
 */
MUIIconButton.Component = (props) => {
    const style = getObjectFromJSON(props.styleJSON);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <IconButton
                    style={style}
                    color={props.muiColor}
                    size={props.muiSize}
                    disabled={props.disabled}
                    onClick={props.onClick.bind(this)}>
                        {getIcon(props.muiIcon, true)}
                </IconButton>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
