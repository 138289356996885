import { Brick, registerBrick } from 'olympe';
import i18next from "i18next";
import { getLanguage } from "./_language";

export class GetLocalisedText extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} key
     * @param {function(string)} setValue
     */
    update(context, [key], [setValue]) {
        getLanguage().subscribe(() => {
            setValue(GetLocalisedText.getTranslation(key));
        });
    }
}

registerBrick('0178e91f59a26f386f05', GetLocalisedText);

/**
 * @type {Set<string>}
 */
GetLocalisedText.keysTranslated = new Set();

/**
 * @param {string} key
 */
GetLocalisedText.addKey = (key) => {
    GetLocalisedText.keysTranslated.add(key);
};

/**
 * @param {string} key
 * @return {string}
 */
GetLocalisedText.getTranslation = (key) => {
    GetLocalisedText.addKey(key);
    return i18next.t(key);
};

/**
 * Utility
 */
window.printAllKeysTranslated = () => {
    for(let key of GetLocalisedText.keysTranslated)
        console.log(key);
};