import { Brick, registerBrick } from 'olympe';
import {getLogger} from "@olympeio/core";

export default class CreateTreeItem extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {string} tree
     * @param {string} rank
     * @param {string} label
     * @param {string} id
     * @param {string} collapseIcon
     * @param {string} expandIcon
     * @param {!Array} outputs
     */
    update($, [tree, rank, label, data, collapseIcon, expandIcon], [setTree]) {
        if(typeof rank !== 'string' || !/^\d+(\.\d+)*$/.test(rank)){
            getLogger("CreateTreeItem").warn(`The rank is supposed to be a string that contains numbers separated by dots (e.g. 1.1.2). Rank received : ${rank}`);
            return ;
        }

        // Get the tree always on the form of an js array to simplify further processing (input can be string, js object or js array)
        let currentItems;
        if(typeof tree === 'string'){
            try {
                currentItems = JSON.parse(tree);
            } catch (e) {
                getLogger("CreateTreeItem").warn(`An error was encountered when trying to parse the JSON string ${tree}`);
                return ;
            }
        } else {
            currentItems = tree;
        }
        if(!Array.isArray(currentItems)){
            currentItems = [currentItems];
        }

        // Clone the object to allows us to get a previous part of the tree in draw without having to reload
        currentItems = JSON.parse(JSON.stringify(currentItems));

        // Split the rank to build a tree according to the rank
        const rebuildTree = currentItems;
        const arrays = rank.split('.');
        let partialRank;
        arrays.forEach((currentRank, index) => {
            partialRank = partialRank ? partialRank += "." + currentRank : currentRank;
            const foundItem = currentItems.find(item => item['id'] === partialRank);
            if(foundItem && index < arrays.length - 1) {
                // Item found: We go further
                if(!foundItem["children"]){
                    foundItem["children"] = [];
                }
                currentItems = foundItem["children"];

            } else if(index === arrays.length - 1) {
                // End of path: add the data
                if(foundItem){
                    foundItem['name'] = label;
                    foundItem['collapseIcon'] = collapseIcon;
                    foundItem['expandIcon'] = expandIcon;
                    foundItem['data'] = data;
                } else {
                    const newData = {
                        "id": rank,
                        "name": label,
                        "collapseIcon": collapseIcon,
                        "expandIcon": expandIcon,
                        "data": data
                    }
                    currentItems.push(newData);
                }
            } else {
                // Item not found, but not at the end of path:
                // e.g: 1.2.3 but the tree doesn't contains 1.2, create a minimalist node with id & name that is "1.2"
                const newData = {
                    "id": partialRank,
                    "name": partialRank,
                    "children": []
                }
                currentItems.push(newData);
                currentItems = newData["children"];
            }
        });

        // In case of the user mixed up the composition of the tree,
        // we sort it out so the ranks decides who's going to be the first element
        this.sortTree(rebuildTree);
        setTree(rebuildTree);

    }


    /**
     * Recursively sort the tree & its children based on the "id" property
     * @param tree
     */
    sortTree (tree) {
        tree.forEach(item => {
            if(item['children']){
                this.sortTree(item['children']);
            }
        });

        tree.sort((a, b) => {
            if ( a['id'] < b['id'] ){
                return -1;
            }
            if ( a['id'] > b['id'] ){
                return 1;
            }
            return 0;
        });
    }

}

registerBrick('017f5531a367a93644db', CreateTreeItem);
