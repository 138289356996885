import { Brick, registerBrick } from 'olympe';
import {map} from "rxjs/operators";

export default class IsNull extends Brick {

    /**
     * @override
     */
    setupExecution($){
        return $.observe(this.getInputs()[0], false).pipe(map(value => [value]));
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} object
     * @param {function(boolean)} setIsNull
     * @param {function(*)} setObject
     */
    update($, [object], [setIsNull, setObject]) {
        setObject(object);
        setIsNull(object === null);
    }
}

registerBrick('0180e185c86ed0f39472', IsNull);
