import {ErrorFlow, registerBrick, Brick, BrickContext} from 'olympe';
import {getLogger} from '@olympeio/core';

export default class PrettyPrintJSON extends Brick {

    /**
     * @param {!BrickContext} $
     * @param {string} jsonString
     * @param {number} spaces
     * @param {function(ErrorFlow)} setError
     * @param {function(string)} setPretty
     */
    update($, [jsonString, spaces], [setError, setPretty]) {
        // Guards
        if(typeof jsonString !== 'string') {
            getLogger('Pretty Print JSON').error('Provided json input is not a string');
            return;
        }
        if(typeof spaces !== 'number') {
            getLogger('Pretty Print JSON').error('Provided spaces input is not a number');
            return;
        }

        let obj;
        try {
            obj = JSON.parse(jsonString);
        } catch(e) {
            getLogger('Pretty Print JSON').error('Error when parsing input: '+e.message);
            setError(ErrorFlow.create(e.message, 1));
            return;
        }

        if(obj !== undefined) {
            setPretty(JSON.stringify(obj, undefined, spaces));
        }
    }
}

registerBrick('017e4da3c3db2af365f2', PrettyPrintJSON);
