import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsArray } from '../../helper';

export default class Tail extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [source], [setResult, setErrorFlow]) {
        const logger = getLogger('Tail');

        const { array, error } = getAsArray(source);

        if (!error) {
            const result = _.tail(array);
            setResult(result);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017fe45dbe54f45a2f37', Tail);