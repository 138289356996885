import { registerBrick, EnumValue, Enum, GlobalProperties, QueryResult } from 'olympe';
import { ReactBrick, useProperty, cssToSxProps, ifNotTransparent, ifNotNull, useMUITheme } from '@olympeio/core';
import { combineLatestWith, map } from 'rxjs/operators';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MUITabs from '@mui/material/Tabs';
import MUITab from '@mui/material/Tab';

export default class Tabs extends ReactBrick {

    /**
     * @override
     */
    setupExecution($) {
        return $.observe('Hidden').pipe(combineLatestWith($.observe('Tabs', false).pipe(
            map(tabs => tabs instanceof Enum ? tabs.getValues() : QueryResult.empty())
        )));
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return props => {
            const [hidden, tabs] = props.values;
            const theme = useMUITheme($);
            const currentTab = useProperty($, 'Current Tab');
            const borderRadius = useProperty($, 'Border Radius');

            const setCurrentTab = (_, value) => {
                $.set('Previous Tab', currentTab);
                $.set('Current Tab', value);
                $.trigger('On Click');
            };

            // No tabs specified -> show a placeholder in DRAW
            if (!hidden && tabs.size() === 0 && $.get(GlobalProperties.EDITION, true)) {
                return (
                    <Box sx={{ backgroundColor: 'lightgrey', width: 1, height: 1, overflow: 'hidden' }}>
                        <Typography sx={{ color: 'black', padding: 1 }}>
                            <b>Tabs</b><br/>
                            Specify <code>Tabs</code> with values to use the component.
                        </Typography>
                    </Box>
                );
            }

            // Tabs are provided
            else if (!hidden && tabs.size() > 0) {
                return (
                    <ThemeProvider theme={theme}>
                        <MUITabs
                            value={currentTab}
                            onChange={setCurrentTab}
                            sx={{
                                width: 1,
                                height: 1,
                                '.MuiTabs-flexContainer': { height: 1 },
                                boxSizing: 'border-box',
                                ...ifNotTransparent('borderColor', useProperty($, 'Border Color')),
                                ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                                ...ifNotNull('borderWidth', useProperty($, 'Border Width')),
                                ...ifNotTransparent('borderStyle', 'solid', useProperty($, 'Border Color')),
                                ...ifNotTransparent('backgroundColor', useProperty($, 'Default Color')),
                                ...cssToSxProps(useProperty($, 'CSS Property')),
                            }}
                        >
                            {tabs.map((tab, index) => {
                                const value = tab.get(EnumValue.valueProp);
                                const name = tab.get(EnumValue.nameProp) ?? value;
                                return (<MUITab label={name} value={value} key={index} />);
                            })}
                        </MUITabs>
                    </ThemeProvider>
                );
            }

            // Hidden or runtime with no tabs specified
            return null;
        };
    }
}

registerBrick('0188245fcbb87d910229', Tabs);
