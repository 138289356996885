import {Color, registerBrick, VisualBrick} from 'olympe';
import {ReactBrick, getLogger, jsonToSxProps} from '@olympeio/core';
import React, {useEffect, useRef, useState} from 'react';
import { combineLatest } from 'rxjs';
import {TreeView} from "@mui/lab";
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Box from '@mui/material/Box';
import {getIcon} from "@olympeio-extensions/commons";
import styled from '@mui/material/styles/styled';


export default class TreeStructure extends ReactBrick {

    /**
     * @override
     */
    setupExecution($) {
        const properties = [ 'json', 'Width', 'Height', 'Line Height', 'Toggle On Icon Only', 'Text Color', 'MUI sx [json]' ];

        return combineLatest([
            $.observe('Default Color', false),
            $.observe('Focused Color', false),
            $.observe('Renderer', false),
            ...properties.map(p => $.observe(p))
        ]);
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @return {Element}
     */
    static getReactComponent($) {
        // Register the click handler
        const onItemClick = $.get('On Item Click');
        const onItemClickData = onItemClick ? [$.runner(onItemClick), ...onItemClick.getInputs()] : null;

        return (props) => {
            const [bgColor, focusedColor, renderer, json, width, height, lineHeight, toggleOnIconOnly, color, sxProp] = props.values;

            // Get the data whatever the input is (json or string)
            let data;
            if (typeof json === 'object') {
                data = json;
            } else if(typeof json === 'string') {
                try {
                    data = JSON.parse(json);
                } catch (e) {
                    getLogger('TreeView').error(`The JSON input is not valid: ${e}`);
                    return null;
                }
            } else {
                getLogger('TreeView').error(`Unknown type of data : ${typeof json}`);
                return null;
            }

            // Provide the new max height
            useEffect(() => {
                $.set('Max Required Height', TreeStructure.countItems(data) * lineHeight);
            });

            const clickHandler = (content) => {
                if(onItemClickData){
                    const [onClick$, ctrlflowInput, dataInput] = onItemClickData;
                    onClick$.set(dataInput, content);
                    onClick$.trigger(ctrlflowInput);
                }
            }

            // allow expansion of nodes when clicking on the icon only
            // solution taken from https://github.com/mui/material-ui/issues/19953#issuecomment-634456463
            let additionalProps = {};
            if(toggleOnIconOnly) {
                const [expanded, setExpanded] = useState([]);
                additionalProps.onNodeToggle = (event, nodeIds) => {
                    if (event.target.closest(".MuiTreeItem-iconContainer")) {
                        setExpanded(nodeIds);
                    }
                };
                additionalProps.expanded = expanded;
            }

            // Display the component
            return (
                <TreeView
                    aria-label="rich object"
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        bgcolor: bgColor?.toHexString(),
                        height: height,
                        width: width,
                        color: color?.toHexString()
                    }}
                    {...additionalProps}
                >
                    {
                        Array.isArray(data)
                            ? data.map((node) => TreeStructure.renderTreeItem($, node, clickHandler, renderer))
                            : TreeStructure.renderTreeItem($, data, clickHandler, renderer)
                    }
                </TreeView>
            )
        };
    }

    /**
     * @param {BrickContext} $
     * @param {Object} node
     * @param {function(Object)} onItemClick
     * @param {!VisualBrick} renderer
     * @return {JSX.Element}
     */
    static renderTreeItem = ($, node, onItemClick, renderer) => {
        const lineHeight = $.get('Line Height');
        const sxProp = $.get('MUI sx [json]');
        const collapseIcon = !node.collapseIcon ? 'expand_more' : node.collapseIcon;
        const expandIcon = !node.expandIcon ? 'chevron_right' : node.expandIcon;
        const id = node.id;

        let label;
        if (renderer !== null && id !== undefined) {

            const ref = useRef();
            useEffect(() => {
                if (!ref.current) {
                    return; // No need to render the line if not displayed by the tree
                }
                const renderer$ = $.runner(renderer)
                    .set('Node Id', id)
                    .set('Node Name', node.name)
                    .set('Node Data', node.data)
                    .set('Height', lineHeight)
                    .setParentElement(ref.current);

                setTimeout(() => {
                    const bounds = ref.current?.parentElement?.getBoundingClientRect();
                    // minus 8px padding left and right applied on <li/> element, but not taken into account in the children flexbox width...
                    bounds && renderer$.set('Width', bounds.width-16);
                }, 0);

                return () => renderer$?.destroy();
            });

            label = <Box sx={{ height: lineHeight }} ref={ref} />
        }
        else {
            label = <Box sx={{
                height: lineHeight,
                display: 'table-cell',
                verticalAlign: 'middle'
            }}>
                {node.name}
            </Box>;
        }

        const focusedColor = $.get('Focused Color');
        const StyledTreeItem = styled((props) => (
            <TreeItem {...props} />
        ))(({ theme }) => ({
            [`& .${treeItemClasses.content}`]: {
                '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
                    backgroundColor: (focusedColor instanceof Color ? focusedColor.toHexString() : undefined),
                },
            },
        }));

        return (
            <StyledTreeItem
                key={id}
                nodeId={id}
                label={label}
                collapseIcon={getIcon(collapseIcon, true)}
                expandIcon={getIcon(expandIcon, true)}
                onClick={() => onItemClick(node['data'])}
                sx={{...jsonToSxProps(sxProp)}}
            >
                {
                    Array.isArray(node.children)
                    ? node.children.map((node) => TreeStructure.renderTreeItem($, node, onItemClick, renderer))
                    : null
                }
            </StyledTreeItem>
        );
    };

    /**
     * Recursively get the size of the tree
     *
     * @param {Object[]} data
     * @return {number}
     */
    static countItems (data) {
        if(!data){
            return 0;
        }

        data = Array.isArray(data) ? data : [data];

        let count = data.length;
        data.forEach(item => {
            count += TreeStructure.countItems(item['children']);
        });

        return count;
    }
}

registerBrick('017f20fb338839d62a12', TreeStructure);
