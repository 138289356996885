import { UIBrick, registerBrick, UIContext } from 'olympe';
import { getLogger } from '@olympeio/core';
import { BarcodeFormat, DecodeHintType, NotFoundException } from '@zxing/library';
import { BrowserMultiFormatReader, IScannerControls } from '@zxing/browser';
import { combineLatest } from 'rxjs';

const ALL_FORMATS = [
    // 1D product
    BarcodeFormat.UPC_A, BarcodeFormat.UPC_E, BarcodeFormat.EAN_8, BarcodeFormat.EAN_13,
    // 1D product
    BarcodeFormat.CODE_39, BarcodeFormat.CODE_128, BarcodeFormat.ITF, BarcodeFormat.RSS_14,
    // 2D
    BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX, BarcodeFormat.AZTEC, BarcodeFormat.PDF_417
];

export class QRScanner extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!UIContext} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        const logger = getLogger('QRScanner');

        const videoElement = document.createElement('video');
        const focusOverlay = document.createElement('div');

        combineLatest(
            context.getProperty('Width').observe(),
            context.getProperty('Height').observe()
        ).subscribe(([width, height]) => {
            if (width <= 0 || height <= 0) {
                logger.error('Width and Height should be positive number');
                logger.warn('Please set positive width and height');
                videoElement.width = 1;
                videoElement.height = 1;
            } else {
                videoElement.width = width;
                videoElement.height = height;
            }
            this.resizeComponent(videoElement, focusOverlay);
        });

        // setup accepted barcode formats
        const decodeContinuously = (constraints) => {
            const hints = new Map();
            hints.set(DecodeHintType.POSSIBLE_FORMATS, ALL_FORMATS);

            // start the scanner (MediaStream selection is done by the lib, following the constraints)
            const codeReader = new BrowserMultiFormatReader();
            codeReader.setHints(hints);

            codeReader.decodeFromConstraints(constraints, videoElement, (result, err) => {
                if (result) {
                    // export scanned value
                    context.getProperty('scanned value').set(result.getText());
                    context.getEvent('On Scanned').trigger();
                }
                if (err) {
                    if (!(err instanceof NotFoundException)) {
                        logger.error(err);
                    }
                }
            }).then((obj) => {
                // register the scanner controls for stopping it later
                context.set('IScannerControls', obj);

                // set the size & position of the overlay to match the actual video
                this.resizeComponent(videoElement, focusOverlay);
            }).catch((reason) => {
                logger.error(reason);
            });
        };

        // observe UI props and render accordingly
        combineLatest(
            context.observe('is active'),
            context.observe('front camera')
        ).subscribe(([isActive, frontCamera]) => {
                this.stopScanner(context); // stop any previous scanner run
                if (isActive) {
                    // set constraints, so that
                    //    - camera uses the max width/height available, while keeping aspect ratio
                    //    - faces front or back according to prop
                    const constraints = {
                        video: {
                            width: { 'max': videoElement.width },
                            height: { 'max': videoElement.height },
                            facingMode: frontCamera ? 'user' : 'environment'
                        },
                        audio: false,
                    };
                    decodeContinuously(constraints);
                    elementDom.appendChild(focusOverlay);
                } else {
                    if (elementDom.contains(focusOverlay)) {
                        elementDom.removeChild(focusOverlay);
                    }
                }
            }
        );
        elementDom.appendChild(videoElement);
    }

    /**
     * Method called any time we want to refresh the DOM element
     *
     * @param videoElement
     * @param focusOverlay
     */
    resizeComponent(videoElement, focusOverlay) {

        let actualScaling = Math.min(videoElement.width / videoElement.videoWidth, videoElement.height / videoElement.videoHeight);
        const w = actualScaling * videoElement.videoWidth;
        const h = actualScaling * videoElement.videoHeight;
        const borderWidth = w / 10;

        const focusOverlayStyle = "z-index: 1; box-sizing: border-box !important; border: solid rgba(0, 0, 0, 0.3); box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset; position: absolute;";
        focusOverlay.setAttribute('style', focusOverlayStyle +
            ` width: ${w}px; height: ${h}px; border-width:${borderWidth}px; ` +
            `top: ${(videoElement.height - h) / 2}px; left: ${(videoElement.width - w) / 2}px;`
        );
    }

    /**
     * @param {!UIContext} context
     */
    stopScanner(context) {
        // stop the media stream
        const controls = /** @type {IScannerControls} */(context.get('IScannerControls'));
        if (controls !== null) {
            controls.stop();
            context.remove('IScannerControls');
        }
    }

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     */
    destroy(context) {
        this.stopScanner(context);
    }
}

// Registry
registerBrick('017670012f3f3cc70b49', QRScanner);
