import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsJson, iterateInObject } from '../../helper';

export default class PickBy extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {*} predicate
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    async update($, [source, predicate], [setResult, setErrorFlow]) {
        const logger = getLogger('Pick By');

        // Guards
        if (!(predicate instanceof Brick)) {
            logger.error('Provided predicate input is not a Brick');
            return;
        }

        const { json, error } = getAsJson(source);

        if (!error) {
            const lambdaResults = await iterateInObject($, json, predicate);

            let index = 0;
            const result = _.pickBy(json, () => lambdaResults[index++]);
            setResult(result);
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017ffa05d6524513cbb6', PickBy);