import { Brick, registerBrick } from 'olympe';
import {combineLatest, of} from 'rxjs';

export default class SVGCircle extends Brick {

    setupExecution($) {
        let circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        return combineLatest([of(circle)].concat(this.getInputs().map((i) => $.observe(i))));
    }
    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {SVGElement} circle
     * @param {number} cx
     * @param {string} cy
     * @param {string} radius
     * @param {Color} color
     * @param {function(SVGElement)} setCircle
     */
    update($, [circle, cx, cy, radius, color], [setCircle]) {
        circle.setAttribute('cx', cx);
        circle.setAttribute('cy', cy);
        circle.setAttribute('r', radius);
        circle.setAttribute('fill', "rgb("+color.getRed()+","+color.getGreen()+","+color.getBlue()+")");
        
        setCircle(circle);
    }
}

registerBrick('01860da71d8b9046ab46', SVGCircle);

