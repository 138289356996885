<template>
  <div>
    <button
      type="button"
      class="carousel__next"
      aria-label="Navigate to next slide"
      v-bind:style="[{ color: color, background: background }]"
      v-bind:class="{ 'is-last-slide': currentSlide === slidesCount - 1 && !continuous }"
      @click="this.$emit('toNext', currentSlide, currentSlide + 1)"
    >
      <svg
        class="carousel__icon"
        viewBox="0 0 24 24"
        role="img"
        aria-label="arrowRight"
      >
        <title>arrowRight</title>
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
      </svg>
    </button>
    <button
      type="button"
      class="carousel__prev"
      aria-label="Navigate to previous slide"
      v-bind:style="[{ color: color, background: background }]"
      v-bind:class="{ 'is-first-slide': currentSlide === 0 && !continuous }"
      @click="this.$emit('toPrev', currentSlide, currentSlide - 1)"
    >
      <svg
        class="carousel__icon"
        viewBox="0 0 24 24"
        role="img"
        aria-label="arrowLeft"
      >
        <title>arrowLeft</title>
        <path
          d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: ['currentSlide', 'slidesCount', 'color', 'background', 'continuous'],
};
</script>

<style>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

button.carousel__prev.is-first-slide {
  display: none;
}

button.carousel__next.is-last-slide {
  display: none;
}
</style>
