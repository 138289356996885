import { Transaction, Sync } from 'olympe';
import { getLogger } from '@olympeio/core';
import React, { useEffect, useState } from 'react';
import { map } from 'rxjs/operators';
import Board, { moveCard } from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';

export const KanbanBoard = ({ board, boardProps, onStatusChangedContext, onTileClickContext }) => {
    const [controlledBoard, setControlledBoard] = useState(board);

    const updateCardStatus = (card, newInstanceStatus) => {
        const oldInstanceStatus = Sync.getInstance(card.id).getProperty(boardProps.status);

        const transaction = new Transaction(true);
        transaction.update(card.id, boardProps.status, newInstanceStatus);
        transaction.execute().then(() => {
            onStatusChangedContext.subscribe(context => {
                const [controlFlow, selectedCard, oldStatus, newStatus] = context?.inputs;

                context?.ctx.set(selectedCard, Sync.getInstance(card.id));
                context?.ctx.set(oldStatus, oldInstanceStatus);
                context?.ctx.set(newStatus, newInstanceStatus);
                context?.ctx.set(controlFlow, Date.now());
            });
        }).catch(message => {
            getLogger('Kanban').error(`Failed to update status ${card.id} : ${errorMessage}`);
        });
    };

    const getStatusByCardId = id => {
        const status = board.columns.find(column => column.id === id)?.title;
        return status;
    };

    const handleCardMove = (_card, source, destination) => {
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setControlledBoard(updatedBoard);

        const newStatus = getStatusByCardId(destination.toColumnId);
        updateCardStatus(_card, newStatus);
    };

    useEffect(() => {
        setControlledBoard(board);
    }, [board]);

    useEffect(() => {
        // render with the interval until columnElements are found
        const intervalId = setInterval(() => renderColumnStyle(intervalId), 50);
    }, []);

    const renderColumnStyle = (intervalId) => {
        const columnElements = document.getElementsByClassName('react-kanban-column');
        if (columnElements.length) {
            clearInterval(intervalId);
        }

        const columnWidth = boardProps.cardWidth;

        for (const columnElem of columnElements) {
            columnElem.style.backgroundColor = boardProps.columnColor?.toHexString();
            columnElem.style.width = `${columnWidth}px`;
            columnElem.style.minWidth = `${columnWidth}px`;
            columnElem.style.maxWidth = `${columnWidth}px`;
            columnElem.style.overflow = 'hidden';
            columnElem.style.fontSize = boardProps.fontSize;
            columnElem.style.fontFamily = boardProps.fontFamily;
        }
    };

    const card = (card, { dragging }) => {
        renderColumnStyle();

        const [backgroundColor, setBackgroundColor] = React.useState(boardProps.cardColor.toHexString());
        const [tileBackgroundColor, setTileBackgroundColor] = React.useState(null);

        useEffect(() => {
            card.color?.pipe(map((tileColor) => {
                if (tileColor) {
                    setTileBackgroundColor(tileColor.toHexString());
                }
            })).subscribe();
        }, []);

        useEffect(() => {
            setBackgroundColor(boardProps.cardColor);
        }, [boardProps.cardColor]);

        const transX = dragging ? boardProps.x : 0;
        const transY = dragging ? boardProps.y : 0;
        const cardWidth = boardProps.cardWidth;

        const handleClick = clickedCard => {
            onTileClickContext.subscribe(context => {
                const [controlFlow, selectedCard] = context.inputs;

                context.ctx.set(selectedCard, Sync.getInstance(clickedCard.id));
                context.ctx.set(controlFlow, Date.now());
            });
        };

        return (
            <div
                style={{
                    transform: `translate(-${transX}px, -${transY}px)`,
                    width: `${cardWidth}px`,
                    minWidth: `${cardWidth}px`,
                    maxWidth: `${cardWidth}px`,
                    backgroundColor: tileBackgroundColor || backgroundColor,
                    overflow: 'hidden',
                    fontSize: boardProps.fontSize,
                    fontFamily: boardProps.fontFamily
                }}
                className={`react-kanban-card ${dragging ? 'react-kanban-card--dragging' : ''}`}
                onClick={() => handleClick(card)}
            >
                <span>
                    <div className='react-kanban-card__title'>
                        <span>{card.title}</span>
                    </div>
                </span>
                <div className='react-kanban-card__description'>{card.description}</div>
            </div>
        );
    };

    return (
        <Board
            onCardDragEnd={handleCardMove}
            renderCard={card}
            disableColumnDrag
        >
            {controlledBoard}
        </Board>
    );
};
