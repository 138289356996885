import { ListDef, CloudObject, QueryResult, BrickContext, Predicate, PropertyPrimitive, StringModel, generateTag, tagToString } from 'olympe';
import { getLogger, useProperty } from '@olympeio/core'
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import { of, Observable } from 'rxjs';
import { combineLatestWith, switchMap } from 'rxjs/operators';
import { filterByValue, debounce } from '../../helpers/_helpers';
import { MUIDataGrid } from './MUIDataGrid.jsx';
import { GridCheckIcon, GridCloseIcon } from '@mui/x-data-grid';

export const DatagridComponent = ({ $, columns, renderers, refresh }) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [pageSize, setPageSize] = useState();

    const disableSort = useProperty($, 'Disable Sort Per Column', false);
    const isServerMode = useProperty($, 'Is Server Mode', false);
    const filterText = useProperty($, 'Filter Text', false);

    const searchDebouncer = useCallback(
        debounce((text, rows) => {
            if (text === '') {
                setFilteredRows(rows);
            }
            else if (!isServerMode) {
                const dateFormat = $.get('Date Format');
                setFilteredRows(filterByValue(rows, text, dateFormat));
            }
        }, 250),
        []
    );

    // Initialize the map of runners with empty arrays.
    useEffect(() => {
        $.set('allRunners', renderers.reduce((map, renderer) => map.set(renderer.getTag(), []), new Map()));
    }, []);

    // Cleaning up Existing Runners is a heavy operation, with great power comes great responsibility
    // therefore we wrap it up in a debounce to make sure it is not called too often (for example if the user
    // enjoys resizing his datagrid a tremendous amount of time in a short window)
    const cleanupExtraRunners = useCallback(debounce((pageSize) => {
        $.get('allRunners')?.forEach((runners) => {
            // Too many runners for the number of rows displayed
            if (runners.length > pageSize) {
                for (let i = pageSize; i < runners.length; i++) {
                    runners[i]?.destroy?.(); // can be undefined (or 0) or a runner
                    delete runners[i];
                }
                runners.length = pageSize;
            }
        });
    }, 250), []);

    // Every time the page size changes, check to destroy unused renderers
    useEffect(() => {
        cleanupExtraRunners(pageSize);
    }, [pageSize]);

    // Get the list of data
    useEffect(() => {
        const observeList = $.observe('Data (local)', false);
        const observeListSize = observeList.pipe(switchMap(list => getSizeOfList(list)));
        const subscription = observeList.pipe(combineLatestWith(observeListSize)).subscribe(([_data, size]) => {
            if (columns && columns.length > 0 && _data) {
                getRows($, _data, columns).then((r) => {
                    setRows(r);
                    searchDebouncer(filterText, r);
                });
            }
        });
        return () => { subscription.unsubscribe(); };
    }, [columns, refresh]);

    // On Search Use Effect
    useEffect(() => {
        if (rows.length > 0) {
            searchDebouncer(filterText, rows);
        }
    }, [filterText]);

    // Add function with cell rendering to expected columns
    const mappedColumns = useMemo(() => {
        return columns.map((col) => {
            const renderer = renderers[col.rendererIndex];
            if (renderer) {
                col.renderCell = (params) => {
                    params.rowHeight = $.get('Row Height');
                    return (
                        <RenderColumn
                            $={$}
                            renderer={renderer}
                            params={params}
                            pageSize={pageSize}
                        />
                    );
                };
            }
            else if (col.type === "boolean") {
                col.renderCell = (params) => {
                    const value = params.row[col.field];
                    return value === true ? <GridCheckIcon /> : value === false ? <GridCloseIcon /> : ""
                };
            }
            col.sortable = !(!!col.renderCell || disableSort);
            return col;
        });
    }, [renderers, pageSize]);

    /**
     * @param {!BrickContext} $
     * @param {Brick} renderer
     * @param {Object} params
     * @param {number} pageSize
     */
    const RenderColumn = React.memo(({ $, renderer, params, pageSize }) => {
        return (<Box
            id={generateTag()}
            className={'custom-renderer'}
            style={{border: 'none', outline: 'none', color: 'transparent', width: '100%', height: '100%'}}
            ref={el => el && renderColumnStyle($, el, renderer, params, pageSize)} //Cell rendering
        />);
        }
    );

    /**
     * @param {!BrickContext} $
     * @param {!HTMLElement} el
     * @param {Brick} renderer
     * @param {!Object} params
     * @param {number} pageSize
     */
    const renderColumnStyle = ($, el, renderer, params, pageSize) => {
        const tag = params.row.tag;
        if (!CloudObject.exists(tag)) {
            // Do nothing if the current object does not exist. This occurs especially when deleting a row from its own renderer.
            return;
        }

        const selectedEntries = $.get('Selected Entries') || [];
        const isSelected = selectedEntries.filter(selectedRow => selectedRow.tag === tag).length !== 0;
        // get all runners for the current Custom Renderer
        const allRunners = $.get('allRunners').get(renderer.getTag());
        if (allRunners) {
            const rowIndex = params.id % pageSize;
            let runner = allRunners[rowIndex];
            // if no runner exists for this Row index, create one
            if (!runner) {
                runner = $.runner(renderer);
                allRunners[rowIndex] = runner;
            }

            // update runner's row data
            runner
                .set('Current Index', rowIndex)
                .set('Current Item', CloudObject.get(tag))
                .set('Is Selected', isSelected)
                .set('Current List', $.get('Data (local)'))
                .set('Width', params.colDef.width)
                .set('Height', params.rowHeight)
                .setParentElement(el); // Attach runner to the row cell
        }
    };

    /**
     * @private
     * @param {*} list
     * @return {Observable<number>}
     */
    const getSizeOfList = (list) => {
        if (Array.isArray(list)) {
            return of(list.length);
        } else if (list instanceof QueryResult) {
            return of(list.size());
        } else if (list instanceof ListDef) {
            return list.observeSize();
        }
        if (list !== null) {
            getLogger('Datagrid').error('Data (local) supposed to be ListDef, Array or QueryResult');
        }
        return of(0);
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {Object[]} data
     * @param {Object[]} columns
     * @return {*[]}
     */
    const getRows = async ($, data, columns) => {
        let index = 0;
        let entries = Array.isArray(data) ? data : [];
        if (data instanceof QueryResult) {
            entries = data.toArray();
        } else if (data instanceof ListDef) {
            data.forEachCurrentValue(entry => entries.push(entry));
        }
        return await Promise.all(entries.map(
            async (entry) => await processRow($, index++, entry, columns)
        ));
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {number} index
     * @param {!CloudObject} instance
     * @param {!Array} columns
     * @return {Object}
     */
    const processRow = async ($, index, instance, columns) => {
        const row = {
            id: index,
            filterable: true,
            tag: instance.getTag(), // this prop is not part of the DataGrid API! It is useful for the selection
        };

        // Append properties values, in the order defined
        for (const col of columns) {
            let fieldValue = '';
            if (col.relation) {
                fieldValue = await getRelatedPropertyValue($, instance, col);
            } else {
                fieldValue = getPropertyValue(instance, col);
            }
            row[col.field] = fieldValue;
        }
        return row;
    }

    /**
     * @private
     * @param {!BrickContext} $
     * @param {!CloudObject} instance
     * @param {!Object} column
     * @return {string}
     */
    const getRelatedPropertyValue = async ($, instance, column) => {
        const relatedInstance = await instance.followSingle(column.relation).execute();
        return relatedInstance !== null ? getPropertyValue(relatedInstance, column) : '-';
    }

    /**
     * @private
     * @param {!CloudObject} instance
     * @param {!Object} column
     * @return {string}
     */
    const getPropertyValue = (instance, column) => {
        if (column.model && column.model.name() === 'Enum') {
            return getDisplayName(instance.get(column.propTag), column.propTag);
        }
        // Get the property value depending on its type
        if (['string', 'dateTime', 'boolean'].includes(column.type)) {
            return instance.get(column.propTag);
        } else if (column.type === 'number') {
            return String(instance.get(column.propTag));
        }
        return '';
    }

    const getDisplayName = (value, property) => {
        const enumValue = property.follow(PropertyPrimitive.typeRel).follow(PropertyPrimitive.instancesRel)
            .filter(Predicate.equals(StringModel.valueProp, value)).executeFromCache().getFirst();

        return (enumValue && enumValue.name()) || value;
    };

    return (
        <MUIDataGrid
            $={$}
            rows={filteredRows}
            cols={mappedColumns}
            isServerMode={isServerMode}
            pageSize={pageSize}
            onPageSizeCalculated={setPageSize}
        />
    );
};
