import { Brick, registerBrick } from 'olympe';

export default class URLInformations extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array} inputs
     * @param {function(string)} setHost
     * @param {function(string)} setHostname
     * @param {function(string)} setHref
     * @param {function(string)} setOrigin
     * @param {function(string)} setPathname
     * @param {function(string)} setPort
     * @param {function(string)} setProtocol
     * @param {function(string)} setHash
     */
    update($, inputs, [setHost, setHostname, setHref, setOrigin, setPathname, setPort, setProtocol]) {
        const location = window.location;
        setHost(location.host);
        setHostname(location.hostname);
        setHref(location.href);
        setOrigin(location.origin);
        setPathname(location.pathname);
        setPort(location.port);
        setProtocol(location.protocol);
    }
}

registerBrick('0181dc6bbc3995e8584f', URLInformations);
