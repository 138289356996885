const GLOBAL_BACKDROP_KEY = '$__GlobalBackdrop';

/**
 * @param {string} key
 * @return {[string, string]}
 */
export function getBackdropKeys(key) {
    return [`${GLOBAL_BACKDROP_KEY}:${key}:shown`, `${GLOBAL_BACKDROP_KEY}:${key}:label`];
}

/*
 * @param {string} key
 * @return {HTMLDivElement}
 */
export function getBackdropContainer(key) {
    return document.querySelector(`body > .global-backdrop-container.global-backdrop-container-${key}`) || addBackdropContainer(key);
}

/**
 * @param {string} key
 * @return {HTMLDivElement}
 */
export function addBackdropContainer(key) {
    const container = document.createElement('div');
    container.classList.add('global-backdrop-container');
    container.classList.add(`global-backdrop-container-${key}`);
    container.style.position = 'absolute';
    container.style.zIndex = '1500';
    document.body.appendChild(container);
    return container;
}

/**
 * Returns the root context of the given context
 * @param {!BrickContext} $
 * @return {!BrickContext}
 */
export function getRootContext($) {
    const contexts = /** @type {BrickContext[]} */ ([$]);
    for (const current of contexts) {
        const parent = current.getParent();
        parent && contexts.push(parent);
    }
    return contexts.pop();
}
