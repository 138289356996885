// WARNING ****************************************************************************
// This file is automatically generated. We recommend that you do not edit it manually.
// ************************************************************************************

// This file imports Olympe and project and dependencies bricks source code, so that Webpack can bundle everything together. 

// Import Olympe runtime or DRAW
import 'olympe';
import '@olympeio/core';

// Import project bricks. We use webpack-import-glob-loader to import all bricks.

import '@olympeio-extensions/file-format-support';
import '@olympeio-extensions/qrcode-zxing';
import '@olympeio-extensions/material';
import '@olympeio-extensions/templates';


// Import further dependencies bricks

import './bricks/Actions/DQ_JDRCSVToJSON.js'; import './bricks/Actions/JSONFind.js';
