import { Brick, registerBrick } from 'olympe';

export default class GetTimezoneOffset extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {Date} localDate
     * @param {function(number)} setOffset
     */
    update(context, [localDate], [setOffset]) {
        setOffset(localDate.getTimezoneOffset());
    }
}

registerBrick('017cbc8d1f78c04aabdf', GetTimezoneOffset);