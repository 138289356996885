import { Brick, registerBrick, ErrorFlow } from 'olympe';
import { getLogger } from '@olympeio/core';
import * as _ from 'lodash';
import { getAsArray, getAsJson } from '../../helper';

export default class Omit extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {*} source
     * @param {*} paths
     * @param {function(*)} setResult
     * @param {function(*)} setErrorFlow
     */
    update($, [source, paths], [setResult, setErrorFlow]) {
        const logger = getLogger('Omit');
        let pathsToOmit = null;
        if (typeof paths === 'string') {
            try {
                const pathsAfterParse = JSON.parse(paths);
                pathsToOmit = { array: pathsAfterParse };
            } catch (err) {
                pathsToOmit = { array: paths };
            }
        } else {
            pathsToOmit = getAsArray(paths);
        }
        // Guards
        if (pathsToOmit.error) {
            logger.error('Provided paths input is not a string or array');
            return;
        }

        const { json, error } = getAsJson(source);
        if (!error) {
            setResult(_.omit(json, pathsToOmit.array));
        } else {
            logger.error(error.message);
            setErrorFlow(ErrorFlow.create(error.message, error.code));
        }
    }
}

registerBrick('017ff9b25abda101cb76', Omit);