import { Brick, registerBrick } from 'olympe';
import { getLogger } from "@olympeio/core";
import { merge } from "rxjs";
import { map } from "rxjs/operators";

export default class Record extends Brick {

    /**
     * @override
     */
    setupExecution($) {
        const [start, stop] = this.getInputs();
        return merge(
            $.observe(start).pipe(map(_ => true)),
            $.observe(stop).pipe(map(_ => false))
        );
    }



    /**
     * @protected
     * @param {!BrickContext} context
     * @param {boolean} start
     * @param {function()} forwardEvent
     * @param {function(string)} setAudioFile
     * @param {function(boolean)} setIsRecording
     */
    update(context, [start], [setAudioFile, setIsRecording]) {

        if(start) {
            context.set('isRecording', true);
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    const mediaRecorder = new MediaRecorder(stream)
                    context.set('mediaRecorder', mediaRecorder);

                    let audioChunks = [];
                    context.set('audioChunks', audioChunks);
                    mediaRecorder.addEventListener("dataavailable", event => {
                        audioChunks.push(event.data);
                    });

                    mediaRecorder.addEventListener("stop", () => {
                        const audioBlob = new Blob(audioChunks);
                        setAudioFile(URL.createObjectURL(audioBlob));
                        audioChunks = [];
                    });

                    setIsRecording(true);
                    mediaRecorder.start();
                    getLogger('Record Audio').info('start recording');
                });
        } else {
            if(context.get('mediaRecorder')) {
                context.get('mediaRecorder').stop();
                setIsRecording(false);
                getLogger('Record Audio').info('stop recording');
            }
        }
    }


    /**
     * @override
     */
    destroy(context) {
        if(context.get('mediaRecorder')) {
            context.get('mediaRecorder').stop();
            getLogger('Record Audio').info('stop recording');
        }
    }
}

registerBrick('017baa59384066e2b79d', Record);
