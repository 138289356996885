import { ActionBrick, registerBrick } from 'olympe';

export default class StopLoadingPopup extends ActionBrick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Array} inputs
     * @param {function()} forwardEvent
     */
    update($, inputs, [forwardEvent]) {
        document.getElementById('loading-popup')?.remove();
        forwardEvent();
    }
}

registerBrick('017ed38a8bebe8cb23ba', StopLoadingPopup);
