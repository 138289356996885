import { Brick, registerBrick } from 'olympe';
import {combineLatest} from "rxjs";
import {filter, tap} from "rxjs/operators";

export default class SynchronizeEvents extends Brick {

    /**
     * @override
     */
    setupExecution($) {
        let lastFlows = [0, 0];
        return combineLatest(this.getInputs().map(input => $.observe(input))).pipe(
            filter((flows) => flows[0] > lastFlows[0] && flows[1] > lastFlows[1]),
            tap((flows) => { lastFlows = flows; })
        );
    }

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {!Array} inputs
     * @param {function(number)} forwardEvent
     */
    update(context, inputs, [forwardEvent]) {
        forwardEvent(Date.now());
    }
}

registerBrick('01808e0b7368a8a5ed99', SynchronizeEvents);
