import {UIBrick, registerBrick, DBView, Sync, UIContext} from 'olympe';
import { combineProps, createEvents, getObjectFromJSON } from "./_helpers";
import ReactDOM from 'react-dom';
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './_theme';
import { GetLocalisedText } from "../i18n/GetLocalisedText";

export class AutocompleteSelect extends UIBrick {

    /**
     * This method runs when the brick is ready in the HTML DOM.
     * @override
     * @param {!UIContext} context
     * @param {!Element} elementDom
     */
    draw(context, elementDom) {
        // On change handling
        const events = createEvents([
            {
                name: 'onChange',
                event: context.trigger.bind(context, 'On Change'),
                before: (event, newVal) => {
                    if(newVal){
                        context.getProperty('Selected Value').set(Sync.getInstance(newVal.value));
                    } else {
                        context.getProperty('Selected Value').set(null);
                        const props = context.get('tmpProps');
                        props.value = null;
                        this.myRender(props, events, elementDom);
                    }
                }
            }
        ]);

        // Refresh components on props
        combineProps([
            {name: 'isLocalised', prop: context.getProperty('Is Localised'), defaultValue: false},
            {name: 'muiVariant', prop: context.getProperty('MUI Variant'), defaultValue: 'standard'},
            {name: 'muiColor', prop: context.getProperty('MUI Color'), defaultValue: 'primary'},
            {name: 'styleJSON', prop: context.getProperty('Style [JSON]'), defaultValue: ''},
            {name: 'disabled', prop: context.getProperty('Disabled'), defaultValue: false},
            {name: 'title', prop: context.getProperty('Title'), defaultValue: ''},
            {name: 'options', prop: context.getProperty('Option Values'), defaultValue: []},
            {name: 'labelProp', prop: context.getProperty('Label Property'), defaultValue: ''},
            {name: 'value', prop: context.getProperty('Selected Value'), defaultValue: null}
        ]).subscribe(props => {
            context.set('tmpProps', props);
            this.myRender(props, events, elementDom);
        });

        // Handle clear event
        context.getEvent('Clear').observe().subscribe(() => {
            context.getProperty('Selected Value').set(null);
            const props = context.get('tmpProps');
            props.value = null;
            this.myRender(props, events, elementDom);
        })
    }

    myRender(props, events, elementDom){
        // Guards
        if(props.muiVariant !== 'outlined' && props.muiVariant !== 'filled'){
            props.muiVariant = 'standard';
        }

        props.optionValues = [];
        props.options.forEach(opt => {
            props.optionValues.push({label: DBView.get().getProperty(opt, props.labelProp), value: opt.getTag()});
        });

        if(props.options.onReady){
            props.options.onReady(() => {
                props.selectedValue = props.optionValues.filter(opt => props.value && opt.value === props.value.getTag())[0] || null;
                ReactDOM.render((<AutocompleteSelect.Component {...props} {...events}/>), elementDom);
            })
        } else {
            props.selectedValue = props.optionValues.filter(opt => props.value && opt.value === props.value.getTag())[0] || null;
            ReactDOM.render((<AutocompleteSelect.Component {...props} {...events}/>), elementDom);
        }
    }
}

registerBrick('017bd3712e7192b20edf', AutocompleteSelect);

/**
 * @param {{
 *     isLocalised: boolean
 *     value: string
 *     muiVariant: string
 *     muiSize: string
 *     required: boolean
 *     id: string
 *     styleJSON: string
 *     muiColor: string
 *     disabled: boolean
 *     muiError: boolean
 *     muiFullWidth: boolean
 *     muiLabel: string
 *     muiHelperText: string
 *     optionSeparator: string
 *     optionValues: string
 *     optionLabels: string
 *     onChange: function(*)
 *   }} props
 * @return {Element}
 */
AutocompleteSelect.Component = (props) => {
    // Localised props
    const label = props.isLocalised ? GetLocalisedText.getTranslation(props.title) : props.title;
    const style = getObjectFromJSON(props.styleJSON);

    style.width = style.width || '99%';
    style.height = style.height || '100%';
    style.margin = style.margin || 3;
    // Select options

    const id = 'id-' + (Date.now() * Math.random());

    // Element
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Autocomplete
                    id={id}
                    value={props.selectedValue}
                    options={props.optionValues}
                    getOptionLabel={(option) => option.label}
                    style={style}
                    onChange={props.onChange.bind(this)}
                    renderInput={(params) => <TextField {...params} label={label} variant={props.muiVariant} color={props.muiColor} />}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
