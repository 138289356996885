import { Brick, registerBrick } from 'olympe';
import {getLogger} from "@olympeio/core";
import replaceall from "replaceall";

export default class StringReplaceAll extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} value
     * @param {string} substr
     * @param {string} newSubstr
     * @param {function(string)} setNewValue
     */
    update(context, [value, substr, newSubstr], [setNewValue]) {
        if(typeof value === 'string' && typeof substr === 'string' && typeof newSubstr === 'string'){
            setNewValue(replaceall(substr, newSubstr, value));
        } else {
            getLogger('StringReplaceAll').error('Please check the inputs types, all must be string');
        }
    }
}

registerBrick('017b344fd3f438ff0629', StringReplaceAll);
