
/**
 * All the MUI variants available
 *
 * @enum {string}
 */
export const MUIVariants = {
    DEFAULT: 'default',
    FILLED: 'filled',
    OUTLINED: 'outlined',
    DASHED: 'dashed'
}

/**
 * All the MUI size identifiers available
 *
 * @enum {string}
 */
export const MUISizes = {
    DEFAULT: 'default',
    MEDIUM: 'medium',
    SMALL: 'small',
    LARGE: 'large'
}

/**
 * Base color identifiers, see Palette in @mui/material/styles/createPalette
 *
 * @enum {string}
 */
export const BaseMUIColors = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success'
}

/**
 * @param {string} value
 * @return {MUIVariants}
 */
export function checkMUIVariant(value) {
    value = value && value.toUpperCase();
    if(MUIVariants.hasOwnProperty(value)) {
        return MUIVariants[value];
    } else {
        return MUIVariants.DEFAULT;
    }
}

/**
 * @param {string} value
 * @return {MUISizes}
 */
export function checkMUISize(value) {
    value = value && value.toUpperCase();
    if(MUISizes.hasOwnProperty(value)) {
        return MUISizes[value];
    } else {
        return MUISizes.DEFAULT;
    }
}

/**
 * @param {string} value
 * @return {BaseMUIColors}
 */
export function checkMUIColor(value) {
    value = value && value.toUpperCase();
    if(BaseMUIColors.hasOwnProperty(value)) {
        return BaseMUIColors[value];
    } else {
        return BaseMUIColors.DEFAULT;
    }
}
