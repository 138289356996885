/**
 * Copyright 2021 Olympe S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Brick, registerBrick, ListDef, QueryResult } from 'olympe';
import { getLogger } from 'logging';

export default class GetSizeOfList extends Brick {

    /**
     * @protected
     * @param {!BrickContext} $
     * @param {!ListDef|!List} list
     * @param {function(number)} setSize
     */
    update($, [list], [setSize]) {
        if (Array.isArray(list)) {
            setSize(list.length);
        } else if(list instanceof ListDef) {
            list.observeSize().subscribe(_size => {
                setSize(_size);
            });
        } else if(list instanceof QueryResult) {
            setSize(list.size());
        } else {
            getLogger('Get Size Of List').error('TypeError: the list should be of type ListDef, Array or QueryResult');
        }
    }
}

registerBrick('016372362aac07c772f3', GetSizeOfList);
