import React, { useRef, useLayoutEffect } from 'react';
import { registerBrick } from 'olympe';
import { getLogger, useProperty, jsonToSxProps, cssToSxProps, ifNotNull, ifNotTransparent, ReactBrick } from '@olympeio/core';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { format } from 'date-fns';
import { combineLatest } from 'rxjs';

export class DatePickerBrick extends ReactBrick {

    /**
     * @override
     */
    setupExecution($) {
        return combineLatest([$.observe('Hidden'), $.observe('Pick Time'), $.observe('Mobile Display'), $.observe('Format')]);
    }

    /**
     * @override
     */
    updateParent(parent, element) {
        parent.style.overflow = 'visible'; // Allow overflow
        return super.updateParent(parent, element);
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            let [hidden, pickTime, mobileDisplay, pattern] = props.values;
            try {
                format(new Date(), pattern)
            } catch (e) {
                getLogger('DatePicker').error('Cannot interpret the pattern ' + pattern + '. Please refer to the documentation : https://date-fns.org/v2.29.3/docs/format');
                pattern = 'dd/MM/yyyy'
            }

            const boxRef = useRef();
            const allowContentOverflow = useProperty($, 'Allow Content Overflow');

            useLayoutEffect(() => {
                if (boxRef && boxRef.current) {
                    boxRef.current.parentElement.style.overflow = allowContentOverflow ? "unset" : "hidden";
                }
            }, [boxRef, allowContentOverflow]);


            const renderDateTimePicker = () => {
                const borderWidth = useProperty($, 'Border Width');
                const borderColor = useProperty($, 'Border Color');
                const borderRadius = useProperty($, 'Border Radius');
                const height = Math.max(30, useProperty($, 'Height'))
                const props = {
                    label: useProperty($, 'Label'),
                    inputFormat: pattern,
                    value: useProperty($, 'Selected Date'),
                    onChange: (newValue) => {
                        $.set('Selected Date', newValue);
                        $.trigger('On Change');
                    },
                    renderInput: (params) => {
                        params.InputProps.sx = {
                            ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                            ...ifNotNull('borderStyle', 'solid', borderWidth > 0),
                            ...ifNotNull('borderWidth', borderWidth, borderWidth > 0),
                            ...ifNotTransparent('borderColor', borderColor),
                            ...ifNotTransparent('backgroundColor', useProperty($, 'Default Color')),
                        }

                        return (
                            <TextField
                                {...params}
                                variant={useProperty($, 'Variant')}
                                ref={boxRef}
                                sx={{
                                    boxSizing: 'border-box',
                                    width: Math.max(165, useProperty($, 'Width')),
                                    height: height,
                                    '.MuiInputBase-root': {
                                        height: height,
                                    },
                                    '.MuiButtonBase-root': {
                                        marginRight: 0
                                    },
                                    ...cssToSxProps(useProperty($, 'CSS Property')),
                                    ...jsonToSxProps(useProperty($, 'MUI sx [json]'))
                                }}
                            />
                        )
                    }
                };

                if (mobileDisplay) {
                    return pickTime ? <MobileDateTimePicker {...props} /> : <MobileDatePicker {...props} />;
                } else {
                    return pickTime ? <DateTimePicker {...props} /> : <DatePicker {...props} />;
                }
            };

            return !hidden && (<LocalizationProvider dateAdapter={AdapterDateFns}>{renderDateTimePicker()}</LocalizationProvider>);
        }
    }
}

registerBrick('018659e8ab0cbad84de4', DatePickerBrick);
