import { ActionBrick, registerBrick, BrickContext, GlobalProperties } from 'olympe';
import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getBackdropKeys, getBackdropContainer, getRootContext } from './backdrop-utils';
import { useProperty, markdownTextToReactElement } from '@olympeio/core';
import {map} from "rxjs/operators";
import { Typography } from '@mui/material';

export default class ShowGlobalBackdrop extends ActionBrick {

    /**
     * @override
     */
    setupExecution($) {
        const [ctrlFlowInput, keyInput] = this.getInputs();
        return $.observe(ctrlFlowInput).pipe((map(() => $.get(keyInput))));
    }

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {string} key
     * @param {function()} forwardEvent
     */
    update($, [key], [forwardEvent]) {
        const rootContext = getRootContext($);
        const [showKey, labelKey] = getBackdropKeys(key);
        const labelInput = this.getInputs()[2];
        // Set the label continuously in the root context
        rootContext.repeat(labelKey, $.observe(labelInput));

        if (!rootContext.get(showKey) && !$.get(GlobalProperties.EDITION, true)) {
            rootContext.set(showKey, true);
            this.showGlobalBackdrop(rootContext, key, labelKey);
        }
        forwardEvent();
    }

    /**
     * @param {!BrickContext} rootContext
     * @param {string} key
     * @param {string} labelKey
     */
    showGlobalBackdrop(rootContext, key, labelKey) {
        const container = getBackdropContainer(key);
        if (container.children.length) {
            return;
        }

        const CustomBackdrop = ShowGlobalBackdrop.getBackdropComponent(rootContext, labelKey);
        ReactDOM.render((<CustomBackdrop></CustomBackdrop>), container);
    }

    /**
     * @param {!BrickContext} rootContext
     * @param {string} labelKey
     */
    static getBackdropComponent(rootContext, labelKey) {
        return () => {
            const label = useProperty(rootContext, labelKey);

            return (
                <Backdrop
                    sx={{ color: '#fff', flexDirection: 'column', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    component={'div'}
                >
                    <CircularProgress color="inherit" />
                    {label ? <Typography>{markdownTextToReactElement(label)}</Typography> : undefined}
                </Backdrop>
            )
        }
    }
}

registerBrick('01881e626374ac834d3f', ShowGlobalBackdrop);
