import { registerBrick } from 'olympe';
import { ReactBrick, useProperty, cssToSxProps, ifNotNull, ifNotTransparent } from '@olympeio/core';
import { startWith, filter, debounceTime } from 'rxjs/operators';
import React from 'react';
import ReactQuill from 'react-quill';
import Box from '@mui/material/Box';
import katex from 'katex';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import 'katex/dist/katex.min.css';

export default class WYSIWYG extends ReactBrick {

    /**
     * @override
     */
    init($) {
        window.katex = katex;
    }

    /**
     * @override
     */
    setupExecution($) {
        return $.observe('HTML text').pipe(
            filter((inputValue) => inputValue !== $.get('QuillInternalValue')),
            startWith(''),
            debounceTime(100)
        );
    }

    /**
     * @override
     */
    static getReactComponent($) {
        return (props) => {
            const [htmlInput] = props.values;
            const height = useProperty($, 'Height');
            const width = useProperty($, 'Width');
            const borderRadius = useProperty($, 'Border Radius');
            const [componentHeight, setComponentHeight] = React.useState(height);
            const [content, setContent] = React.useState(htmlInput);
            const containerRef = React.useRef(null);
            const reactQuillRef = React.useRef(null);

            // Update the HTML value as output
            React.useEffect(() => {
                const subscription = $.observe('QuillInternalValue')
                    .pipe(debounceTime(100))
                    .subscribe((value) => {
                        $.set('HTML text', value);
                    });
                return () => {
                    subscription.unsubscribe();
                };
            }, []);

            // Recalculate quill component height on the change of height and width
            React.useEffect(() => {
                const toolbarHeight = containerRef.current.querySelector('.ql-toolbar.ql-snow').offsetHeight;
                if (toolbarHeight) {
                    setComponentHeight( height - toolbarHeight);
                }
            }, [height, width]);

            return (
                <Box ref={containerRef} sx={{
                    width: 1,
                    height: 1,
                    boxSizing: 'border-box',
                    overflow: useProperty($, 'Allow Content Overflow') ? 'visible' : 'hidden',
                    ...ifNotTransparent('borderColor', useProperty($, 'Border Color')),
                    ...ifNotNull('borderRadius', `${borderRadius}px`, borderRadius),
                    ...ifNotNull('borderWidth', useProperty($, 'Border Width')),
                    ...ifNotTransparent('borderStyle', 'solid', useProperty($, 'Border Color')),
                    ...ifNotTransparent('backgroundColor', useProperty($, 'Default Color')),
                    ...cssToSxProps(useProperty($, 'CSS Property')),
                }}>
                    <ReactQuill
                        ref={reactQuillRef}
                        value={content}
                        placeholder={'Try to type something...'}
                        modules={{
                            formula: true,
                            toolbar: [
                                [{ 'header': [1, 2, 3, 4, 5, 6] }, { 'size': [] }, { 'font': [] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block', 'link'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'script': 'sub' }, { 'script': 'super' }, 'formula'],
                                [{ 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
                                [{ 'direction': 'rtl' }],
                                ['image', 'video'],
                                [{ 'color': [] }, { 'background': [] }],
                                ['clean'],
                            ]
                        }}
                        onChange={(value) => {
                            setContent(value);
                            $.set('QuillInternalValue', value);
                        }}
                        theme={'snow'}
                        style={{ height: `${componentHeight}px` }}
                    />
                </Box>
            );
        }
    }
}

registerBrick('017ed31ff259ae812c2d', WYSIWYG);
