
/**
 * Copyright 2021 Olympe S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionBrick, registerBrick, Transaction, BrickContext, Relation, CloudObject, ErrorFlow } from 'olympe';

export default class DeleteRelation extends ActionBrick {

    /**
     * @protected
     * @param {!BrickContext} $
     * @param {!CloudObject} origin
     * @param {!Relation} relation
     * @param {!CloudObject} destination
     * @param {function()} forwardEvent
     * @param {function(ErrorFlow)} setErrorFlow
     * @param {function(!CloudObject)} setOrigin
     */
    update($, [origin, relation, destination], [forwardEvent, setErrorFlow, setOrigin]) {
        // Get current transaction
        const transaction = Transaction.from($);

        // Delete the specified relation
        transaction.deleteRelation(relation, origin, destination);

        // Release or execute the transaction
        Transaction.process($, transaction).then(() => {
            setOrigin(origin);
            forwardEvent();
        }).catch((message) => {
            setErrorFlow(ErrorFlow.create(`Delete Relation: ${message}`, 1));
        });
    }
}

registerBrick('01681960628b0d391ad6', DeleteRelation);
