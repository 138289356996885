/**
 * Copyright 2021 Olympe S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Brick, registerBrick, Query } from 'olympe';
import {getLogger} from "logging";

export default class ObserveQuery extends Brick {

    /**
     * @override
     * @protected
     * @param {!BrickContext} $
     * @param {!Query} query
     * @param {function(!QueryResult)} setQueryResult
     */
    update($, [query], [setQueryResult]) {
        if (query instanceof Query) {
            query.observe($).subscribe(setQueryResult);
        } else {
            getLogger('ObserveQuery').error(`The provided Query is not a Query object`);
        }
    }
}

registerBrick('017ee95e41926ddeff85', ObserveQuery);
