import { Brick, registerBrick, Context } from 'olympe';
import {getLogger} from '@olympeio/core';

export default class GetFromContext extends Brick {

    /**
     * @protected
     * @param {!BrickContext} context
     * @param {string} key
     * @param {function(*)} setValue
     */
    update(context, [key], [setValue]) {
        if(typeof key !== 'string') {
            getLogger('GetFromContext').error(`Provided key is a string`);
            return;
        }
        context.observe(key, true, true).subscribe(setValue);
    }
}

registerBrick('017d942e6e387481b3eb', GetFromContext);
